import axios from 'axios';
import {removeKeys} from "./auth";

export const STORAGE_KEYS = {
  USER: 'koa-user',
  TOKEN: 'koa-token',
  SESSION: 'koa-active-session',
  SHOW_HIDE: 'koa-show-hide',
};

const METHODS = {
  AUTH: 'UserService.Auth',
  AUTH_LOGOUT: 'UserService.Logout',
  ALL_PRODUCTS: 'ProductService.All',
  CREATE_PRODUCT: 'ProductService.Create',
  UPDATE_PRODUCT: 'ProductService.Update',
  DELETE_PRODUCT: 'ProductService.Delete',
  ALL_CATEGORY: 'CategoryService.All',
  CREATE_CATEGORY: 'CategoryService.Create',
  UPDATE_CATEGORY: 'CategoryService.Update',
  DELETE_CATEGORY: 'CategoryService.Delete',
  ALL_USERS: 'UserService.All',
  USER_UPDATE: 'UserService.Update',
  USER_GET: 'UserService.Get',
  CHECK_SMILE_JOB_STATUS: 'UserService.CheckSmileJobStatus',
  ALL_VENDORS: 'VendorService.All',
  CREATE_VENDOR: 'VendorService.Create',
  UPDATE_VENDOR: 'VendorService.Update',
  SEARCH_GOAL: 'GoalService.Search',
  SEARCH_TRANSACTION: 'TransferService.Search',
  UPDATE_GOAL: 'GoalService.Update',
  ALL_JOBS: 'JobService.All',
  GET_EVENTS_TRANSACTION: 'TransferService.GetEvents',
  SEARCH_USER: 'UserService.Search',
  UPDATE_TRANSACTION: 'TransferService.Update',
  ALL_BATCHES: 'BatchJobService.All',
  START_BATCH: 'BatchJobService.StartJob',
  ALL_USER_EXPORT: 'BulkExportService.AllUserExports',
  CREATE_USER_EXPORT: 'BulkExportService.CreateUserExport',
  DELETE_JOB: 'JobService.Delete',
  ALL_APP_CONFIGS: 'AppConfigService.All',
  SET_APP_CONFIG: 'AppConfigService.Set',
  ALL_REFERRAL: 'ReferralService.All',
  CLAIM_REFERRAL: 'ReferralService.ClaimReferrals',
  RESET_BALANCE: 'TransferService.ResetBalance',
  SETTLE_TRANSACTION: 'TransferService.SettleTransactions',
  RECONCILE_TRANSACTION: 'TransferService.TriggerReconciliation',
  UPLOAD_CREATE: 'UploadService.Create',
  UPLOAD_GET_URL: 'UploadService.GetDownloadUrl',
  RESET_PHONE: 'UserService.ResetPhone',
  USER_DELETE: 'UserService.Delete',
  ADD_IFA_USER: 'UserService.RegisterIfa',
  MIGRATE_TO_COOP: 'UserService.MigrateToCoop',
  GROUP_MEMBERS: 'GroupSavingService.GetMembers',
  GET_USER_STATS: 'StatsService.GetUserStats',
  GET_TRANSACTION_STATS: 'StatsService.GetTransactionStats',
}

const host = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'production': {
      return {
        backend: 'https://api.withkoa.net/rpc',
        backend_dmz: 'https://europe-west3-integrations-staging-britam.cloudfunctions.net/',
      };
    }
    default: {
      return {
        backend: 'https://api-stage.withkoa.net/rpc',
        backend_dmz: 'https://europe-west3-integrations-staging-britam.cloudfunctions.net/',
      };
    }
  }
};

// axios.defaults.baseURL = host().backend;

const backendApi = axios.create({
  baseURL: host().backend,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': "Bearer " + localStorage.getItem(STORAGE_KEYS.TOKEN)
  }
});

const backendDMZ = axios.create({
  baseURL: host().backend_dmz,
  headers: {
    'Content-Type': 'application/json',
    'Verification-Hash': 'QUylkq3AFhpP&H6VB*zcAjF($iK%8NRVf&W*hLHjde%BUiWYg'
  }
});

const checkSession = (session) => {

  var sessionTime = process.env.REACT_APP_SESSION_TIME
  try {
    sessionTime = Number(sessionTime)
  }catch (e) {

  }
  if(isNaN(sessionTime) ){
    sessionTime = 1
  }
  const time = 60*60*sessionTime
  const seconds = (Date.now() - Number(session)) / 1000
  process.env.NODE_ENV !== 'production' && console.log(seconds, time)
  return seconds > time
}

backendApi.interceptors.request.use(
    (request)=> {
      if(!(request.data.method === METHODS.AUTH || request.data.method === METHODS.AUTH_LOGOUT)){
        const session = localStorage.getItem(STORAGE_KEYS.SESSION)
        if(!session || checkSession(session)){
          try {
            removeKeys()
            Api.logout()
          }catch (e){
            process.env.NODE_ENV !== 'production' && console.log(e)
          }
          localStorage.setItem('to.path',window.location.pathname);
          window.location.replace("/login");
          return Promise.reject(request);
        }else {
          localStorage.setItem(STORAGE_KEYS.SESSION, Date.now())
        }
        process.env.NODE_ENV !== 'production' && console.log(request,request.data)
      }
      return request
    }
)

backendApi.interceptors.response.use(
  (response) => {
    // process.env.NODE_ENV !== 'production' && console.log('response', response)
    if (response.data.error) {
      process.env.NODE_ENV !== 'production' && console.log('error', response.data.error)
      if(response.data.error.message === 'signature is invalid'
        // || response.data.error.message === "not found"
      ){
        removeKeys()
        localStorage.setItem('to.path',window.location.pathname);
        window.location.replace("/login");
      }
      return Promise.reject(response);
    }
    process.env.NODE_ENV !== 'production' && console.log('response', response)
    return response;
  },
  (error) => {
    process.env.NODE_ENV !== 'production' && console.log('error', error)
    return Promise.reject(error);
  }
);

backendDMZ.interceptors.response.use(
  (response) => {
    process.env.NODE_ENV !== 'production' && console.log('dmz response', response)
    if (response.data.error) {
      return Promise.reject(response);
    }
    return response;
  },
  (error) => {
    process.env.NODE_ENV !== 'production' && console.log('dmz error', error)
    return Promise.reject(error);
  }
);

const koa = (method, params) => backendApi.post('', {method: method, params: {...params}})

const ax = (withAuth = true) => {
  const userString = window.localStorage.getItem(STORAGE_KEYS.USER);
  if (userString && withAuth) {
    const {token} = JSON.parse(userString);
    return axios.create({
      headers: {Authorization: `Bearer ${token}`},
    });
  }
  return axios.create({});
};

const Api = {
  changePassword: data => ax().post('/api/users/change-password', data),
  forgotPassword: data => ax().post('/api/users/forgot', data),
  requestPasswordChange: data => ax().post('/api/users/req-password-change', data),
  uploadService: data => axios.post(`${host().backend}/api/upload-service/no-auth`, data),
  uploadFileToS3: async (signedUrl, file, handleProgressUpdate) =>
    axios.put(signedUrl, file, {
      headers: {'Content-Type': file.type},
      onUploadProgress(progressEvent) {
        if (handleProgressUpdate) {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          handleProgressUpdate(percentCompleted);
        }
      },
    }),
  login: async ({email, password}) => {
    const res = await ax(false).post('/api/users/login', {email, password});
    return window.localStorage.setItem(STORAGE_KEYS.USER, JSON.stringify(res.data));
  },
  loginFirebase: (uid) => koa(METHODS.AUTH, {uid}),
  logout: () => koa(METHODS.AUTH_LOGOUT, {}),
  //Product api
  allProducts: () => koa(METHODS.ALL_PRODUCTS, {}),
  createProduct: (params) => koa(METHODS.CREATE_PRODUCT, {...params}),
  updateProduct: (params) => koa(METHODS.UPDATE_PRODUCT, {...params}),
  deleteProduct: (params) => koa(METHODS.DELETE_PRODUCT, {...params}),
  //Category api
  allCategories: () => koa(METHODS.ALL_CATEGORY, {}),
  createCategory: (params) => koa(METHODS.CREATE_CATEGORY, {...params}),
  updateCategory: (params) => koa(METHODS.UPDATE_CATEGORY, {...params}),
  deleteCategory: (params) => koa(METHODS.DELETE_CATEGORY, {...params}),
  //User api
  allUsers: (params) => koa(METHODS.ALL_USERS, {...params}),
  updateUser: (params) => koa(METHODS.USER_UPDATE, {...params}),
  getUser: (params) => koa(METHODS.USER_GET, {...params}),
  checkSmileJobStatus: (params) => koa(METHODS.CHECK_SMILE_JOB_STATUS, {...params}),
  searchUser: (params) => koa(METHODS.SEARCH_USER, {...params}),
  upload: (params) => koa(METHODS.UPLOAD_CREATE, {...params}),
  uploadGetURL: (params) => koa(METHODS.UPLOAD_GET_URL, {...params}),
  deleteUser: (params) => koa(METHODS.USER_DELETE, {...params}),
  addIfa: (params) => koa(METHODS.ADD_IFA_USER, {...params}),
  migrateToCoop: (params) => koa(METHODS.MIGRATE_TO_COOP, {...params}),
  getGroupMembers: (params) => koa(METHODS.GROUP_MEMBERS, {...params}),
  getUserStats: () => koa(METHODS.GET_USER_STATS, {}),
  //Vendor api
  allVendors: () => koa(METHODS.ALL_VENDORS, {}),
  createVendor: (params) => koa(METHODS.CREATE_VENDOR, {...params}),
  updateVendor: (params) => koa(METHODS.UPDATE_VENDOR, {...params}),
  //Goal api
  searchGoal: (params) => koa(METHODS.SEARCH_GOAL, {...params}),
  updateGoal: (params) => koa(METHODS.UPDATE_GOAL, {...params}),
  //Transaction api
  searchTransaction: (params) => koa(METHODS.SEARCH_TRANSACTION, {...params}),
  getEventsTransaction: (params) => koa(METHODS.GET_EVENTS_TRANSACTION, {...params}),
  updateTransaction: (params) => koa(METHODS.UPDATE_TRANSACTION, {...params}),
  resetBalance: (params) => koa(METHODS.RESET_BALANCE, {...params}),
  settleTransaction: (params) => koa(METHODS.SETTLE_TRANSACTION, {...params}),
  reconcileTransaction: (params) => koa(METHODS.RECONCILE_TRANSACTION, {...params}),
  resetPhone: (params) => koa(METHODS.RESET_PHONE, {...params}),
  getTransactionStats: (params) => koa(METHODS.GET_TRANSACTION_STATS, {...params}),
  //Job api
  allJobs: (params) => koa(METHODS.ALL_JOBS, {...params}),
  deleteJob: (params) => koa(METHODS.DELETE_JOB, {...params}),
  //Export api
  allBatches: (params) => koa(METHODS.ALL_BATCHES, {...params}),
  startBatch: (params) => koa(METHODS.START_BATCH, {...params}),
  allUserExport: (params) => koa(METHODS.ALL_USER_EXPORT, {...params}),
  createUserExport: (params) => koa(METHODS.CREATE_USER_EXPORT, {...params}),
  //AppConfig
  allAppConfigs: (params) => koa(METHODS.ALL_APP_CONFIGS, {...params}),
  setAppConfig: (params) => koa(METHODS.SET_APP_CONFIG, {...params}),
  //Referral
  allReferral: (params) => koa(METHODS.ALL_REFERRAL, {...params}),
  claimReferral: (params) => koa(METHODS.CLAIM_REFERRAL, {...params}),
  //DMZ
  resendVerifyAccount: (data) => backendDMZ.post('resendVerifyAccount',data),
}

export default Api


