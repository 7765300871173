import React from 'react';
import {Button, Header, Icon, Label} from 'semantic-ui-react';
import { withRouter} from 'react-router-dom';
import hash from 'object-hash';

const TopBar = (props)=> {
    const {back, history, actions,label} = props;
    return (
      <>
        <Header as="h1" style={{marginLeft: back ? -55 : 0}}>
          {back &&
          <Button
            circular
            size="small"
            onClick={() => {
              typeof back === 'string' ? history.push(back) : history.goBack();
            }}
          >
            <Icon name="arrow left"/>
          </Button>
          }
          <Header.Content style={{verticalAlign: 'bottom'}}>{props.name}
            {label ?
              <Label circular color="grey">{label}</Label>
              : ''}
          </Header.Content>

          <Button.Group floated="right">
            {actions ?
              actions.map((item, i) => (
                <item.render key={hash(i)}/>
                ))
              :
              ''}
          </Button.Group>
          {props.meta}
        </Header>
      </>
    );
}

export default withRouter(TopBar);
