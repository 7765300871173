import jwtDecode from 'jwt-decode';
import { STORAGE_KEYS } from './api';

export const checkAuth = !!(localStorage.getItem(STORAGE_KEYS.USER) && localStorage.getItem(STORAGE_KEYS.TOKEN))


export const removeKeys = () => {
  localStorage.removeItem(STORAGE_KEYS.USER)
  localStorage.removeItem(STORAGE_KEYS.TOKEN)
  localStorage.removeItem(STORAGE_KEYS.SESSION)
};


export const checkTokenExpirationMiddleware = () => {
  if (checkAuth){
    const token = localStorage.getItem(STORAGE_KEYS.TOKEN);
    if (jwtDecode(token).exp < Date.now() / 1000) {
      removeKeys();
      return false
    }
    return true
  }
  return false
};
