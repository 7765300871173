import React, {useContext} from 'react';
import {Button, Container, Label, Table} from 'semantic-ui-react';
import {
    getUserFirstName, getUserFullName, getUserLastName, getUserMiddleName,
    isLargeScreen,
    KYCStateColor,
    KYCStateName,
    searchOnTable,
    toReadableDateTime, userCan, userLevels, UserTypes
} from "../../util/HelperFunctions";
import {UserContext} from "../../context/UserProvider";

const UserList = ({data,handleView,searchTable,type= UserTypes.INDIVIDUAL})=> {
    const {user} = useContext(UserContext)
    return (
      <>
        {data && data.length ?
        <Table singleLine basic='very' striped fixed={!isLargeScreen()}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Id</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.HeaderCell>KYC Status</Table.HeaderCell>
              <Table.HeaderCell>SignUp Date</Table.HeaderCell>
                { userCan(user.access, userLevels.VIEW_USER_DETAILS) && <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.map((item,i) =>searchOnTable(searchTable,
              [item.id,type === UserTypes.GROUP ? item.name : `${getUserFirstName(item)} ${item?.last_name ?? item.meta?.first_name}`,item.email,
                KYCStateName(item.kyc_state),item.created_at,toReadableDateTime(item.created_at)]) ?
              <Table.Row key={i}>
                <Table.Cell>{item.id.substring(0,8)}...</Table.Cell>
                <Table.Cell>
                    {type === UserTypes.GROUP ? item.name:<>{`${getUserFirstName(item)} ${getUserLastName(item)}`} {(item.meta && item.meta.is_IFA) && <Label
                        color="blue"
                        size="medium"
                        content="IFA"/>}</>}
                    </Table.Cell>
                {/*<Table.Cell>{`${item.first_name} ${item.last_name}`}</Table.Cell>*/}
                <Table.Cell>{item.email.length > 29 ? `${item.email.substring(0,29)}...` : item.email}</Table.Cell>
                <Table.Cell>
                  <Label
                    color={KYCStateColor(item.kyc_state)}
                    basic
                    size="medium"
                    content={KYCStateName(item.kyc_state)}/>
                </Table.Cell>
                <Table.Cell>{toReadableDateTime(item.created_at)}</Table.Cell>
                  { userCan(user.access, userLevels.VIEW_USER_DETAILS) && <Table.Cell>
                  <Button icon={"user"} content='View Details' size="small" color={"green"} floated="right"
                          onClick={()=> handleView({...item, fullName: getUserFullName(item), first_name: getUserFirstName(item), last_name: getUserLastName(item), middle_name: getUserMiddleName(item)})}/>
                </Table.Cell>}
              </Table.Row>:null
              )
            }
          </Table.Body>
        </Table>
        :
        <Container text textAlign="center" content="No records found" style={{height: "37vh"}}/>
        }
      </>
    );
}


export default UserList;

