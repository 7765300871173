import React, {useContext, useEffect, useState} from 'react';
import {
  Grid,
  Tab,
  Header,
  Label,
  Input,
  Menu,
  Button,
  Card,
  Divider,
  Container,
  Form, Dropdown, Modal, Message, Loader
} from 'semantic-ui-react';
import TopBar from '../../util/topbar';
import TransactionList from "./TransactionList";
import SideModal from "../../util/SideModal";
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import {
  statusColor,
  statusName,
  toReadableValue,
  toReadableDateTime,
  currencyCode,
  KYCStateColor, KYCStateName, NotifyCode, userCan, userLevels
} from "../../util/HelperFunctions";
import Api from "../../util/api";
import { useLocation } from "react-router-dom";
import TransactionDetails from "../../components/TransactionDetails";
import {UserContext} from "../../context/UserProvider";
import axios from "axios";
import moment from "moment";

const Transactions = (props) => {
  const {user} = useContext(UserContext)
  const [viewModalOpen, setViewModalOpen] = useState(false)
  const [searchId,setSearchId] = useState("")
  const [searchIdLoader,setSearchIdLoader] = useState(false)
  const [transactions,setTransactions] = useState(null)
  const location = useLocation();
  const [transactionEvents,setTransactionEvents] = useState(null)
  const [transaction,setTransaction] = useState(null)
  const [transactionStats,setTransactionStats] = useState(null)
  // const [stats,setStats] = useState([
  const stats = ([
    {key:'today',text:'Today',value:0,from_date: moment().startOf('day').format(), to_date: moment().endOf('day').format()},
    {key:'yesterday',text:'Yesterday',value:1,from_date: moment().startOf('day').subtract(1, 'day').format(), to_date: moment().endOf('day').subtract(1, 'day').format()},
    {key:'weekly',text:'Week ago',value:2,from_date: moment().startOf('day').subtract(1, 'week').format(), to_date: moment().endOf('day').format()},
    {key:'this_week',text:'This week',value:3,from_date: moment().startOf('week').format(), to_date: moment().endOf('week').format()},
    {key:'monthly',text:'Month ago',value:4,from_date: moment().startOf('day').subtract(1, 'month').format(), to_date: moment().endOf('day').format()},
    {key:'this_monthly',text:'This month',value:5,from_date: moment().startOf('month').format(), to_date: moment().endOf('month').format()},
    {key:'yearly',text:'Year ago',value:6,from_date: moment().startOf('day').subtract(1, 'year').format(), to_date: moment().endOf('day').format()},
    {key:'this_yearly',text:'This year',value:7,from_date: moment().startOf('year').format(), to_date: moment().endOf('year').format()},
  ])
  const [currentStats,setCurrentStats] = useState(0)
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [submitLoader, setSubmitLoader] = useState(false)
  const [status,setStatus] = useState('failure')
  const [stateTransactionModalOpen,setStateTransactionModalOpen] = useState(false)
  const [popUp, setPopUp] = useState('')
  const [popUpMessage, setPopUpMessage] = useState('')

  // console.log(moment().endOf('week').subtract(1, 'week').format(),moment(). daysInMonth())

  useEffect(()=>{
    if(!transactions){
      fetchTransactions()
    }
    if(!transactionStats){
      fetchTransactionStats()
    }
  },[])

  const fetchTransactions = ()=>{
    if(localStorage.getItem('state')){
      const state = JSON.parse(localStorage.getItem('state'))
      setTransactions(state.transactions)
      setTransaction(state.transactions[state.index])
      setViewModalOpen(true)
      fetchTransactionEvents(state.transactions[state.index].id)
      setSearchId(location.search.replace('?id=',''))
      localStorage.removeItem('state')
    }
    else if(location.search){
      setSearchId(location.search.replace('?id=',''))
      handleSearchTransaction(location.search.replace('?id=',''))
    }
  }

  const fetchTransactionEvents = async (transaction_id) =>{
    try{
      const {data} = await Api.getEventsTransaction({transaction_id})
      if(data.result.transaction_events){
        setTransactionEvents(data.result.transaction_events)
      }else{
        setTransactionEvents([])
      }
    }catch (err) {
      console.log(err)
    }
  }

  const fetchTransactionStats = async () =>{
    try{
      const res = await axios.all([
          ...stats.map(e=> Api.getTransactionStats({from_date:e.from_date,to_date:e.to_date}))
      ])
      console.log(res)
      if(res && res.length > 0){
        let result = []
        res.forEach((e,i)=>{
          result = [...result, e.data.result.transaction_stats]
        })
        // console.log(result)
        setTransactionStats(result)
      }else {
        setTransactionStats(undefined)
      }

    }catch (err) {
      console.log(err)
    }
  }

  const handleSearchTransaction = async (value)=>{
    setSearchIdLoader(true)
    try{
      const params = {q:value}
      const {data} = await Api.searchTransaction(params)
      if(data.result.transactions){
        setTransactions(data.result.transactions)
      }else {
        setTransactions([])
      }
    }catch (err) {
      console.log()
    }
    setSearchIdLoader(false)
  }

  const handleUpdateTransaction = async (e) => {
    e.preventDefault()
    setSubmitLoader(true)
    try {
      const {data} = await Api.updateTransaction({id: transaction.id, status})
      setTransaction(data.result.transaction)
      setTransactions([...transactions.map(item => item.id === data.result.transaction.id ? {...data.result.transaction} : item)])
      handleCloseEditModal()
    } catch (err) {
      console.log(err)
    }
    setSubmitLoader(false)
  }

  const handleOpenViewModal = (transaction) => {
    setViewModalOpen(true)
    setTransaction(transaction)
    fetchTransactionEvents(transaction.id)
  }

  const handleCloseViewModal = () => {
    setViewModalOpen(false)
  }

  const handleSearchId = ()=>{
    handleSearchTransaction(searchId)
  }

  const handleOpenEditModal = (transaction) => {
    setEditModalOpen(true)
    // setStatus(transaction.status)
    // console.log(transaction)
    setTransaction(transaction)
  }

  const handleCloseEditModal = () => {
    setEditModalOpen(false)
  }

  const resetPopUp = () =>{
    setPopUp('')
    setPopUpMessage('')
  }

  const hidePopupMessage = (time)=>{
    setTimeout(()=>{
      resetPopUp()
    },time??1500)
  }

  const handleSetPopupMessage = (code,messsage,time=1500)=>{
    setPopUp(code)
    setPopUpMessage(messsage)
    if(time !== -1){
      hidePopupMessage(time)
    }
  }

  const handleOpenStateTransactionModal = () => {
    setStateTransactionModalOpen(true)
  }

  const handleCloseStateTransactionModal = () => {
    setStateTransactionModalOpen(false)
  }

  const handleTransactionState = async (e)=>{
    e.preventDefault()
    setSubmitLoader(true)
    try {
      let params = {
        open: transaction.state === 'open' ? [] : [transaction.id],
        close: transaction.state === 'open' ? [transaction.id] : [],
      }
      await Api.settleTransaction(params)
      setTransaction({...transaction,state:transaction.state === 'open' ? 'closed':'open'})
      setTransactions([...transactions.map(item => item.id === transaction.id ?
          {...transaction,state:transaction.state === 'open' ? 'closed':'open'} : item)])
      handleSetPopupMessage(NotifyCode.TRANSACTION_STATE.SUCCESS,'Successful')
      handleCloseStateTransactionModal()
    } catch (err) {
      handleSetPopupMessage(NotifyCode.TRANSACTION_STATE.ERROR,err.data.error.message)
    }
    setSubmitLoader(false)
  }

  const panes = [
    {
      menuItem: 'Transactions', render: () => <Tab.Pane>
        <Grid centered>
          {transactionStats === null ? <>
            <Divider hidden/>
            <Card.Group>
              <Card>
                <Card.Content>
                  <Card.Header><Loader active inline /></Card.Header>
                  <Card.Description>
                    Successful deposits from MPesa
                  </Card.Description>
                </Card.Content>
              </Card>
              <Card>
                <Card.Content>
                  <Card.Header><Loader active inline /></Card.Header>
                  <Card.Description>
                    Successful withdrawals to MPesa
                  </Card.Description>
                </Card.Content>
              </Card>
              <Card>
                <Card.Content>
                  <Card.Header><Loader active inline /></Card.Header>
                  <Card.Description>
                    Goal to Goal transfers
                  </Card.Description>
                </Card.Content>
              </Card>
              <Card>
                <Card.Content>
                  <Card.Header><Loader active inline /></Card.Header>
                  <Card.Description>
                    Signed up
                  </Card.Description>
                </Card.Content>
              </Card>
            </Card.Group>
            <Divider hidden/>
          </>: transactionStats === undefined ? <></>: <>
            <Grid.Row floated="left">
              <Grid.Column width={16}>
                <div style={{display: 'flex', width: '100%'}}>
                  <Dropdown
                      button
                      value={currentStats}
                      options={stats}
                      onChange={(event, {value}) => setCurrentStats(value)}
                  />
                </div>
              </Grid.Column>
            </Grid.Row>
            {/*<Divider hidden/>*/}
            <Card.Group>
              <Card>
                <Card.Content>
                  <Card.Header>{transactionStats[currentStats].successful_mpesa_deposits}</Card.Header>
                  <Card.Description>
                    Successful deposits from MPesa
                  </Card.Description>
                </Card.Content>
              </Card>
              <Card>
                <Card.Content>
                  <Card.Header>{transactionStats[currentStats].successful_mpesa_withdrawals}</Card.Header>
                  <Card.Description>
                    Successful withdrawals to MPesa
                  </Card.Description>
                </Card.Content>
              </Card>
              <Card>
                <Card.Content>
                  <Card.Header>{transactionStats[currentStats].successful_goal_to_goal}</Card.Header>
                  <Card.Description>
                    Goal to Goal transfers
                  </Card.Description>
                </Card.Content>
              </Card>
              <Card>
                <Card.Content>
                  <Card.Header>{transactionStats[currentStats].goals_created}</Card.Header>
                  <Card.Description>
                    Signed up
                  </Card.Description>
                </Card.Content>
              </Card>
            </Card.Group>
            <Divider hidden/>
          </>}
          <Grid.Row>
            <Grid.Column width={9} floated="left">
              <div style={{display: 'flex', width: '100%'}}>
                <Input label="ID/Ref" placeholder="Search" value={searchId} loading={searchIdLoader} disabled={searchIdLoader}
                       onChange={(e)=>setSearchId(e.target.value)}
                       onKeyDown={e=> e.key === 'Enter' && searchId.length > 0 ? handleSearchId():null}
                       style={{width: '100%',marginRight:3}}
                />
                <Button onClick={handleSearchId} loading={searchIdLoader} disabled={searchIdLoader|| searchId.length===0}>Search</Button>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {!!transactions&&<TransactionList data={transactions} handleView={handleOpenViewModal} style={{marginTop:10}}/>}
      </Tab.Pane>
    },
  ]

  const modalPanes = (transaction) =>[
    {
      menuItem:<Menu.Item key='details'>
        Details <Label
        color={statusColor(transaction.status)}
        size="large"
        content={statusName(transaction.status)}/>
      </Menu.Item>, render: () => <Tab.Pane>
        <Grid centered>
          <Grid.Row textAlign="right">
            <Grid.Column width={ transaction.status === 'pending' ? 7 : 5} floated="right">
              {(transaction.status === 'pending' && userCan(user.access, userLevels.UPDATE_TRANSACTION) ) &&
              <Button icon={"pencil"}
                      content='Update' size="small" color={"blue"} onClick={()=> handleOpenEditModal(transaction)}/>
                      }
              { userCan(user.access, userLevels.OPEN_CLOSE_TRANSACTION) && <Button icon={transaction.state === 'open' ? 'envelope' : 'envelope open'}
                      content={transaction.state === 'open' ? 'Close Transaction' : 'Open Transaction'}
                      size="small" color="olive" onClick={handleOpenStateTransactionModal}/>}
            </Grid.Column>
          </Grid.Row>
          {(popUp === NotifyCode.TRANSACTION_STATE.SUCCESS || popUp === NotifyCode.TRANSACTION_STATE.ERROR) && <Grid.Row>
            <Grid.Column width={14}>
              <Message success={popUp === NotifyCode.TRANSACTION_STATE.SUCCESS}
                       negative={popUp === NotifyCode.TRANSACTION_STATE.ERROR}>
                <Message.Header>{popUpMessage}</Message.Header>
              </Message>
            </Grid.Column>
          </Grid.Row>}
          <TransactionDetails transaction={transaction} showViewUser={true}/>
        </Grid>
      </Tab.Pane>
    },
    {
      menuItem:<Menu.Item key='events'>
        Events
      </Menu.Item>,
      render: () => <Tab.Pane loading={transactionEvents === null}>

          {transactionEvents?.length ?
            <Card.Group>{transactionEvents?.map((item,i)=>
            <Card fluid key={item.id}>
              <Card.Content>
                <Card.Header>{item.name}</Card.Header>
                <Divider hidden />
                <Card.Meta>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width={14}>
                        <Header as='h4'>
                          <Header.Content>
                            <Header.Subheader>ID</Header.Subheader>
                            {item.id}
                          </Header.Content>
                        </Header>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={14}>
                        <Header as='h4'>
                          <Header.Content>
                            <Header.Subheader>Transaction ID</Header.Subheader>
                            {item.transaction_id}
                          </Header.Content>
                        </Header>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={7}>
                        <Header as='h4'>
                          <Header.Content>
                            <Header.Subheader>Type</Header.Subheader>
                            {item.type}
                          </Header.Content>
                        </Header>
                      </Grid.Column>
                      <Grid.Column width={7}>
                        <Header as='h4'>
                          <Header.Content>
                            <Header.Subheader>Account</Header.Subheader>
                            {item.account}
                          </Header.Content>
                        </Header>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={14}>
                        <Header as='h4'>
                          <Header.Content>
                            <Header.Subheader>Account Reference</Header.Subheader>
                            {item.account_ref}
                          </Header.Content>
                        </Header>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={7}>
                        <Header as='h4'>
                          <Header.Content>
                            <Header.Subheader>Amount</Header.Subheader>
                            {toReadableValue(item.amount,item.currency_code)} {currencyCode(item.currency_code)}
                          </Header.Content>
                        </Header>
                      </Grid.Column>
                      <Grid.Column width={7}>
                        <Header as='h4'>
                          <Header.Content>
                            <Header.Subheader>Data</Header.Subheader>
                            {JSON.stringify(item.data)}
                          </Header.Content>
                        </Header>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={12}>
                        <Header as='h4'>
                          <Header.Content>
                            <Header.Subheader>Date Created</Header.Subheader>
                            {toReadableDateTime(item.created_at)}
                          </Header.Content>
                        </Header>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Card.Meta>
                <Card.Description>

                </Card.Description>
              </Card.Content>
            </Card>)}
            </Card.Group>:
            <Container text textAlign="center" content="No events"/>
          }
      </Tab.Pane>
    },
  ]
  return (
    <>
      <TopBar name={props.title}/>
      <Tab panes={panes}/>
      {!!(transactions && transactions.length && transaction )&&<>
      <SideModal
        open={viewModalOpen}
        onClose={handleCloseViewModal}
        title="View Transaction Details"
        width={80}
      >
        <Tab panes={modalPanes(transaction)}/>
      </SideModal>
        <SideModal
          open={editModalOpen}
          onClose={handleCloseEditModal}
          title="Update Transaction"
        >
          <Form onSubmit={handleUpdateTransaction}>
            <Grid centered>
              <Grid.Row>
                <Grid.Column width={14}>
                  <Header as='h4'>
                    <Header.Content>
                      <Header.Subheader>Current Status</Header.Subheader>
                      <Label
                        color={KYCStateColor(transaction.status)}
                        basic
                        size="medium"
                        content={KYCStateName(transaction.status)}/>
                    </Header.Content>
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={14}>
                  <Form.Field>
                    <label>Status</label>
                    <Dropdown
                      placeholder='Status'
                      fluid
                      selection
                      value={status}
                      options={[
                        {key:'pending',text:'PENDING',value:'pending'},
                        {key:'success',text:'SUCCESS',value:'success'},
                        {key:'failure',text:'FAILURE',value:'failure'},
                      ]}
                      onChange={(event, {value}) => setStatus(value)}
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
              <Button type='submit' loading={submitLoader} disabled={submitLoader || transaction.status===status}>Submit Changes</Button>
            </Grid>
          </Form>
        </SideModal>
        <Modal
            size="mini"
            open={stateTransactionModalOpen}
            onClose={handleCloseStateTransactionModal}
        >
          <Modal.Header>{`${transaction.state === 'open' ? 'Close Transaction' : 'Open Transaction'} for ${transaction.id}`}</Modal.Header>
          <Modal.Content>
            <p>Are you sure?</p>
          </Modal.Content>
          <Modal.Actions>
            <Button negative disabled={submitLoader} onClick={handleCloseStateTransactionModal}>
              No
            </Button>
            <Button positive loading={submitLoader} disabled={submitLoader} onClick={handleTransactionState}>
              Yes
            </Button>
          </Modal.Actions>
        </Modal>
        </>
      }
    </>
    );
}

export default Transactions;

