import React, {useContext} from 'react';
import {Menu} from 'semantic-ui-react';
import {withRouter} from "react-router-dom";
import {UserContext} from "../context/UserProvider";
import {userCan, userLevels} from "../util/HelperFunctions";

const AppMenu = (props) => {
    const {user} = useContext(UserContext)
  const active = () => {
    const paths = props.location.pathname.split("/").filter(Boolean)
    let activeItem = "home";
    if (paths.length >= 1) {
      activeItem = paths[0].toLowerCase();
    }
    return activeItem
  }
  const handleItemClick = (path) => {
    props.history.push(`/${path}`);
  }
  return (
    <Menu pointing secondary>
      <Menu.Item name='home' active={active() === 'home'} onClick={() => handleItemClick("")}/>
        {/*{ userCan(user.access, userLevels.VIEW_USERS) && */}
            <Menu.Item name='Users' active={active() === 'users'} onClick={() => handleItemClick("users")}/>
        {/*}*/}
        {/*{ userCan(user.access, userLevels.VIEW_CATEGORIES) && */}
            <Menu.Item name='categories' active={active() === 'categories'} onClick={() => handleItemClick("categories")}/>
        {/*}*/}
        {/*{ userCan(user.access, userLevels.VIEW_PRODUCTS) &&  */}
            <Menu.Item name='products' active={active() === 'products'} onClick={() => handleItemClick("products")}/>
        {/*}*/}
        {/*{ userCan(user.access, userLevels.VIEW_VENDORS) && */}
            <Menu.Item name='vendors' active={active() === 'vendors'} onClick={() => handleItemClick("vendors")}/>
        {/*}*/}
        {/*{ userCan(user.access, userLevels.VIEW_TRANSACTIONS) && */}
            <Menu.Item name='transactions' active={active() === 'transactions'} onClick={() => handleItemClick("transactions")}/>
        {/*}*/}
        {/*{ userCan(user.access, userLevels.VIEW_JOBS) &&*/}
            <Menu.Item name='jobs' active={active() === 'jobs'} onClick={() => handleItemClick("jobs")}/>
        {/*}*/}
        {/*{ userCan(user.access, userLevels.VIEW_EXPORTS) &&*/}
            <Menu.Item name='exports' active={active() === 'exports'} onClick={() => handleItemClick("exports")}/>
        {/*}*/}
        {/*{ userCan(user.access, userLevels.VIEW_APP_CONFIGS) &&*/}
            <Menu.Item name='app configs' active={active() === 'app-configs'} onClick={() => handleItemClick("app-configs")}/>
        {/*}*/}
      <Menu.Item name='support' active={active() === 'support'}/>
    </Menu>
    );
}

export default withRouter(AppMenu);
