import React, {useContext, useEffect, useState} from 'react';
import {
  Grid, Checkbox, Header, Divider,
  Tab, Input, Button, Container, Dropdown, Label, Message
} from 'semantic-ui-react';
import TopBar from '../../util/topbar';
import Api from "../../util/api";
import {
  AppConfigKeys,
  handleSetPopupMessage, NotifyCode,
  toActualValue,
  toBaseValue,
  // userCan, userLevels
} from "../../util/HelperFunctions";
import {currencyOptions} from "../../util/selectOptions";
import {UserContext} from "../../context/UserProvider";

// const disabledUpdate = !userCan(user.access, userLevels.UPDATE_APP_CONFIGS)
const disabledUpdate = false
const AppSettings = (props) => {
  const {user} = useContext(UserContext)
  const [app_configs,setApp_configs] = useState(null)
  const [networkError, setNetworkError] = useState(false)
  const [allRequests, setAllRequests] = useState(null)
  const [loading, setLoading] = useState(false)

  // Users
  const [kyc_processing,setKyc_processing] = useState(null)
  const [registration,setRegistration] = useState(null)
  const [login,setLogin] = useState(null)
  const [profile_update,setProfile_update] = useState(null)

  // Transfers
  const [inbound_transfers,setInbound_transfers] = useState(null)
  const [outbound_transfers,setOutbound_transfers] = useState(null)
  const [internal_transfers,setInternal_transfers] = useState(null)
  const [min_trans_amount,setMin_trans_amount] = useState(null)
  const [max_trans_amount,setMax_trans_amount] = useState(null)

  // Goals
  const [goal_creation,setGoal_creation] = useState(null)

  // Communications
  const [outbound_emails,setOutbound_emails] = useState(null)
  const [outbound_pn,setOutbound_pn] = useState(null)
  const [outbound_txt,setOutbound_txt] = useState(null)

  // Referrals
  const [enable_referrals,setEnable_referrals] = useState(null)
  const [ref_claims,setRef_claims] = useState(null)
  const [no_ref_claims,setNo_ref_claims] = useState(null)
  // const [ref_claim_amount,setRef_claim_amount] = useState(null)
  const [can_refer_others,setCan_refer_others] = useState(null)
  const [number_of_referrals,setNumber_of_referrals] = useState(null)
  const [earning_per_referral,setEarning_per_referral] = useState(null)
  const [referral_currency,setReferral_currency] = useState(null)
  const [redemption_mode,setRedemption_mode] = useState(null)
  const [minimum_referral_deposit_kec,setMinimum_referral_deposit_kec] = useState(null)
  const [minimum_referral_deposit_ksh,setMinimum_referral_deposit_ksh] = useState(null)
  const [activeIndex,setActiveIndex] = useState(0)

  //Britam
  const [bt_create_account,setBt_create_account] = useState(null)
  const [bt_withdraw,setBt_withdraw] = useState(null)
  const [bt_deposit,setBt_deposit] = useState(null)

  const [popUp, setPopUp] = useState('')
  const [popUpMessage, setPopUpMessage] = useState('')

  const resetPopUp = () =>{
    setPopUp('')
    setPopUpMessage('')
  }

  useEffect(()=>{
    if(!app_configs){
      fetchAppConfigs()
    }
    setTabIndex(props.location.hash)
  },[])

  const fetchAppConfigs = async () =>{
    setNetworkError(false)
    try {
      const {data} = await Api.allAppConfigs()
      if (data.result.app_configs) {
        setApp_configs(data.result.app_configs)
        setAllRequests(null)
        data.result.app_configs.forEach(e=>{
          setAppConfigs(e.key,e.value)
        })
      } else {
        setApp_configs([])
      }
    } catch (err) {
      setApp_configs([])
      /*setNetworkError(true)
      setApp_configs([])
      console.log(err)*/
      handleSetPopupMessage(setPopUp,setPopUpMessage,resetPopUp,NotifyCode.FETCH_JOBS.ERROR,err.data.error.message,-1)
    }
    setLoading(false)
  }

  const saveChanges = async ()=>{
    setLoading(true)
    resetPopUp()
    Object.keys(allRequests).map(async (key)=> {
      try {
        await Api.setAppConfig({key,value:allRequests[key]})
      }catch (err) {
        handleSetPopupMessage(setPopUp,setPopUpMessage,resetPopUp,NotifyCode.UPDATE_APP_CONFIG.ERROR,err.data.error.message,-1)
      }
      //Check for the last key
      if(key === Object.keys(allRequests)[Object.keys(allRequests).length - 1]){
        setLoading(false)
        setAllRequests(null)
        fetchAppConfigs()
      }
    });
  }

  const clearChanges = ()=>{
    setAllRequests(null)
    setKyc_processing(null)
    setRegistration(null)
    setLogin(null)
    setProfile_update(null)
    setInbound_transfers(null)
    setOutbound_transfers(null)
    setInternal_transfers(null)
    setMin_trans_amount(null)
    setMax_trans_amount(null)
    setGoal_creation(null)
    setOutbound_emails(null)
    setOutbound_pn(null)
    setOutbound_txt(null)
    setEnable_referrals(null)
    setRef_claims(null)
    setNo_ref_claims(null)
    setCan_refer_others(null)
    setNumber_of_referrals(null)
    setEarning_per_referral(null)
    setReferral_currency(null)
    setRedemption_mode(null)
    setMinimum_referral_deposit_kec(null)
    setMinimum_referral_deposit_ksh(null)
    setBt_create_account(null)
    setBt_withdraw(null)
    setBt_deposit(null)
    app_configs.forEach(e=>{
      setAppConfigs(e.key,e.value)
    })
  }

  const setAppConfigs = (key,value) =>{
    switch (key){
      //Users
      case AppConfigKeys.KycProcessing:
        setKyc_processing(value)
        break;
      case AppConfigKeys.NewUserRegistration:
        setRegistration(value)
        break;
      case AppConfigKeys.Login:
        setLogin(value)
        break;
      case AppConfigKeys.ProfileUpdate:
        setProfile_update(value)
        break;
      //Transfers
      case AppConfigKeys.InboundTransfers:
        setInbound_transfers(value)
        break;
      case AppConfigKeys.OutboundTransfers:
        setOutbound_transfers(value)
        break;
      case AppConfigKeys.InternalTransfers:
        setInternal_transfers(value)
        break;
      case AppConfigKeys.MinTransactionAmount:
        setMin_trans_amount(value)
        break;
      case AppConfigKeys.MaxTransactionAmount:
        setMax_trans_amount(value)
        break;
      case AppConfigKeys.GoalCreation:
        setGoal_creation(value)
        break;
      //Communication
      case AppConfigKeys.OutboundEmails:
        setOutbound_emails(value)
        break;
      case AppConfigKeys.OutboundPushNotifications:
        setOutbound_pn(value)
        break;
      case AppConfigKeys.OutboundTextMessages:
        setOutbound_txt(value)
        break;
      //Referrals
      case AppConfigKeys.EnableReferrals:
        setEnable_referrals(value)
        break;
      case AppConfigKeys.ReferralClaims:
        setRef_claims(value)
        break;
      case AppConfigKeys.NumberOfReferralClaims:
        setNo_ref_claims(value)
        break;
      case AppConfigKeys.CanReferOthers:
        setCan_refer_others(value)
        break;
      case AppConfigKeys.NumberOfReferrals:
        setNumber_of_referrals(value)
        break;
      case AppConfigKeys.EarningPerReferral:
        setEarning_per_referral(toActualValue(value))
        break;
      case AppConfigKeys.ReferralCurrency:
        setReferral_currency(value)
        break;
      case AppConfigKeys.RedemptionMode:
        setRedemption_mode(value)
        break;
      case AppConfigKeys.MinimumReferralDepositKEC:
        setMinimum_referral_deposit_kec(toActualValue(value))
        break;
      /*case AppConfigKeys.MinimumReferralDepositKSH:
        setMinimum_referral_deposit_ksh(toActualValue(value))
        break;*/
      //Britam
      case AppConfigKeys.BritamCreateAccount:
        setBt_create_account(value)
        break;
      case AppConfigKeys.BritamWithdraw:
        setBt_withdraw(value)
        break;
      case AppConfigKeys.BritamDeposit:
        setBt_deposit(value)
        break;
      default: return;
    }
  }

  const saveSetAppConfigs = (key,value) =>{
    setAppConfigs(key,value)
    setAllRequests({...allRequests,[key]:value})
  }

  const refreshBtn=()=>{
    setApp_configs(null)
    fetchAppConfigs()
  }

  const setTabIndex = (value) => {
    switch (value) {
      case '#users':
        setActiveIndex(0)
        break
      case '#transfers':
        setActiveIndex(1)
        break
      case '#goals':
        setActiveIndex(2)
        break
      case '#communications':
        setActiveIndex(3)
        break
      case '#referrals':
        setActiveIndex(4)
        break
      case '#britam':
        setActiveIndex(5)
        break
      default:
        setActiveIndex(0)
    }
  }

  const setHash = (value) => {
    switch (value) {
      case 0:
        window.location.hash = '#users'
        break
      case 1:
        window.location.hash = '#transfers'
        break
      case 2:
        window.location.hash = '#goals'
        break
      case 3:
        window.location.hash = '#communications'
        break
      case 4:
        window.location.hash = '#referrals'
        break
      case 5:
        window.location.hash = '#britam'
        break
      default:
        window.location.hash = '#users'
    }
  }

  const panes = [
    {
      menuItem: 'Users',
      render: () => <Tab.Pane style={{minHeight: 300}} loading={!app_configs}>
        {(popUp === NotifyCode.UPDATE_APP_CONFIG.ERROR) &&
            <Grid centered><Grid.Row>
              <Grid.Column width={14}>
                <Message negative>
                  <Message.Header>{popUpMessage}</Message.Header>
                </Message>
              </Grid.Column>
            </Grid.Row></Grid>}
        {(popUp === NotifyCode.FETCH_APP_CONFIGS.ERROR) ? <Grid centered>
          <Grid.Row>
            <Grid.Column width={14}>
              <Message negative>
                <Message.Header>{popUpMessage}</Message.Header>
              </Message>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={14}>
              <Button secondary onClick={refreshBtn} icon="redo" content="Refresh" size="tiny" style={{marginLeft: 10}}/>
            </Grid.Column>
          </Grid.Row>
        </Grid>: <>
          <Grid style={{marginTop: 20}}>
            <Grid.Row>
              <Grid.Column width={14}>
                <Header content="Configuration"/>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <Checkbox
                    toggle
                    label='Enable KYC Application'
                    checked={kyc_processing === null ? true: kyc_processing}
                    name={AppConfigKeys.KycProcessing}
                    disabled={disabledUpdate}
                    onChange={(event, {checked})=> saveSetAppConfigs(AppConfigKeys.KycProcessing,checked)}/>
              </Grid.Column>
              <Grid.Column width={5}>
                <Checkbox
                    toggle
                    label='Enable New User Registration'
                    name={AppConfigKeys.NewUserRegistration}
                    checked={registration === null ? true: registration}
                    disabled={disabledUpdate}
                    onChange={(event, {checked})=>
                        saveSetAppConfigs(AppConfigKeys.NewUserRegistration,checked)}/>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <Checkbox
                    toggle
                    label='Enable Login'
                    name={AppConfigKeys.Login}
                    checked={login === null ? true: login}
                    disabled={disabledUpdate}
                    onChange={(event, {checked})=> saveSetAppConfigs(AppConfigKeys.Login,checked)}/>
              </Grid.Column>
              <Grid.Column width={5}>
                <Checkbox
                    toggle
                    label='Enable Profile Update'
                    name={AppConfigKeys.ProfileUpdate}
                    checked={profile_update === null ? true: profile_update}
                    disabled={disabledUpdate}
                    onChange={(event, {checked})=> saveSetAppConfigs(AppConfigKeys.ProfileUpdate,checked)}/>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </> }
        {/*{!networkError?
        <Grid style={{marginTop: 20}}>
          <Grid.Row>
            <Grid.Column width={14}>
              <Header content="Configuration"/>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={5}>
              <Checkbox
                    toggle
                    label='Enable KYC Application'
                    checked={kyc_processing === null ? true: kyc_processing}
                    name={AppConfigKeys.KycProcessing}
                    disabled={disabledUpdate}
                    onChange={(event, {checked})=> saveSetAppConfigs(AppConfigKeys.KycProcessing,checked)}/>
            </Grid.Column>
            <Grid.Column width={5}>
              <Checkbox
                    toggle
                    label='Enable New User Registration'
                    name={AppConfigKeys.NewUserRegistration}
                    checked={registration === null ? true: registration}
                    disabled={disabledUpdate}
                    onChange={(event, {checked})=>
                      saveSetAppConfigs(AppConfigKeys.NewUserRegistration,checked)}/>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={5}>
              <Checkbox
                    toggle
                    label='Enable Login'
                    name={AppConfigKeys.Login}
                    checked={login === null ? true: login}
                    disabled={disabledUpdate}
                    onChange={(event, {checked})=> saveSetAppConfigs(AppConfigKeys.Login,checked)}/>
            </Grid.Column>
            <Grid.Column width={5}>
              <Checkbox
                    toggle
                    label='Enable Profile Update'
                    name={AppConfigKeys.ProfileUpdate}
                    checked={profile_update === null ? true: profile_update}
                    disabled={disabledUpdate}
                    onChange={(event, {checked})=> saveSetAppConfigs(AppConfigKeys.ProfileUpdate,checked)}/>
            </Grid.Column>
          </Grid.Row>
        </Grid>
          :
          <Container text textAlign="center" style={{height: "37vh"}}>
            Network error
            <Button secondary onClick={refreshBtn} icon="redo" content="Refresh" size="tiny" style={{marginLeft: 10}}/>
          </Container>}*/}
      </Tab.Pane>
    },
    {
      menuItem: 'Transfers',
      render: () => <Tab.Pane style={{minHeight: 300}} loading={!app_configs}>
        {(popUp === NotifyCode.UPDATE_APP_CONFIG.ERROR) &&
            <Grid centered><Grid.Row>
              <Grid.Column width={14}>
                <Message negative>
                  <Message.Header>{popUpMessage}</Message.Header>
                </Message>
              </Grid.Column>
            </Grid.Row></Grid>}
        {(popUp === NotifyCode.FETCH_APP_CONFIGS.ERROR) ? <Grid centered>
          <Grid.Row>
            <Grid.Column width={14}>
              <Message negative>
                <Message.Header>{popUpMessage}</Message.Header>
              </Message>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={14}>
              <Button secondary onClick={refreshBtn} icon="redo" content="Refresh" size="tiny" style={{marginLeft: 10}}/>
            </Grid.Column>
          </Grid.Row>
        </Grid>:
            <Grid style={{marginTop: 20}}>
              <Grid.Row>
                <Grid.Column width={14}>
                  <Header content="Configuration"/>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={5}>
                  <Checkbox
                      toggle
                      label='Enable Inbound Transfers'
                      name={AppConfigKeys.InboundTransfers}
                      checked={inbound_transfers === null ? true: inbound_transfers}
                      disabled={disabledUpdate}
                      onChange={(event, {checked})=> saveSetAppConfigs(AppConfigKeys.InboundTransfers,checked)}/>
                </Grid.Column>
                <Grid.Column width={5}>
                  <Checkbox
                      toggle
                      label='Enable Outbound Transfers'
                      name={AppConfigKeys.OutboundTransfers}
                      checked={outbound_transfers === null ? true: outbound_transfers}
                      disabled={disabledUpdate}
                      onChange={(event, {checked})=> saveSetAppConfigs(AppConfigKeys.OutboundTransfers,checked)}/>
                </Grid.Column>
              </Grid.Row>
              {/*<Grid.Row>
            <Grid.Column width={5}>
              <Checkbox
                    toggle
                    label='Enable Goal To External transfers'
                    onChange={(event, {checked})=> saveSetAppConfigs('',checked)}/>
            </Grid.Column>
            <Grid.Column width={5}>
              <Checkbox
                    toggle
                    label='Enable External To Goal Transfers'
                    onChange={(event, {checked})=> saveSetAppConfigs('',checked)}/>
            </Grid.Column>
          </Grid.Row>*/}
              <Grid.Row>
                <Grid.Column width={10}>
                  <Checkbox
                      toggle
                      label='Enable goal to goal transfers'
                      name={AppConfigKeys.InternalTransfers}
                      checked={internal_transfers === null ? true: internal_transfers}
                      disabled={disabledUpdate}
                      onChange={(event, {checked})=> saveSetAppConfigs(AppConfigKeys.InternalTransfers,checked)}/>
                </Grid.Column>
                {/*<Grid.Column width={5}>
              <Checkbox
                    toggle
                    label='Minimum transaction amount'
                    name={AppConfigKeys.MinTransactionAmount}
                    checked={min_trans_amount === null ? true: min_trans_amount}
                    onChange={(event, {checked})=> saveSetAppConfigs(AppConfigKeys.MinTransactionAmount,checked)}/>
            </Grid.Column>*/}
              </Grid.Row>
              {/*<Grid.Row>
            <Grid.Column width={10}>
              <Checkbox
                    toggle
                    label='Maximum transaction amount'
                    name={AppConfigKeys.MaxTransactionAmount}
                    checked={max_trans_amount === null ? true: max_trans_amount}
                    onChange={(event, {checked})=> saveSetAppConfigs(AppConfigKeys.MaxTransactionAmount,checked)}/>
            </Grid.Column>
          </Grid.Row>*/}
            </Grid>}
        {/*{!networkError?
        <Grid style={{marginTop: 20}}>
          <Grid.Row>
            <Grid.Column width={14}>
              <Header content="Configuration"/>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={5}>
              <Checkbox
                    toggle
                    label='Enable Inbound Transfers'
                    name={AppConfigKeys.InboundTransfers}
                    checked={inbound_transfers === null ? true: inbound_transfers}
                    disabled={disabledUpdate}
                    onChange={(event, {checked})=> saveSetAppConfigs(AppConfigKeys.InboundTransfers,checked)}/>
            </Grid.Column>
            <Grid.Column width={5}>
              <Checkbox
                    toggle
                    label='Enable Outbound Transfers'
                    name={AppConfigKeys.OutboundTransfers}
                    checked={outbound_transfers === null ? true: outbound_transfers}
                    disabled={disabledUpdate}
                    onChange={(event, {checked})=> saveSetAppConfigs(AppConfigKeys.OutboundTransfers,checked)}/>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={10}>
              <Checkbox
                    toggle
                    label='Enable goal to goal transfers'
                    name={AppConfigKeys.InternalTransfers}
                    checked={internal_transfers === null ? true: internal_transfers}
                    disabled={disabledUpdate}
                    onChange={(event, {checked})=> saveSetAppConfigs(AppConfigKeys.InternalTransfers,checked)}/>
            </Grid.Column>
          </Grid.Row>
        </Grid>:
        <Container text textAlign="center" style={{height: "37vh"}}>
          Network error
          <Button secondary onClick={refreshBtn} icon="redo" content="Refresh" size="tiny" style={{marginLeft: 10}}/>
        </Container>}*/}
      </Tab.Pane>
    },
    {
      menuItem: 'Goals',
      render: () => <Tab.Pane style={{minHeight: 300}} loading={!app_configs}>
        {(popUp === NotifyCode.UPDATE_APP_CONFIG.ERROR) &&
            <Grid centered><Grid.Row>
              <Grid.Column width={14}>
                <Message negative>
                  <Message.Header>{popUpMessage}</Message.Header>
                </Message>
              </Grid.Column>
            </Grid.Row></Grid>}
        {(popUp === NotifyCode.FETCH_APP_CONFIGS.ERROR) ? <Grid centered>
              <Grid.Row>
                <Grid.Column width={14}>
                  <Message negative>
                    <Message.Header>{popUpMessage}</Message.Header>
                  </Message>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={14}>
                  <Button secondary onClick={refreshBtn} icon="redo" content="Refresh" size="tiny" style={{marginLeft: 10}}/>
                </Grid.Column>
              </Grid.Row>
            </Grid>:
            <Grid style={{marginTop: 20}}>
              <Grid.Row>
                <Grid.Column width={14}>
                  <Header content="Configuration"/>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={5}>
                  <Checkbox
                      toggle
                      label='Enable Goal Creation'
                      checked={goal_creation === null ? true: goal_creation}
                      name={AppConfigKeys.GoalCreation}
                      disabled={disabledUpdate}
                      onChange={(event, {checked})=> saveSetAppConfigs(AppConfigKeys.GoalCreation,checked)}/>
                </Grid.Column>
              </Grid.Row>
            </Grid>}
        {/*{!networkError?
        <Grid style={{marginTop: 20}}>
          <Grid.Row>
            <Grid.Column width={14}>
              <Header content="Configuration"/>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={5}>
                  <Checkbox
                    toggle
                    label='Enable Goal Creation'
                    checked={goal_creation === null ? true: goal_creation}
                    name={AppConfigKeys.GoalCreation}
                    disabled={disabledUpdate}
                    onChange={(event, {checked})=> saveSetAppConfigs(AppConfigKeys.GoalCreation,checked)}/>
            </Grid.Column>
          </Grid.Row>
        </Grid>:
        <Container text textAlign="center" style={{height: "37vh"}}>
          Network error
          <Button secondary onClick={refreshBtn} icon="redo" content="Refresh" size="tiny" style={{marginLeft: 10}}/>
        </Container>}*/}
      </Tab.Pane>
    },
    {
      menuItem: 'Communications',
      render: () => <Tab.Pane style={{minHeight: 300}} loading={!app_configs}>
        {(popUp === NotifyCode.UPDATE_APP_CONFIG.ERROR) &&
            <Grid centered><Grid.Row>
              <Grid.Column width={14}>
                <Message negative>
                  <Message.Header>{popUpMessage}</Message.Header>
                </Message>
              </Grid.Column>
            </Grid.Row></Grid>}
        {(popUp === NotifyCode.FETCH_APP_CONFIGS.ERROR) ? <Grid centered>
              <Grid.Row>
                <Grid.Column width={14}>
                  <Message negative>
                    <Message.Header>{popUpMessage}</Message.Header>
                  </Message>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={14}>
                  <Button secondary onClick={refreshBtn} icon="redo" content="Refresh" size="tiny" style={{marginLeft: 10}}/>
                </Grid.Column>
              </Grid.Row>
            </Grid>:
            <Grid style={{marginTop: 20}}>
              <Grid.Row>
                <Grid.Column width={14}>
                  <Header content="Configuration"/>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={5}>
                  <Checkbox
                      toggle
                      label='Enable Sending Emails'
                      name={AppConfigKeys.OutboundEmails}
                      checked={outbound_emails === null ? true: outbound_emails}
                      disabled={disabledUpdate}
                      onChange={(event, {checked})=> saveSetAppConfigs(AppConfigKeys.OutboundEmails,checked)}/>
                </Grid.Column>
                <Grid.Column width={5}>
                  <Checkbox
                      toggle
                      label='Enable Sending PushNotifications'
                      name={AppConfigKeys.OutboundPushNotifications}
                      checked={outbound_pn === null ? true: outbound_pn}
                      disabled={disabledUpdate}
                      onChange={(event, {checked})=> saveSetAppConfigs(AppConfigKeys.OutboundPushNotifications,checked)}/>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={10}>
                  <Checkbox
                      toggle
                      label='Enable Sending Text Messages'
                      name={AppConfigKeys.OutboundTextMessages}
                      checked={outbound_txt === null ? true: outbound_txt}
                      disabled={disabledUpdate}
                      onChange={(event, {checked})=> saveSetAppConfigs(AppConfigKeys.OutboundTextMessages,checked)}/>
                </Grid.Column>
              </Grid.Row>
            </Grid>}
        {/*{!networkError?
        <Grid style={{marginTop: 20}}>
          <Grid.Row>
            <Grid.Column width={14}>
              <Header content="Configuration"/>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={5}>
                  <Checkbox
                    toggle
                    label='Enable Sending Emails'
                    name={AppConfigKeys.OutboundEmails}
                    checked={outbound_emails === null ? true: outbound_emails}
                    disabled={disabledUpdate}
                    onChange={(event, {checked})=> saveSetAppConfigs(AppConfigKeys.OutboundEmails,checked)}/>
            </Grid.Column>
            <Grid.Column width={5}>
                  <Checkbox
                    toggle
                    label='Enable Sending PushNotifications'
                    name={AppConfigKeys.OutboundPushNotifications}
                    checked={outbound_pn === null ? true: outbound_pn}
                    disabled={disabledUpdate}
                    onChange={(event, {checked})=> saveSetAppConfigs(AppConfigKeys.OutboundPushNotifications,checked)}/>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={10}>
                  <Checkbox
                    toggle
                    label='Enable Sending Text Messages'
                    name={AppConfigKeys.OutboundTextMessages}
                    checked={outbound_txt === null ? true: outbound_txt}
                    disabled={disabledUpdate}
                    onChange={(event, {checked})=> saveSetAppConfigs(AppConfigKeys.OutboundTextMessages,checked)}/>
            </Grid.Column>
          </Grid.Row>
        </Grid>:
        <Container text textAlign="center" style={{height: "37vh"}}>
          Network error
          <Button secondary onClick={refreshBtn} icon="redo" content="Refresh" size="tiny" style={{marginLeft: 10}}/>
        </Container>}*/}
      </Tab.Pane>
    },
    {
      menuItem: 'Referrals',
      render: () => <Tab.Pane style={{minHeight: 300}} loading={!app_configs}>
        {(popUp === NotifyCode.UPDATE_APP_CONFIG.ERROR) &&
            <Grid centered><Grid.Row>
              <Grid.Column width={14}>
                <Message negative>
                  <Message.Header>{popUpMessage}</Message.Header>
                </Message>
              </Grid.Column>
            </Grid.Row></Grid>}
        {(popUp === NotifyCode.FETCH_APP_CONFIGS.ERROR) ? <Grid centered>
              <Grid.Row>
                <Grid.Column width={14}>
                  <Message negative>
                    <Message.Header>{popUpMessage}</Message.Header>
                  </Message>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={14}>
                  <Button secondary onClick={refreshBtn} icon="redo" content="Refresh" size="tiny" style={{marginLeft: 10}}/>
                </Grid.Column>
              </Grid.Row>
            </Grid>:
            <Grid style={{marginTop: 20}}>
              <Grid.Row>
                <Grid.Column width={14}>
                  <Header content="Attribute"/>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={14}>
                  <Checkbox
                      toggle
                      label='Enable Referral Claims'
                      name={AppConfigKeys.ReferralClaims}
                      checked={ref_claims === null ? true: ref_claims}
                      disabled={disabledUpdate}
                      onChange={(event, {checked})=> saveSetAppConfigs(AppConfigKeys.ReferralClaims,checked)}/>
                </Grid.Column>
              </Grid.Row>
              <Divider/>
              <Grid.Row>
                <Grid.Column width={14}>
                  <Header content="Configuration"/>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={7}>
                  <Checkbox
                      toggle
                      label='Enable Referrals'
                      name={AppConfigKeys.EnableReferrals}
                      checked={enable_referrals === null ? true: enable_referrals}
                      disabled={disabledUpdate}
                      onChange={(event, {checked})=> saveSetAppConfigs(AppConfigKeys.EnableReferrals,checked)}/>
                </Grid.Column>
                <Grid.Column width={7}>
                  <Checkbox
                      toggle
                      label='Can Refer Others'
                      name={AppConfigKeys.CanReferOthers}
                      checked={can_refer_others === null ? true: can_refer_others}
                      disabled={disabledUpdate}
                      onChange={(event, {checked})=> saveSetAppConfigs(AppConfigKeys.CanReferOthers,checked)}/>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={7}>
                  <Label style={{fontSize: '1em', paddingTop: '0.73em', paddingBottom: '0.73em', marginRight: 0,
                    borderTopRightRadius: 0, borderBottomRightRadius:0}}>
                    Redemption Mode
                  </Label>
                  <Dropdown
                      selection
                      placeholder='Redemption Mode'
                      value={redemption_mode}
                      options={[
                        {key: 'manual', text: 'Manual', value: 'manual'},
                        {key: 'automatic', text: 'Automatic', value: 'automatic'},
                      ]}
                      disabled={disabledUpdate}
                      onChange={(event, {value}) =>
                          saveSetAppConfigs(AppConfigKeys.RedemptionMode,value)}
                      style={{borderTopLeftRadius: 0, borderBottomLeftRadius:0}}
                  />
                </Grid.Column>
                <Grid.Column width={7}>
                  <Label style={{fontSize: '1em', paddingTop: '0.73em', paddingBottom: '0.73em', marginRight: 0,
                    borderTopRightRadius: 0, borderBottomRightRadius:0}}>
                    Referral Currency
                  </Label>
                  <Dropdown
                      selection
                      placeholder='Referral Currency'
                      value={referral_currency}
                      options={currencyOptions}
                      disabled={disabledUpdate}
                      onChange={(event, {value}) =>
                          saveSetAppConfigs(AppConfigKeys.ReferralCurrency,value)}
                      style={{borderTopLeftRadius: 0, borderBottomLeftRadius:0}}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row >
                <Grid.Column width={7}>
                  <Input type="number" label="Number Of Referral" value={number_of_referrals || ''}
                         disabled={disabledUpdate}
                         onChange={event => saveSetAppConfigs(AppConfigKeys.NumberOfReferrals,Number(event.target.value))}/>
                </Grid.Column>
                <Grid.Column width={7}>
                  <Input type="number" label="Number Of Referral Claims" value={no_ref_claims || ''}
                         disabled={disabledUpdate}
                         onChange={event => saveSetAppConfigs(AppConfigKeys.NumberOfReferralClaims,Number(event.target.value))}/>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row >
                <Grid.Column width={7}>
                  <Input type="number" label="Earning Per Referral" value={earning_per_referral || ''}
                         disabled={disabledUpdate}
                         onChange={event => saveSetAppConfigs(AppConfigKeys.EarningPerReferral,toBaseValue(event.target.value))}/>
                </Grid.Column>
                <Grid.Column width={7}>
                  <Input type="number" label="Minimum Referral Deposit in KSH" value={minimum_referral_deposit_kec || ''}
                         disabled={disabledUpdate}
                         onChange={event => saveSetAppConfigs(AppConfigKeys.MinimumReferralDepositKEC,toBaseValue(event.target.value))}/>
                </Grid.Column>
              </Grid.Row>
            </Grid>}
        {/*{!networkError?
        <Grid style={{marginTop: 20}}>
          <Grid.Row>
            <Grid.Column width={14}>
              <Header content="Attribute"/>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={14}>
              <Checkbox
                  toggle
                  label='Enable Referral Claims'
                  name={AppConfigKeys.ReferralClaims}
                  checked={ref_claims === null ? true: ref_claims}
                  disabled={disabledUpdate}
                  onChange={(event, {checked})=> saveSetAppConfigs(AppConfigKeys.ReferralClaims,checked)}/>
            </Grid.Column>
          </Grid.Row>
          <Divider/>
          <Grid.Row>
            <Grid.Column width={14}>
              <Header content="Configuration"/>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={7}>
                  <Checkbox
                    toggle
                    label='Enable Referrals'
                    name={AppConfigKeys.EnableReferrals}
                    checked={enable_referrals === null ? true: enable_referrals}
                    disabled={disabledUpdate}
                    onChange={(event, {checked})=> saveSetAppConfigs(AppConfigKeys.EnableReferrals,checked)}/>
            </Grid.Column>
            <Grid.Column width={7}>
              <Checkbox
                  toggle
                  label='Can Refer Others'
                  name={AppConfigKeys.CanReferOthers}
                  checked={can_refer_others === null ? true: can_refer_others}
                  disabled={disabledUpdate}
                  onChange={(event, {checked})=> saveSetAppConfigs(AppConfigKeys.CanReferOthers,checked)}/>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={7}>
              <Label style={{fontSize: '1em', paddingTop: '0.73em', paddingBottom: '0.73em', marginRight: 0,
                borderTopRightRadius: 0, borderBottomRightRadius:0}}>
                Redemption Mode
              </Label>
              <Dropdown
                selection
                placeholder='Redemption Mode'
                value={redemption_mode}
                options={[
                  {key: 'manual', text: 'Manual', value: 'manual'},
                  {key: 'automatic', text: 'Automatic', value: 'automatic'},
                ]}
                disabled={disabledUpdate}
                onChange={(event, {value}) =>
                  saveSetAppConfigs(AppConfigKeys.RedemptionMode,value)}
                style={{borderTopLeftRadius: 0, borderBottomLeftRadius:0}}
              />
            </Grid.Column>
            <Grid.Column width={7}>
              <Label style={{fontSize: '1em', paddingTop: '0.73em', paddingBottom: '0.73em', marginRight: 0,
                borderTopRightRadius: 0, borderBottomRightRadius:0}}>
                Referral Currency
              </Label>
              <Dropdown
                  selection
                  placeholder='Referral Currency'
                  value={referral_currency}
                  options={currencyOptions}
                  disabled={disabledUpdate}
                  onChange={(event, {value}) =>
                      saveSetAppConfigs(AppConfigKeys.ReferralCurrency,value)}
                  style={{borderTopLeftRadius: 0, borderBottomLeftRadius:0}}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row >
            <Grid.Column width={7}>
                <Input type="number" label="Number Of Referral" value={number_of_referrals || ''}
                       disabled={disabledUpdate}
                       onChange={event => saveSetAppConfigs(AppConfigKeys.NumberOfReferrals,Number(event.target.value))}/>
            </Grid.Column>
            <Grid.Column width={7}>
                <Input type="number" label="Number Of Referral Claims" value={no_ref_claims || ''}
                       disabled={disabledUpdate}
                       onChange={event => saveSetAppConfigs(AppConfigKeys.NumberOfReferralClaims,Number(event.target.value))}/>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row >
            <Grid.Column width={7}>
                <Input type="number" label="Earning Per Referral" value={earning_per_referral || ''}
                       disabled={disabledUpdate}
                       onChange={event => saveSetAppConfigs(AppConfigKeys.EarningPerReferral,toBaseValue(event.target.value))}/>
            </Grid.Column>
            <Grid.Column width={7}>
              <Input type="number" label="Minimum Referral Deposit in KSH" value={minimum_referral_deposit_kec || ''}
                     disabled={disabledUpdate}
                     onChange={event => saveSetAppConfigs(AppConfigKeys.MinimumReferralDepositKEC,toBaseValue(event.target.value))}/>
            </Grid.Column>
          </Grid.Row>
        </Grid>:
        <Container text textAlign="center" style={{height: "37vh"}}>
          Network error
          <Button secondary onClick={refreshBtn} icon="redo" content="Refresh" size="tiny" style={{marginLeft: 10}}/>
        </Container>}*/}
      </Tab.Pane>
    },
    {
      menuItem: 'Britam',
      render: () => <Tab.Pane style={{minHeight: 300}} loading={!app_configs}>
        {(popUp === NotifyCode.UPDATE_APP_CONFIG.ERROR) &&
            <Grid centered><Grid.Row>
              <Grid.Column width={14}>
                <Message negative>
                  <Message.Header>{popUpMessage}</Message.Header>
                </Message>
              </Grid.Column>
            </Grid.Row></Grid>}
        {(popUp === NotifyCode.FETCH_APP_CONFIGS.ERROR) ? <Grid centered>
              <Grid.Row>
                <Grid.Column width={14}>
                  <Message negative>
                    <Message.Header>{popUpMessage}</Message.Header>
                  </Message>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={14}>
                  <Button secondary onClick={refreshBtn} icon="redo" content="Refresh" size="tiny" style={{marginLeft: 10}}/>
                </Grid.Column>
              </Grid.Row>
            </Grid>:
            <Grid style={{marginTop: 20}}>
              <Grid.Row>
                <Grid.Column width={5}>
                  <Checkbox
                      toggle
                      label="Enable Britam Create Account"
                      name={AppConfigKeys.BritamCreateAccount}
                      checked={bt_create_account === null ? true: bt_create_account}
                      disabled={disabledUpdate}
                      onChange={(event, {checked})=> saveSetAppConfigs(AppConfigKeys.BritamCreateAccount,checked)}/>
                </Grid.Column>
                <Grid.Column width={5}>
                  <Checkbox
                      toggle
                      label="Enable Britam Withdraw"
                      name={AppConfigKeys.BritamWithdraw}
                      checked={bt_withdraw === null ? true: bt_withdraw}
                      disabled={disabledUpdate}
                      onChange={(event, {checked})=> saveSetAppConfigs(AppConfigKeys.BritamWithdraw,checked)}/>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={10}>
                  <Checkbox
                      toggle
                      label="Enable Britam Deposit"
                      name={AppConfigKeys.BritamDeposit}
                      checked={bt_deposit === null ? true: bt_deposit}
                      disabled={disabledUpdate}
                      onChange={(event, {checked})=> saveSetAppConfigs(AppConfigKeys.BritamDeposit,checked)}/>
                </Grid.Column>
              </Grid.Row>
            </Grid>}
        {/*{!networkError?
        <Grid style={{marginTop: 20}}>
          <Grid.Row>
            <Grid.Column width={5}>
                  <Checkbox
                    toggle
                    label="Enable Britam Create Account"
                    name={AppConfigKeys.BritamCreateAccount}
                    checked={bt_create_account === null ? true: bt_create_account}
                    disabled={disabledUpdate}
                    onChange={(event, {checked})=> saveSetAppConfigs(AppConfigKeys.BritamCreateAccount,checked)}/>
            </Grid.Column>
            <Grid.Column width={5}>
                  <Checkbox
                    toggle
                    label="Enable Britam Withdraw"
                    name={AppConfigKeys.BritamWithdraw}
                    checked={bt_withdraw === null ? true: bt_withdraw}
                    disabled={disabledUpdate}
                    onChange={(event, {checked})=> saveSetAppConfigs(AppConfigKeys.BritamWithdraw,checked)}/>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={10}>
                  <Checkbox
                    toggle
                    label="Enable Britam Deposit"
                    name={AppConfigKeys.BritamDeposit}
                    checked={bt_deposit === null ? true: bt_deposit}
                    disabled={disabledUpdate}
                    onChange={(event, {checked})=> saveSetAppConfigs(AppConfigKeys.BritamDeposit,checked)}/>
            </Grid.Column>
          </Grid.Row>
        </Grid>:
        <Container text textAlign="center" style={{height: "37vh"}}>
          Network error
          <Button secondary onClick={refreshBtn} icon="redo" content="Refresh" size="tiny" style={{marginLeft: 10}}/>
        </Container>}*/}
      </Tab.Pane>
    },
  ]

  return (
    <>
      <TopBar name={props.title} actions={
        // userCan(user.access, userLevels.UPDATE_APP_CONFIGS) &&
        [
          {render: () => !!allRequests && <>
              <Button basic color="red" disabled={loading} content="Clear Changes" onClick={clearChanges}/>
              <Button basic color="green" disabled={loading} loading={loading} content="Save Changes" onClick={saveChanges}/>
              </>},
        ]
      }/>
      <Tab panes={panes} activeIndex={activeIndex}
           onTabChange={(event, {activeIndex})=> {
             setHash(activeIndex)
             setActiveIndex(activeIndex)
           }}/>
    </>
  );
}

export default AppSettings;

