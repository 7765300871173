import React from 'react';
import {Button, Container, Table} from 'semantic-ui-react';
import {currencyCode, searchOnTable, toReadableValue,
    // userCan, userLevels
} from "../../util/HelperFunctions";
// import {UserContext} from "../../context/UserProvider";

const ProductList = ({data,handleView,searchTable}) => {
    // const {user} = useContext(UserContext)
  return (
    <>
      {data && data.length ?
      <Table singleLine basic='very' striped fixed>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Goal Name</Table.HeaderCell>
            <Table.HeaderCell>Target</Table.HeaderCell>
            <Table.HeaderCell>Recurrence</Table.HeaderCell>
            <Table.HeaderCell>Deposits</Table.HeaderCell>
              {/*{ userCan(user.access, userLevels.VIEW_PRODUCT_DETAILS) &&*/}
                  <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
              {/*}*/}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {data.map((item,i) =>searchOnTable(searchTable,
            [item.name,item.desc, item.data.recurrence.value,item.data.target.value,item.data.deposits.value,
              `${toReadableValue(item.data.target.value,item.data.currency_code.value)} ${currencyCode(item.data.currency_code.value)}`,
              `${toReadableValue(item.data.deposits.value,item.data.currency_code.value)} ${currencyCode(item.data.currency_code.value)}`]) ?
            <Table.Row key={i}>
              <Table.Cell>{item.name}</Table.Cell>
              <Table.Cell>{`${toReadableValue(item.data.target.value,item.data.currency_code.value)} ${currencyCode(item.data.currency_code.value)}`}</Table.Cell>
              <Table.Cell>{item.data.recurrence.value}</Table.Cell>
              <Table.Cell>{`${toReadableValue(item.data.deposits.value,item.data.currency_code.value)} ${currencyCode(item.data.currency_code.value)}`}</Table.Cell>
                {/*{ userCan(user.access, userLevels.VIEW_PRODUCT_DETAILS) && */}
                    <Table.Cell style={{float:'right'}}>
                <Button icon={"file alternate outline"} content='View Details' size="mini" color={"green"} onClick={()=> handleView(i)}/>
              </Table.Cell>
                {/*}*/}
            </Table.Row>:null
          )
          }
        </Table.Body>
      </Table>
        :
        <Container text textAlign="center" content="No records found" style={{height: "37vh"}}/>
      }
      </>
    );
}


export default ProductList;

