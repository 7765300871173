import React, {useContext} from 'react';
import {Button, Container, Label, Table} from 'semantic-ui-react';
import {
  currencyCode,
  searchOnTable,
  toBasePercentage,
  toReadableValue,
  // userCan, userLevels,
  yesOrNoColor
} from "../../util/HelperFunctions";
import {UserContext} from "../../context/UserProvider";

const VendorList = ({data,handleView,searchTable}) => {
  // const {user} = useContext(UserContext)
  return (
    <>
      {data && data.length ?
      <Table singleLine basic='very' striped fixed>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Minimum Balance</Table.HeaderCell>
            <Table.HeaderCell>Base Rate</Table.HeaderCell>
            <Table.HeaderCell>Enabled</Table.HeaderCell>
            {/*{ userCan(user.access, userLevels.VIEW_VENDOR_DETAILS) && */}
                <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
            {/*}*/}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {data.map((item,i) =>searchOnTable(searchTable,
            [item.id,item.name,toBasePercentage(item.base_rate),
              `${toReadableValue(item.min_balance,item.currency_code)} ${currencyCode(item.currency_code)}`]) ?
            <Table.Row key={i}>
              <Table.Cell>{item.id}</Table.Cell>
              <Table.Cell>{item.name}</Table.Cell>
              <Table.Cell>{`${toReadableValue(item.min_balance,item.currency_code)} ${currencyCode(item.currency_code)}`}</Table.Cell>
              <Table.Cell>{toBasePercentage(item.base_rate)}%</Table.Cell>
              <Table.Cell>
                <Label
                color={yesOrNoColor(item.enabled)}
                size="medium" content={item.enabled?'True' : 'False'} />
              </Table.Cell>
              {/*{ userCan(user.access, userLevels.VIEW_VENDOR_DETAILS) && */}
                  <Table.Cell style={{float:'right'}}>
                <Button icon="file alternate outline" content='View Details' size="mini" color="green" onClick={()=> handleView(i)}/>
              </Table.Cell>
              {/*}*/}
            </Table.Row>:null
          )
          }
        </Table.Body>
      </Table>
        :
        <Container text textAlign="center" content="No records found" style={{height: "37vh"}}/>
      }
      </>
    );
}


export default VendorList;

