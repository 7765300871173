import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/remote-config"

const firebaseConfig = {
  apiKey: "AIzaSyCGQpYmk-cPaQmvAsVEgq0K2OxVwJaTSrY",
  authDomain: "oka-cash.firebaseapp.com",
  databaseURL: "https://oka-cash.firebaseio.com",
  projectId: "oka-cash",
  storageBucket: "oka-cash.appspot.com",
  messagingSenderId: "704897129556",
  appId: "1:704897129556:web:716046aba939db48aaa1c1",
  measurementId: "G-NPG5VENFES"
};

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const remoteConfig = firebase.remoteConfig();
remoteConfig.settings = {
  fetchTimeoutMillis: 6000,
  minimumFetchIntervalMillis: 3600000
}


remoteConfig.fetchAndActivate().then(() => {})
  .catch((err) => {
    console.error(err);
  });

const provider = new firebase.auth.GoogleAuthProvider();

export const signInWithGoogle = () => {
  return auth.signInWithPopup(provider);
};