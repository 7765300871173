import React, {useContext} from 'react';
import {Button, Container, Label, Table} from 'semantic-ui-react';
import {
    searchOnTable, statusColor, statusName,
    toReadableDateTime, userCan, userLevels,
} from "../../util/HelperFunctions";
import {UserContext} from "../../context/UserProvider";

const UserExportList = ({data,handleView,searchTable}) => {
    const {user} = useContext(UserContext)
  return (
    <>
      {data && data.length ?
      <Table singleLine basic='very' striped fixed>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>State</Table.HeaderCell>
            <Table.HeaderCell>Date</Table.HeaderCell>
              { userCan(user.access, userLevels.VIEW_EXPORT_DETAILS) &&<Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {data.map((item,i) =>searchOnTable(searchTable,
            [item.id,item.state,item.created_at,toReadableDateTime(item.created_at)]) ?
            <Table.Row key={i}>
              <Table.Cell>{item.id.substring(0,8)}...</Table.Cell>
              <Table.Cell>
                <Label
                color={statusColor(item.state)}
                size="medium" content={statusName(item.state)} /></Table.Cell>
              <Table.Cell>{toReadableDateTime(item.created_at)}</Table.Cell>
                { userCan(user.access, userLevels.VIEW_EXPORT_DETAILS) &&<Table.Cell style={{float:'right'}}>
                <Button icon="file alternate outline" content='View Details' size="mini" color="green" onClick={()=> handleView(item)}/>
              </Table.Cell>}
            </Table.Row>:null
          )
          }
        </Table.Body>
      </Table>
        :
        <Container text textAlign="center" content="No records found" style={{height: "37vh"}}/>
      }
      </>
    );
}


export default UserExportList;

