import React from "react";
import {Accordion, Button, Dropdown, Form, Grid, Icon, Label, Table} from "semantic-ui-react";
import {
    currencyCode, getTransactionDirection,
    getTransactionType, isLargeScreen, statusColor, statusName, toReadableDateTime,
    toReadableValue,
    transactionDirectionColor,
    transactionTypeColor
} from "../util/HelperFunctions";
import TransactionDetails from "./TransactionDetails";

function GoalTransactions({e,ii,item,activeGoalTransactionIndex,handleActiveGoalTransaction,goalTransactionState,goalTransactionStateOpen,
                              goalTransactionStateClose,handleSetGoalTransactionState,viewGoalsTransaction,transactions,groupMembers}){

    const getOwner = (value) => groupMembers && groupMembers.length > 0 ? groupMembers.find(e=> !!value.meta.group_transaction.group_member_id && value.meta.group_transaction.group_member_id === e.user_id)?? null : null

    return  <>
        <Accordion.Title
            active={activeGoalTransactionIndex.includes(e.id)}
            index={e.id}
            onClick={handleActiveGoalTransaction}
        >
            <Table singleLine>
                <Table.Body>
                    <Table.Row key={e.id}>
                        {item !== null && goalTransactionState === item.id && <Table.Cell>
                            <Form.Field>
                                <Dropdown
                                    placeholder='State'
                                    fluid
                                    selection
                                    value={goalTransactionStateOpen.includes(e.id) ?
                                        'open' : goalTransactionStateClose.includes(e.id)? 'closed' : e.state}
                                    options={[
                                        {key: 'open', text: 'Open', value: 'open'},
                                        {key: 'closed', text: 'Close', value: 'closed'},
                                    ]}
                                    onChange={(event, {value}) => handleSetGoalTransactionState(e.id,value)}
                                />
                            </Form.Field>
                        </Table.Cell>}
                        <Table.Cell><Icon name='dropdown' /></Table.Cell>
                        <Table.Cell>{e.id}</Table.Cell>
                        <Table.Cell>{e.ref}</Table.Cell>
                        <Table.Cell>{toReadableValue(e.amount,e.currency_code)} {currencyCode(e.currency_code)}</Table.Cell>
                        <Table.Cell>
                            <Label
                                color={e.transfer_type === 'interest' ? transactionTypeColor(e.transfer_type):transactionDirectionColor(e.direction)}
                                size="medium" content={e.transfer_type === 'interest' ?getTransactionType(e.transfer_type):getTransactionDirection(e.direction)} />
                        </Table.Cell>
                        <Table.Cell>
                            <Label
                                color={statusColor(e.status)}
                                size="medium" content={statusName(e.status)} />
                        </Table.Cell>
                        {isLargeScreen() && <Table.Cell>{toReadableDateTime(e.created_at)}</Table.Cell>}
                    </Table.Row>
                </Table.Body>
            </Table>
        </Accordion.Title>
        <Accordion.Content active={activeGoalTransactionIndex.includes(e.id)}>
            <Grid centered>
                <Grid.Row textAlign="right">
                    <Grid.Column width={7} floated="right">
                        <Button icon="file alternate outline" content='View Details' size="mini" color="green" floated="right"
                                onClick={() => viewGoalsTransaction(ii,item === null || transactions !== null ? transactions : item.transactions)}/>
                    </Grid.Column>
                </Grid.Row>
                <TransactionDetails transaction={e} />
            </Grid>
        </Accordion.Content>
    </>
}

export default GoalTransactions