import React from 'react';
import {Button, Container, Label, Table} from 'semantic-ui-react';
import {searchOnTable,
    // userCan, userLevels,
    yesOrNoColor, yesOrNoName} from "../../util/HelperFunctions";
// import {UserContext} from "../../context/UserProvider";

const CategoryList = ({data,handleEdit,handleView,handleDelete,searchTable}) => {
    // const {user} = useContext(UserContext)
  return (
    <>
      {data && data.length ?
        <Table singleLine basic='very' striped >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Description</Table.HeaderCell>
            <Table.HeaderCell>Enabled</Table.HeaderCell>
              {/*{ (userCan(user.access, userLevels.VIEW_CATEGORY_DETAILS) || userCan(user.access, userLevels.EDIT_CATEGORY) || userCan(user.access, userLevels.DELETE_CATEGORY) ) &&*/}
              <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
              {/*}*/}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {data.map((item,i) =>searchOnTable(searchTable,
            [item.name,item.desc,yesOrNoName(item.enabled)]) ?
            <Table.Row key={i}>
              <Table.Cell>{item.name}</Table.Cell>
              <Table.Cell style={{maxWidth:300,overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap"}}>
                {item.desc}
              </Table.Cell>
              <Table.Cell>
                <Label
                  color={yesOrNoColor(item.enabled)}
                  basic
                  size="medium"
                  content={yesOrNoName(item.enabled)}/>
              </Table.Cell>
                {/*{ (userCan(user.access, userLevels.VIEW_CATEGORY_DETAILS) || userCan(user.access, userLevels.EDIT_CATEGORY) || userCan(user.access, userLevels.DELETE_CATEGORY) ) &&*/}
                <Table.Cell style={{float:'right'}}>
                    {/*{ userCan(user.access, userLevels.EDIT_CATEGORY) &&*/}
                        <Button icon={"pencil"} content='Edit' size="mini" color={"blue"} onClick={()=> handleEdit(i)}/>
                    {/*}*/}
                    {/*{ userCan(user.access, userLevels.DELETE_CATEGORY) && */}
                        <Button icon={"trash"} content='Delete' size="mini" color={"red"} onClick={()=> handleDelete(i)}/>
                    {/*}*/}
                    {/*{ userCan(user.access, userLevels.VIEW_CATEGORY_DETAILS) && */}
                        <Button icon={"file alternate outline"} content='View Details' size="mini" color={"green"} onClick={()=> handleView(i)}/>
                    {/*}*/}
              </Table.Cell>
                {/*}*/}
            </Table.Row>:null
          )
          }
        </Table.Body>
      </Table>
        : <Container text textAlign="center" content="No records found" style={{height: "37vh"}}/>}
      </>
    );
}

export default CategoryList;

