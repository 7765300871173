import React from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import {checkTokenExpirationMiddleware} from './util/auth';
import routes from "./store/routes";
import UserProvider from "./context/UserProvider";

const App = () => (
  <Router>
    <Switch>
      {
        routes.map((route, i) => {
            return <Route key={i} exact={route.exact} path={route.path} render={props =>
              route.private ? (
                checkTokenExpirationMiddleware() ?
                    <UserProvider>
                  <route.layout {...props} showMenu={route.showMenu} access={route.access}>
                    <route.component{...props} title={route.title}/>
                  </route.layout></UserProvider> : // success component
                  <Redirect to={{pathname: route.redirect, state: {from: props.location}}}/>// failure component
              ):
                (
                  checkTokenExpirationMiddleware() ?
                    <Redirect to={{pathname: route.redirect, state: {from: props.location}}}/>:
                    <route.layout {...props}>
                      <route.component{...props} title={route.title}/>
                    </route.layout>
                )
            }/>
          })
      }
      <Redirect to="/"/>
    </Switch>
  </Router>
);

export default App;
