import React, {createContext, useState} from "react";
import {STORAGE_KEYS} from "../util/api";
import {removeKeys} from "../util/auth";

export const UserContext = createContext({user:null})

const UserProvider = (props) =>{
  const [user, setUser] = useState({...JSON.parse(localStorage.getItem(STORAGE_KEYS.USER))})
  const logout = () =>{
    removeKeys()
    window.location.replace('/login')
  }
  return <UserContext.Provider value={{user,setUser,logout}}>
    {props.children}
  </UserContext.Provider>
}
export default UserProvider