import React, {useContext, useEffect, useState} from 'react';
import {Button, Card, Container, Form, Grid, Header, Input, Label, Tab} from 'semantic-ui-react';
import TopBar from '../../util/topbar';
import TransactionExportList from "./TransactionExportList";
import SideModal from "../../util/SideModal";
import Api from "../../util/api";
import {
  statusColor,
  statusName,
  toDateTimeDifference,
  toReadableDateTime, userCan, userLevels,
  yesOrNoColor
} from "../../util/HelperFunctions";
import {DateTimeInput} from "semantic-ui-calendar-react";
import UserExportList from "./UserExportList";
import {UserContext} from "../../context/UserProvider";

const Exports = (props) => {
  const {user} = useContext(UserContext)
  const [modalOpen, setModalOpen] = useState(false)
  const [startBatchModalOpen, setStartBatchModalOpen] = useState(false)
  const [viewModalOpen, setViewModalOpen] = useState(false)
  const [viewUserExportModalOpen, setViewUserExportModalOpen] = useState(false)
  const [submitLoader, setSubmitLoader] = useState(false)
  const [batch, setBatch] = useState(null)
  const [batches, setBatches] = useState(null)
  const [networkError, setNetworkError] = useState(false)
  const [searchTable, setSearchTable] = useState("")
  const [vendors, setVendors] = useState(null)
  const [run_at, setRun_at] = useState('')
  const [userExports, setUserExports] = useState(null)
  const [userExport, setUserExport] = useState(null)
  const [networkErrorUserExport, setNetworkErrorUserExport] = useState(false)
  const [to, setTo] = useState('')
  const [from, setFrom] = useState('')
  const [timeDiff,setTimeDiff] = useState(null)
  const [activeTab, setActiveTab] = useState(0)

  useEffect(() => {
    if(props.location.hash === '#users'){
      setActiveTab(1)
    }
    if (!batches) {
      fetchBatches()
    }
    if (!userExports) {
      fetchUserExports()
    }
    if (!vendors) {
      fetchVendors()
    }
  }, [])

  const fetchBatches = async () => {
    try {
      const {data} = await Api.allBatches()
      if (data.result.batch_jobs) {
        setBatches(data.result.batch_jobs)
      } else {
        setBatches([])
      }
    } catch (err) {
      setNetworkError(true)
      setBatches([])
      console.log('err', err)
    }
  }

  const fetchUserExports = async () => {
    try {
      const {data} = await Api.allUserExport()
      if (data.result.bulk_exports) {
        setUserExports(data.result.bulk_exports)
      } else {
        setUserExports([])
      }
    } catch (err) {
      setNetworkErrorUserExport(true)
      setUserExports([])
      console.log('err', err)
    }
  }

  const fetchVendors = async () => {
    try {
      const {data} = await Api.allVendors()
      if (data.result.vendors) {
        setVendors(data.result.vendors)
      } else {
        setVendors([])
      }
    } catch (err) {
      setNetworkError(true)
      setVendors([])
      console.log('err', err)
    }
  }

  const handleStartBatch = async (e) => {
    e.preventDefault()
    setSubmitLoader(true)
    try {
      let params = {
        id: batch.id,
        run_at: formatDate(run_at),
      }
      const {data} = await Api.startBatch(params)
      setBatch(data.result.batch_job)
      setBatches([...batches.map(item => item.id === data.result.batch_job.id ? {...data.result.batch_job} : item)])
      handleCloseEditModal()
    } catch (err) {
      console.log(err)
    }
    setSubmitLoader(false)
  }

  const handleCreateUserExport = async (e) => {
    e.preventDefault()
    setSubmitLoader(true)
    try {
      let params = {
        from: formatDate(from),
        to: formatDate(to),
      }
      const {data} = await Api.createUserExport(params)
      setBatch(data.result.bulk_export)
      setUserExports([{...data.result.bulk_export},...userExports])
      handleClose()
    } catch (err) {
      console.log(err)
    }
    setSubmitLoader(false)
  }

  const openModal = () => {
    setModalOpen(true)
    const date = new Date()
    setTo(formatDefaultDate(date))
    date.setDate(date.getDate() - 7)
    setFrom(formatDefaultDate(date))
  }

  const handleClose = () => {
    setModalOpen(false)
  }

  const handleOpenViewUserExportModal = (userExport) => {
    setViewUserExportModalOpen(true)
    setUserExport(userExport)
    setTimeDiff(toDateTimeDifference(new Date(userExport.meta.ended_at),new Date(userExport.meta.started_at)))
  }

  const handleCloseViewUserExportModal = () => {
    setViewUserExportModalOpen(false)
    setUserExport(null)
    setTimeDiff(null)
  }

  const handleOpenEditModal = (batch) => {
    setStartBatchModalOpen(true)
    setBatch(batch)
  }

  const handleCloseEditModal = () => {
    setStartBatchModalOpen(false)
  }

  const handleOpenViewModal = (batch) => {
    setViewModalOpen(true)
    setBatch(batch)
    const date = new Date()
    date.setMinutes(date.getMinutes() + 2)
    setRun_at(formatDefaultDate(date,true))
  }

  const handleCloseViewModal = () => {
    setViewModalOpen(false)
    setBatch(null)
  }

  const refreshBtn = () => {
    setBatches(null)
    fetchBatches()
  }

  const refreshUserExportBtn = () => {
    setUserExports(null)
    fetchUserExports()
  }

  const formatDate = (value) => {
    return `${value.replace(' ','T')}:00.000Z`
  }

  const formatDefaultDate = (value,time=false) => {
    return `${value.getFullYear()}-${value.getMonth() > 8 
      ?value.getMonth()+1:'0'+(Number(value.getMonth())+1)}-${value.getDate() > 8 ?
      value.getDate():'0'+Number(value.getDate())} ${time?(value.getHours() > 9 ?
      value.getHours():'0'+Number(value.getHours()))+':'+(value.getMinutes() > 9 ?
        value.getMinutes():'0'+Number(value.getMinutes())):'00:00'}`
  }

  const panes = [
    {
      menuItem: 'Transactions',
      render: () => <Tab.Pane loading={!batches || !vendors}>
        {!networkError ?
          <>
            <Grid centered>
              <Grid.Row>
                <Grid.Column width={9} floated="left">
                  <div style={{display: 'flex', width: '100%'}}>
                    <Input label="Filter" placeholder="Search" value={searchTable}
                           onChange={(e) => setSearchTable(e.target.value)}
                           style={{width: '100%', marginRight: 3}}
                    />
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <TransactionExportList data={batches} handleView={handleOpenViewModal} searchTable={searchTable} vendors={vendors}/>
          </> :
          <Container text textAlign="center" style={{height: "37vh"}}>
            Network error
            <Button secondary onClick={refreshBtn} icon="redo" content="Refresh" size="tiny" style={{marginLeft: 10}}/>
          </Container>
        }
      </Tab.Pane>
    },
    {
      menuItem: 'Users',
      render: () => <Tab.Pane loading={!userExports}>
        {!networkErrorUserExport ?
          <>
            <Grid centered>
              <Grid.Row>
                <Grid.Column width={9} floated="left">
                  <div style={{display: 'flex', width: '100%'}}>
                    <Input label="Filter" placeholder="Search" value={searchTable}
                           onChange={(e) => setSearchTable(e.target.value)}
                           style={{width: '100%', marginRight: 3}}
                    />
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <UserExportList data={userExports} handleView={handleOpenViewUserExportModal} searchTable={searchTable}/>
          </> :
          <Container text textAlign="center" style={{height: "37vh"}}>
            Network error
            <Button secondary onClick={refreshUserExportBtn} icon="redo" content="Refresh" size="tiny" style={{marginLeft: 10}}/>
          </Container>
        }
      </Tab.Pane>
    },
  ]

  const batchModalPanes = (batch) => [
    {
      menuItem: 'Detail', render: () => <Tab.Pane>
        <Grid centered>
          {!batch.closed && <Grid.Row textAlign="right">
            <Grid.Column width={4} floated="right">
              { userCan(user.access, userLevels.START_EXPORT_PROCESS) && <Button icon="play" content='Start Process' size="mini" color="blue"
                      onClick={() => handleOpenEditModal(batch)}/>}
            </Grid.Column>
          </Grid.Row>}
          <Grid.Row>
            <Grid.Column width={14}>
              <Header as='h4'>
                <Header.Content>
                  <Header.Subheader>ID</Header.Subheader>
                  {batch.id}
                </Header.Content>
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={14}>
              <Header as='h4'>
                <Header.Content>
                  <Header.Subheader>Closed</Header.Subheader>
                  <Label
                    color={yesOrNoColor(batch.closed)}
                    size="medium" content={batch.closed ? 'True' : 'False'}/>
                </Header.Content>
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={14}>
              <Header as='h4'>
                <Header.Content>
                  <Header.Subheader>Vendor</Header.Subheader>
                  {batch.vendor_id ? vendors.find(item => item.id === batch.vendor_id).name : '-'}
                </Header.Content>
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={14}>
              <Header as='h4'>
                <Header.Content>
                  <Header.Subheader>Date</Header.Subheader>
                  {toReadableDateTime(batch.created_at)}
                </Header.Content>
              </Header>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Tab.Pane>
    },
    {
      menuItem: 'Process', render: () => <Tab.Pane>
        <Grid centered>
          {!!(batch.batch_processing && batch.batch_processing.length) ?
            <Card.Group stackable style={{margin: '10px 0'}}>
              {batch.batch_processing.map(item => {
                  const td = toDateTimeDifference(new Date(item.end), new Date(item.start))
                  return <Card key={item.id} fluid>
                    <Card.Content>
                      <Card.Description>
                        <Grid centered>
                          <Grid.Row>
                            <Grid.Column width={14}>
                              <Header as='h4'>
                                <Header.Content>
                                  <Header.Subheader>ID</Header.Subheader>
                                  {item.id}
                                </Header.Content>
                              </Header>
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row>
                            <Grid.Column width={14}>
                              <Header as='h4'>
                                <Header.Content>
                                  <Header.Subheader>Batch ID</Header.Subheader>
                                  {item.batch_id}
                                </Header.Content>
                              </Header>
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row>
                            <Grid.Column width={14}>
                              <Header as='h4'>
                                <Header.Content>
                                  <Header.Subheader>State</Header.Subheader>
                                  <Label
                                    color={statusColor(item.state)}
                                    size="medium" content={statusName(item.state)}/>
                                </Header.Content>
                              </Header>
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row>
                            <Grid.Column width={7}>
                              <Header as='h4'>
                                <Header.Content>
                                  <Header.Subheader>Total time taken to process</Header.Subheader>
                                  {`${td.hours ? td.hours + ' hours' : ''} ${td.minutes ? td.minutes + ' minutes' : ''} ${td.seconds} seconds`}
                                </Header.Content>
                              </Header>
                            </Grid.Column>
                            <Grid.Column width={7}>
                              <Header as='h4'>
                                <Header.Content>
                                  <Header.Subheader>Transactions processed</Header.Subheader>
                                  {item.meta?.transactions_processed ?? '-'}
                                </Header.Content>
                              </Header>
                            </Grid.Column>
                          </Grid.Row>
                          {!!item.trigger && <Grid.Row>
                            <Grid.Column width={14}>
                              <Header as='h4'>
                                <Header.Content>
                                  <Header.Subheader>Trigger</Header.Subheader>
                                  {item.trigger}
                                </Header.Content>
                              </Header>
                            </Grid.Column>
                          </Grid.Row>}
                          <Grid.Row>
                            <Grid.Column width={14}>
                              <Header as='h4'>
                                <Header.Content>
                                  <Header.Subheader>Date</Header.Subheader>
                                  {toReadableDateTime(item.created_at)}
                                </Header.Content>
                              </Header>
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row>
                            <Grid.Column width={14}>
                              <a href={item.meta?.download_url ?? '#'} target='_blank' download rel="noopener noreferrer"
                                 style={{textDecoration: 'none'}}>
                                <Button icon="download" size="mini" color="green" content='Download CSV'/>
                              </a>
                            </Grid.Column>
                          </Grid.Row>

                        </Grid>
                      </Card.Description>
                    </Card.Content>
                  </Card>
                }
              )}
            </Card.Group>
            :
            <Grid.Row>
              <Grid.Column width={14}>
                <Header as='h4' content="No Process found"/>
              </Grid.Column>
            </Grid.Row>
          }
        </Grid>
      </Tab.Pane>
    },
  ]

  const userExportModalPanes = (userExport) => [
    {
      menuItem: 'Detail', render: () => <Tab.Pane>
        <Grid centered>
          <Grid.Row>
            <Grid.Column width={14}>
              <Header as='h4'>
                <Header.Content>
                  <Header.Subheader>ID</Header.Subheader>
                  {userExport.id}
                </Header.Content>
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={14}>
              <Header as='h4'>
                <Header.Content>
                  <Header.Subheader>State</Header.Subheader>
                  <Label
                    color={statusColor(userExport.state)}
                    size="medium" content={statusName(userExport.state)}/>
                </Header.Content>
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={7}>
              <Header as='h4'>
                <Header.Content>
                  <Header.Subheader>Export From</Header.Subheader>
                  {toReadableDateTime(userExport.meta?.user_export.from)}
                </Header.Content>
              </Header>
            </Grid.Column>
            <Grid.Column width={7}>
              <Header as='h4'>
                <Header.Content>
                  <Header.Subheader>Export To</Header.Subheader>
                  {toReadableDateTime(userExport.meta?.user_export.to)}
                </Header.Content>
              </Header>
            </Grid.Column>
          </Grid.Row>
          {userExport.state === 'processed' && <>
            <Grid.Row>
              <Grid.Column width={7}>
                <Header as='h4'>
                  <Header.Content>
                    <Header.Subheader>Total time taken to process</Header.Subheader>
                    {`${timeDiff.hours ? timeDiff.hours+' hours':''} ${timeDiff.minutes ? timeDiff.minutes+' minutes':''} ${timeDiff.seconds} seconds`}
                  </Header.Content>
                </Header>
              </Grid.Column>
              <Grid.Column width={7}>
                <Header as='h4'>
                  <Header.Content>
                    <Header.Subheader>Total records exported</Header.Subheader>
                    {userExport.meta.total}
                  </Header.Content>
                </Header>
              </Grid.Column>
            </Grid.Row>
          </>}
          {!!userExport.meta.last_error && <Grid.Row>
            <Grid.Column width={14}>
              <Header as='h4'>
                <Header.Content>
                  <Header.Subheader>Error Message</Header.Subheader>
                  {userExport.meta.last_error}
                </Header.Content>
              </Header>
            </Grid.Column>
          </Grid.Row>}
          <Grid.Row>
            <Grid.Column width={14}>
              <Header as='h4'>
                <Header.Content>
                  <Header.Subheader>Date</Header.Subheader>
                  {toReadableDateTime(userExport.created_at)}
                </Header.Content>
              </Header>
            </Grid.Column>
          </Grid.Row>
          {(userExport.state === 'processed' && userCan(user.access, userLevels.DOWNLOAD_EXPORT)) &&
          <Grid.Row>
            <Grid.Column width={14}>
              <a href={userExport.meta?.download_url ?? '#'} target='_blank' download rel="noopener noreferrer"
                 style={{textDecoration: 'none'}}>
                <Button icon="download" size="mini" color="green" content='Download CSV'/>
              </a>
            </Grid.Column>
          </Grid.Row>}
        </Grid>
      </Tab.Pane>
    },
  ]

  return (
    <>
      <TopBar name={props.title}
              actions={ userCan(user.access, userLevels.CREATE_EXPORT) &&
                [
                  {render: () => <Button basic color="blue" content="Create User Export" onClick={openModal}/>},
                ]
              }
      />
      <Tab panes={panes} activeIndex={activeTab} onTabChange={e => setActiveTab(e.target.value)}/>
      <SideModal
        open={modalOpen}
        onClose={handleClose}
        title="Create User Export"
      >
        <Form onSubmit={handleCreateUserExport}>
          <Grid centered>
            <Grid.Row>
              <Grid.Column width={14}>
                <Form.Field>
                  <label>From</label>
                  <DateTimeInput
                    name="dateTime"
                    placeholder="YYYY-MM-DD HH:mm"
                    value={from}
                    iconPosition="left"
                    dateTimeFormat="YYYY-MM-DD HH:mm"
                    onChange={(event, {value}) => setFrom(value)}
                    required
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={14}>
                <Form.Field>
                  <label>To</label>
                  <DateTimeInput
                    name="dateTime"
                    placeholder="YYYY-MM-DD HH:mm"
                    value={to}
                    iconPosition="left"
                    dateTimeFormat="YYYY-MM-DD HH:mm"
                    onChange={(event, {value}) => setTo(value)}
                    required
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Button type='submit' loading={submitLoader} disabled={submitLoader}>Save</Button>
          </Grid>
        </Form>
      </SideModal>
      {!!(batches && batches.length && batch !== null) &&
      <>
        <SideModal
          open={viewModalOpen}
          onClose={handleCloseViewModal}
          title="Batch Detail"
          width={startBatchModalOpen ? 80 : 60}
        >
          <Tab panes={batchModalPanes(batch)}/>
        </SideModal>
        <SideModal
          open={startBatchModalOpen}
          onClose={handleCloseEditModal}
          title="Start Batch"
        >
          <Form onSubmit={handleStartBatch}>
            <Grid centered>
              <Grid.Row>
                <Grid.Column width={14}>
                  <Form.Field>
                    <label>Datetime</label>
                    <DateTimeInput
                      name="dateTime"
                      placeholder="YYYY-MM-DD HH:mm"
                      value={run_at}
                      iconPosition="left"
                      dateTimeFormat="YYYY-MM-DD HH:mm"
                      onChange={(event, {value}) => setRun_at(value)}
                      required
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
              <Button type='submit' loading={submitLoader} disabled={submitLoader}>Run</Button>
            </Grid>
          </Form>
        </SideModal>
      </>
      }
      {!!(userExports && userExports.length && userExport !== null) &&
      <>
        <SideModal
          open={viewUserExportModalOpen}
          onClose={handleCloseViewUserExportModal}
          title="User Export Detail"
          width={startBatchModalOpen ? 80 : 60}
        >
          <Tab panes={userExportModalPanes(userExport)}/>
        </SideModal>
      </>
      }
    </>
  );
}

export default Exports;


