import React, {useContext, useEffect, useState} from 'react';
import {
  Button,
  Container, Dropdown,
  Form,
  Grid,
  Header,
  Icon,
  Input,
  Label,
  Message,
  Modal,
  Tab
} from 'semantic-ui-react';
import TopBar from '../../util/topbar';
import CategoryList from "./CategoryList";
import SideModal from "../../util/SideModal";
import Api from "../../util/api";
import {
  handleSetPopupMessage,
  NotifyCode,
  userCan,
  userLevels,
  yesOrNoColor,
  yesOrNoName
} from "../../util/HelperFunctions";
import {UserContext} from "../../context/UserProvider";

const Categories = (props) => {
  const {user} = useContext(UserContext)
  const [modalOpen, setModalOpen] = useState(false)
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [viewModalOpen, setViewModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [index, setIndex] = useState(0)
  const [name, setName] = useState('')
  const [desc, setDesc] = useState('')
  const [enabled, setEnabled] = useState(false)
  const [id, setId] = useState('')
  const [categories, setCategories] = useState(null)
  const [submitLoader, setSubmitLoader] = useState(false)
  const [networkError, setNetworkError] = useState(false)
  const [searchTable,setSearchTable] = useState("")

  const [popUp, setPopUp] = useState('')
  const [popUpMessage, setPopUpMessage] = useState('')

  const resetPopUp = () =>{
    setPopUp('')
    setPopUpMessage('')
  }

  useEffect(() => {
    if (!categories) {
      fetchCategories()
    }
  },[])

  const fetchCategories = async () => {
    resetPopUp()
    try {
      const {data} = await Api.allCategories()
      if (data.result.categories) {
        setCategories(data.result.categories)
      } else {
        setCategories([])
      }
    } catch (err) {
      /*setNetworkError(true)
      setCategories([])
      console.log(err)*/
      handleSetPopupMessage(setPopUp,setPopUpMessage,resetPopUp,NotifyCode.FETCH_CATEGORIES.ERROR,err.data.error.message,-1)
    }
  }

  const openModal = () => {
    setModalOpen(true)
  }

  const handleClose = () => {
    setModalOpen(false)
  }

  const handleOpenEditModal = (index) => {
    setEditModalOpen(true)
    setIndex(index)
    setId(categories[index].id)
    setName(categories[index].name)
    setDesc(categories[index].desc)
    setEnabled(categories[index].enabled)
  }

  const handleCloseEditModal = () => {
    setEditModalOpen(false)
    resetFields()
    setIndex(-1)
  }

  const handleOpenViewModal = (index) => {
    setViewModalOpen(true)
    setIndex(index)
  }

  const handleCloseViewModal = () => {
    setViewModalOpen(false)
    setIndex(-1)

  }

  const handleOpenDeleteModal = (index) => {
    setDeleteModalOpen(true)
    setIndex(index)
  }

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false)
    setIndex(-1)
  }

  const resetFields = () => {
    setName('')
    setDesc('')
    setId('')
  }

  const refreshBtn = () => {
    setCategories(null)
  }

  const handleCreateSubmit = async (e) => {
    e.preventDefault()
    setSubmitLoader(true)
    resetPopUp()
    try {
      const params = {name, desc}
      const {data} = await Api.createCategory(params)
      setCategories([...categories, data.result.category])
      handleClose()
      resetFields()
    } catch (err) {
      handleSetPopupMessage(setPopUp,setPopUpMessage,resetPopUp,NotifyCode.CREATE_CATEGORY.ERROR,err.data?.error.message,-1)
    }
    setSubmitLoader(false)
  }

  const handleEditSubmit = async (e) => {
    e.preventDefault()
    setSubmitLoader(true)
    resetPopUp()
    try {
      const params = {id, name, desc, enabled}
      const {data} = await Api.updateCategory(params)
      setCategories([...categories.map(item => item.id === data.result.category.id ? {...data.result.category} : item)])
      handleCloseEditModal()
    } catch (err) {
      handleSetPopupMessage(setPopUp,setPopUpMessage,resetPopUp,NotifyCode.UPDATE_CATEGORY.ERROR,err.data?.error.message,-1)
    }
    setSubmitLoader(false)
  }

  const handleDeleteSubmit = async (e) => {
    e.preventDefault()
    setSubmitLoader(true)
    resetPopUp()
    try {
      const params = {id: categories[index].id}
      const {data} = await Api.deleteCategory(params)
      if (data.result.status) {
        setCategories([...categories.filter(item => item.id !== categories[index].id)])
      }
      handleCloseDeleteModal()
    } catch (err) {
      handleSetPopupMessage(setPopUp,setPopUpMessage,resetPopUp,NotifyCode.DELETE_CATEGORY.ERROR,err.data?.error.message,-1)
    }
    setSubmitLoader(false)
  }

  const panes = [
    {
      menuItem: 'Category List',
      render: () => <Tab.Pane loading={categories === null}>
        {(popUp === NotifyCode.FETCH_CATEGORIES.ERROR) ? <Grid centered>
          <Grid.Row>
            <Grid.Column width={14}>
              <Message negative>
                <Message.Header>{popUpMessage}</Message.Header>
              </Message>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={14}>
              <Button secondary onClick={refreshBtn} icon="redo" content="Refresh" size="tiny" style={{marginLeft: 10}}/>
            </Grid.Column>
          </Grid.Row>
        </Grid>: <>
          <Grid centered>
            <Grid.Row>
              <Grid.Column width={9} floated="left">
                <div style={{display: 'flex', width: '100%'}}>
                  <Input label="Filter" placeholder="Search" value={searchTable}
                         onChange={(e) => setSearchTable(e.target.value)}
                         style={{width: '100%', marginRight: 3}}
                  />
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <CategoryList data={categories} handleEdit={handleOpenEditModal} handleView={handleOpenViewModal}
                        handleDelete={handleOpenDeleteModal} searchTable={searchTable}/>
        </>}
        {/*{!networkError ?
          <>
            <Grid centered>
              <Grid.Row>
                <Grid.Column width={9} floated="left">
                  <div style={{display: 'flex', width: '100%'}}>
                    <Input label="Filter" placeholder="Search" value={searchTable}
                           onChange={(e) => setSearchTable(e.target.value)}
                           style={{width: '100%', marginRight: 3}}
                    />
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <CategoryList data={categories} handleEdit={handleOpenEditModal} handleView={handleOpenViewModal}
                          handleDelete={handleOpenDeleteModal} searchTable={searchTable}/>
          </> :
          <Container text textAlign="center" style={{height: "37vh"}}>
            Network error
            <Button secondary onClick={refreshBtn} icon="redo" content="Refresh" size="tiny" style={{marginLeft: 10}}/>
          </Container>
        }*/}
      </Tab.Pane>
    },
  ]

  return (
    <>
      <TopBar
        name={props.title}
        actions={
          // userCan(user.access, userLevels.CREATE_CATEGORY) &&
          [
            {render: () => <Button basic color="blue" content="Create Category" onClick={openModal}/>},
          ]
        }
      />
      <Tab panes={panes}/>
      <SideModal
        open={modalOpen}
        onClose={handleClose}
        title="Create Category"
      >
        <Form onSubmit={handleCreateSubmit}>
          <Grid centered>
            {(popUp === NotifyCode.CREATE_CATEGORY.ERROR) &&
              <Grid.Row>
                <Grid.Column width={14}>
                  <Message negative>
                    <Message.Header>{popUpMessage}</Message.Header>
                  </Message>
                </Grid.Column>
              </Grid.Row>}
            <Grid.Row>
              <Grid.Column width={12}>
                <Form.Field>
                  <label>Name</label>
                  <input placeholder='eg: Popular Goals' value={name} required
                         onChange={event => setName(event.target.value)}/>
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={12}>
                <Form.Field>
                  <label>Description (Optional)</label>
                  <textarea placeholder='eg: Most popular goals'
                            onChange={event => setDesc(event.target.value)} value={desc}/>
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Button type='submit' loading={submitLoader} disabled={submitLoader}>Submit</Button>
          </Grid>
        </Form>
      </SideModal>
      {!!(categories && categories.length && index !== -1) &&
        <>
          <SideModal
            open={editModalOpen}
            onClose={handleCloseEditModal}
            title="Edit Category"
          >
            <Form onSubmit={handleEditSubmit}>
              <Grid centered>
                {(popUp === NotifyCode.UPDATE_CATEGORY.ERROR) &&
                    <Grid.Row>
                      <Grid.Column width={14}>
                        <Message negative>
                          <Message.Header>{popUpMessage}</Message.Header>
                        </Message>
                      </Grid.Column>
                    </Grid.Row>}
                <Grid.Row>
                  <Grid.Column width={12}>
                    <Form.Field>
                      <label>Name</label>
                      <input placeholder='eg: Popular Goals' value={name} required
                             onChange={event => setName(event.target.value)}/>
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={12}>
                    <Form.Field>
                      <label>Description (Optional)</label>
                      <textarea placeholder='eg: Most popular goals'
                                onChange={event => setDesc(event.target.value)} value={desc}/>
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={12} style={{marginTop: 7}}>
                    <Form.Field>
                      <label>Enabled</label>
                      <Dropdown
                          placeholder='Enabled'
                          fluid
                          selection
                          value={enabled}
                          options={[
                            {key: 'true', text: 'Yes', value: true},
                            {key: 'false', text: 'No', value: false},
                          ]}
                          onChange={(event, {value}) => setEnabled(value)}
                      />
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                <Button type='submit' loading={submitLoader} disabled={submitLoader}>Submit</Button>
              </Grid>
            </Form>
          </SideModal>
          <Modal
            size="mini"
            open={deleteModalOpen}
            onClose={handleCloseDeleteModal}
          >
            <Modal.Header>{`Deleting ${categories[index].name}`}</Modal.Header>
            <Modal.Content>
              {(popUp === NotifyCode.DELETE_CATEGORY.ERROR) &&
                  <Message negative>
                    <Message.Header>{popUpMessage}</Message.Header>
                  </Message>
              }
              <p>Are you sure?</p>
              {categories[index].products && categories[index].products.length ?
                <Message warning icon>
                  <Icon name='help'/>
                  <Message.Content>
                    <Message.Header>Note</Message.Header>
                    This category has products
                  </Message.Content>
                </Message> : ''}
            </Modal.Content>
            <Modal.Actions>
              <Button negative disabled={submitLoader} onClick={handleCloseDeleteModal}>
                No
              </Button>
              <Button positive loading={submitLoader} disabled={submitLoader} onClick={handleDeleteSubmit}>
                Yes
              </Button>
            </Modal.Actions>
          </Modal>
          <SideModal
            open={viewModalOpen}
            onClose={handleCloseViewModal}
            title="View Category"
          >
            <Grid centered>
              <Grid.Row>
                <Grid.Column width={12}>
                  <Header as='h4'>
                    <Header.Content>
                      <Header.Subheader>Name</Header.Subheader>
                      {categories[index].name}
                    </Header.Content>
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={12}>
                  <Header as='h4'>
                    <Header.Content>
                      <Header.Subheader>Description</Header.Subheader>
                      {categories[index].desc ?? '-'}
                    </Header.Content>
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={12}>
                  <Header as='h4'>
                    <Header.Content>
                      <Header.Subheader>Enabled</Header.Subheader>
                      <Label
                        color={yesOrNoColor(categories[index].enabled)}
                        basic
                        size="medium"
                        content={yesOrNoName(categories[index].enabled)}/>
                    </Header.Content>
                  </Header>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </SideModal>
        </>
      }
    </>
  );
}

export default Categories;

