import React from 'react';
import {Button, Modal} from 'semantic-ui-react';

export default props => (
  <Modal
    className={"side-modal"}
    closeOnEscape
    closeOnDimmerClick={false}
    style={{
      // margin: '0 0 0 20%',
      width: props.width?`${props.width}%`:'60%',
      minHeight: '100%',
      maxHeight: '100%',
      borderRadius: 0,
      marginTop: 0,
      marginBottom: 0,
      float: "right",
      marginLeft: props.width?`${97.5-props.width}%`:"37.5%",
    }}
    size={"fullscreen"}
    {...props}
  >
    <Modal.Header>
      {props.title || ''}
      <Button
        icon="close"
        floated="right"
        color="red"
        size="tiny"
        onClick={props.onClose ? props.onClose : () => {
        }}
      />
    </Modal.Header>
    <Modal.Content style={{height: '90%', overflow: 'auto', position:'absolute'}}>
      <Modal.Description style={{width: '100%'}}>
        {props.children}
      </Modal.Description>
    </Modal.Content>
  </Modal>
);
