import React from "react";
import {Accordion, Grid, Header, Icon, Label, Popup} from "semantic-ui-react";
import {
  currencyCode,
  getTransactionDirection, getTransactionType, getUserFullName,
  statusColor,
  statusName,
  toReadableDateTime,
  toReadableValue, transactionTypeColor
} from "../util/HelperFunctions";

function TransactionDetails ({transaction,showViewUser,owner}){
  const [showMeta, setShowMeta] = React.useState(false)
  const viewUser = (id) => {
    const win = window.open(`/users/${id}`)
    win.focus()
  }
  return <>
    <Grid.Row >
      <Grid.Column width={7}>
        <Header as='h4'>
          <Header.Content>
            <Header.Subheader>Transaction ID</Header.Subheader>
            {transaction.id}
          </Header.Content>
        </Header>
      </Grid.Column>
      <Grid.Column width={7}>
        <Header as='h4'>
          <Header.Content>
            <Header.Subheader>Reference ID</Header.Subheader>
            {transaction.ref}
          </Header.Content>
        </Header>
      </Grid.Column>
    </Grid.Row>
    {!!owner && <Grid.Row >
      <Grid.Column width={7}>
        <Header as='h4'>
          <Header.Content>
            <Header.Subheader>Member ID</Header.Subheader>
            {owner.id}
            {showViewUser &&
                <Popup content='Open profile' trigger={
                  <Icon name="external alternate" onClick={()=>viewUser(owner.id)}/>}
                />}
          </Header.Content>
        </Header>
      </Grid.Column>
      <Grid.Column width={7}>
        <Header as='h4'>
          <Header.Content>
            <Header.Subheader>Member name</Header.Subheader>
            {getUserFullName(owner)} ({owner.role})
          </Header.Content>
        </Header>
      </Grid.Column>
    </Grid.Row>}
    <Grid.Row >
      <Grid.Column width={7}>
        <Header as='h4'>
          <Header.Content>
            <Header.Subheader>Creator ID</Header.Subheader>
            {transaction.creator_id} {showViewUser &&
            <Popup content='Open profile' trigger={
              <Icon name="external alternate" onClick={()=>viewUser(transaction.creator_id)}/>}
            />}
          </Header.Content>
        </Header>
      </Grid.Column>
      <Grid.Column width={7}>
        <Header as='h4'>
          <Header.Content>
            <Header.Subheader>Account ID</Header.Subheader>
            {transaction.account_id}
          </Header.Content>
        </Header>
      </Grid.Column>
    </Grid.Row>
    <Grid.Row >
      <Grid.Column width={7}>
        <Header as='h4'>
          <Header.Content>
            <Header.Subheader>Amount Reference</Header.Subheader>
            {transaction.account_ref??'-'}
          </Header.Content>
        </Header>
      </Grid.Column>
      <Grid.Column width={7}>
        <Header as='h4'>
          <Header.Content>
            <Header.Subheader>Amount Type</Header.Subheader>
            {transaction.account_type??'-'}
          </Header.Content>
        </Header>
      </Grid.Column>
    </Grid.Row>
    <Grid.Row >
      <Grid.Column width={7}>
        <Header as='h4'>
          <Header.Content>
            <Header.Subheader>Amount</Header.Subheader>
            {toReadableValue(transaction.amount,transaction.currency_code)} {currencyCode(transaction.currency_code)}
          </Header.Content>
        </Header>
      </Grid.Column>
      {transaction.direction === 'outbound' ?
      <Grid.Column width={7}>
        <Header as='h4'>
          <Header.Content>
            <Header.Subheader>Charge</Header.Subheader>
            {toReadableValue(transaction.charge,transaction.currency_code)} {currencyCode(transaction.currency_code)}
          </Header.Content>
        </Header>
      </Grid.Column>:
      <Grid.Column width={7}>
        <Header as='h4'>
          <Header.Content>
            <Header.Subheader>Transfer Type</Header.Subheader>
            <Label
                color={transactionTypeColor(transaction.transfer_type)}
                size="large" content={getTransactionType(transaction.transfer_type)} />
          </Header.Content>
        </Header>
      </Grid.Column>}
    </Grid.Row>
    {transaction.direction === 'outbound' && <Grid.Row>
      <Grid.Column width={7}>
        <Header as='h4'>
          <Header.Content>
            <Header.Subheader>Transfer Type</Header.Subheader>
            <Label
                color={transactionTypeColor(transaction.transfer_type)}
                size="large" content={getTransactionType(transaction.transfer_type)} />
          </Header.Content>
        </Header>
      </Grid.Column>
      <Grid.Column width={7}>
        <Header as='h4'>
          <Header.Content>
            <Header.Subheader>Direction</Header.Subheader>
            {getTransactionDirection(transaction.direction)}
          </Header.Content>
        </Header>
      </Grid.Column>
    </Grid.Row>}
    <Grid.Row >
      <Grid.Column width={7}>
        <Header as='h4'>
          <Header.Content>
            <Header.Subheader>Payment Method</Header.Subheader>
            {transaction.payment_method??'-'}
          </Header.Content>
        </Header>
      </Grid.Column>
      <Grid.Column width={7}>
        <Header as='h4'>
          <Header.Content>
            <Header.Subheader>Payment Reference</Header.Subheader>
            {transaction.payment_ref??'-'}
          </Header.Content>
        </Header>
      </Grid.Column>
    </Grid.Row>
    <Grid.Row >
      <Grid.Column width={7}>
        <Header as='h4'>
          <Header.Content>
            <Header.Subheader>Status</Header.Subheader>
            <Label
                color={statusColor(transaction.status)}
                size="large"
                content={statusName(transaction.status)}/>
          </Header.Content>
        </Header>
      </Grid.Column>
      {transaction.direction === 'outbound' ?
          <Grid.Column width={7}>
            <Header as='h4'>
              <Header.Content>
                <Header.Subheader>State</Header.Subheader>
                {transaction.state}
              </Header.Content>
            </Header>
          </Grid.Column>:
          <Grid.Column width={7}>
            <Header as='h4'>
              <Header.Content>
                <Header.Subheader>Direction</Header.Subheader>
                {getTransactionDirection(transaction.direction)}
              </Header.Content>
            </Header>
          </Grid.Column>}

    </Grid.Row>
    <Grid.Row >
      <Grid.Column width={7}>
        <Header as='h4'>
          <Header.Content>
            <Header.Subheader>From Reference ID</Header.Subheader>
            {transaction.from_ref_id}
          </Header.Content>
        </Header>
      </Grid.Column>
      <Grid.Column width={7}>
        <Header as='h4'>
          <Header.Content>
            <Header.Subheader>To Reference ID</Header.Subheader>
            {transaction.to_ref_id}
          </Header.Content>
        </Header>
      </Grid.Column>
    </Grid.Row>
    <Grid.Row >
      <Grid.Column width={7}>
        <Header as='h4'>
          <Header.Content>
            <Header.Subheader>From Reference Name</Header.Subheader>
            {transaction.from_ref_name}
          </Header.Content>
        </Header>
      </Grid.Column>
      <Grid.Column width={7}>
        <Header as='h4'>
          <Header.Content>
            <Header.Subheader>To Reference Name</Header.Subheader>
            {transaction.to_ref_name}
          </Header.Content>
        </Header>
      </Grid.Column>
    </Grid.Row>
    <Grid.Row >
      <Grid.Column width={7}>
        <Header as='h4'>
          <Header.Content>
            <Header.Subheader>From Reference Type</Header.Subheader>
            {transaction.from_ref_type}
          </Header.Content>
        </Header>
      </Grid.Column>
      <Grid.Column width={7}>
        <Header as='h4'>
          <Header.Content>
            <Header.Subheader>To Reference Type</Header.Subheader>
            {transaction.to_ref_type}
          </Header.Content>
        </Header>
      </Grid.Column>
    </Grid.Row>
    <Grid.Row >
      <Grid.Column width={transaction.direction === 'outbound' ? 14 : 7}>
        <Header as='h4'>
          <Header.Content>
            <Header.Subheader>Date Created At</Header.Subheader>
            {toReadableDateTime(transaction.created_at)}
          </Header.Content>
        </Header>
      </Grid.Column>
      {transaction.direction !== 'outbound' && <Grid.Column width={7}>
        <Header as='h4'>
          <Header.Content>
            <Header.Subheader>State</Header.Subheader>
            {transaction.state}
          </Header.Content>
        </Header>
      </Grid.Column>}
    </Grid.Row>
    <Grid.Row >
      <Grid.Column width={14}>
        <Header as='h4'>
          <Header.Content>
            <Header.Subheader>Description</Header.Subheader>
            {transaction.desc??'-'}
          </Header.Content>
        </Header>
      </Grid.Column>
    </Grid.Row>
    {transaction.status !== 'success' &&
    <Grid.Row >
      <Grid.Column width={14}>
        <Header as='h4'>
          <Header.Content>
            <Header.Subheader>Error Description</Header.Subheader>
            {transaction.error_desc??'-'}
          </Header.Content>
        </Header>
      </Grid.Column>
    </Grid.Row>}

    <Grid.Row >
      <Grid.Column width={14}>
        <Accordion fluid styled>
          <Accordion.Title active={showMeta} onClick={()=> setShowMeta(!showMeta)}>
            <Icon name='dropdown' />
            Meta
          </Accordion.Title>
          <Accordion.Content active={showMeta}>
            <pre>{JSON.stringify(transaction.meta,null,2)}</pre>
          </Accordion.Content>
        </Accordion>
      </Grid.Column>
    </Grid.Row>
  </>
}

export default TransactionDetails

