import React, {useContext, useEffect, useState} from 'react';
import {Button, Container, Dropdown, Grid, Header, Input, Menu, Message, Modal, Tab} from 'semantic-ui-react';
import TopBar from '../../util/topbar';
import JobList from "./JobList";
import SideModal from "../../util/SideModal";
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import Api from "../../util/api";
import {handleSetPopupMessage, NotifyCode, toReadableDateTime} from "../../util/HelperFunctions";
import {remoteConfig} from "../../util/firebaseConfig";
import {UserContext} from "../../context/UserProvider";
import ProductList from "../products/ProductList";

const count = 50
const Jobs = (props) => {
  const [viewModalOpen, setViewModalOpen] = useState(false)
  const [showLoadMore, setShowLoadMore] = useState(true)
  const [loadMore, setLoadMore] = useState(false)
  const [jobs, setJobs] = useState(null)
  // const [index, setIndex] = useState(0)
  const [offset, setOffset] = useState(0)
  // const [count,setCount] = useState(50)
  const [networkError,setNetworkError] = useState(false)
  const [currentOffset,setCurrentOffset] = useState(0)
  const [searchTable,setSearchTable] = useState("")
  const [excludeKeys,setExcludeKeys] = useState(['push_notification_job'])
  const [job, setJob] = useState(null)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [submitLoader, setSubmitLoader] = useState(false)

  const [popUp, setPopUp] = useState('')
  const [popUpMessage, setPopUpMessage] = useState('')

  const resetPopUp = () =>{
    setPopUp('')
    setPopUpMessage('')
  }

  useEffect(() => {
    if (!jobs) {
      fetchJobs()
    }
  },[])

  const fetchJobs = async (offset = 0) => {
    // setNetworkError(false)
    resetPopUp()
    try {
      const {data} = await Api.allJobs({offset, count})
      if (data.result.jobs) {
        if (offset === 0) {
          setJobs(data.result.jobs)
          setShowLoadMore(data.result.jobs.length === count)
        } else {
          setJobs([...jobs, ...data.result.jobs])
          setShowLoadMore(data.result.jobs.length > 0 && data.result.jobs.length === count)
        }
        setOffset(data.result.pagination.offset + count)
      } else {
        setJobs([])
      }
      setLoadMore(false)
    } catch (err) {
      setJobs([])
      /*setNetworkError(true)
      console.log('err', err)*/
      handleSetPopupMessage(setPopUp,setPopUpMessage,resetPopUp,NotifyCode.FETCH_JOBS.ERROR,err.data.error.message,-1)
    }
  }

  const handleDeleteSubmit = async (e) => {
    e.preventDefault()
    setSubmitLoader(true)
    try {
      const params = {id: job.id}
      const {data} = await Api.deleteJob(params)
      if (data.result.status) {
        setJobs([...jobs.filter(item => item.id !== job.id)])
      }
      handleCloseDeleteModal()
    } catch (err) {
      console.log(err)
    }
    setSubmitLoader(false)
  }

  const handleOpenViewModal = (index) => {
    setViewModalOpen(true)
    // setIndex(index)
    setJob(jobsWithExclusion()[index])
  }

  const handleOpenDeleteModal = (index) => {
    setDeleteModalOpen(true)
    setJob(jobsWithExclusion()[index])
  }

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false)
    setJob(null)
  }

  const refreshBtn=()=>{
    setJobs(null)
    fetchJobs()
  }

  const handleCloseViewModal = () => {
    setViewModalOpen(false)
    // setIndex(-1)
    setJob(null)
  }

  const previousBtn = ()=>{
    setCurrentOffset(currentOffset - count)
    window.scrollTo(0,100)
  }

  const nextBtn = async ()=>{
    if(showLoadMore && (currentOffset + count === offset)){
      setOffset(offset)
      setLoadMore(true)
      await fetchJobs(offset)
    }
    setCurrentOffset(currentOffset < offset ? currentOffset + count: currentOffset)
    window.scrollTo(0,100)
  }

  const jobsWithExclusion = () =>{
    let data = []
    if(jobs){
      jobs.forEach(e => {
        if(!excludeKeys.includes(e.job_class)){
          data.push(e)
        }
      })
    }

    return data
  }

  const getExclusionKeysList = () =>{
    let exclusionKeysList = !!remoteConfig.getValue('exclution_keys_list').asString() ?
      JSON.parse(remoteConfig.getValue('exclution_keys_list').asString()):
      [
        'push_notification_job',
        'email_job',
      ]
    return [...exclusionKeysList.map(item=> {return {key:item,text:item.replaceAll('_',' '),value:item}})]
  }

  const panes = [
    {
      menuItem: 'Jobs', render: () => <Tab.Pane loading={jobs === null}>
        {(popUp === NotifyCode.FETCH_JOBS.ERROR) ? <Grid centered>
          <Grid.Row>
            <Grid.Column width={14}>
              <Message negative>
                <Message.Header>{popUpMessage}</Message.Header>
              </Message>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={14}>
              <Button secondary onClick={refreshBtn} icon="redo" content="Refresh" size="tiny" style={{marginLeft: 10}}/>
            </Grid.Column>
          </Grid.Row>
        </Grid>: <>
          <Grid centered>
            <Grid.Row>
              <Grid.Column width={6} >
                <div style={{display: 'flex', width: '100%'}}>
                  <Input label="Filter" placeholder="Search" value={searchTable}
                         onChange={(e)=>setSearchTable(e.target.value)}
                         style={{width: '100%',marginRight:3}}
                  />
                </div>
              </Grid.Column>
              <Grid.Column width={6}>
                <Dropdown
                    text='Exclude class'
                    icon='filter'
                    labeled
                    multiple
                    button
                    className='icon'
                    value={excludeKeys}
                    options={getExclusionKeysList()}
                    onChange={(event, {value}) => setExcludeKeys(value)}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <JobList data={searchTable.length === 0 ? jobsWithExclusion()?.slice(currentOffset, count + currentOffset) : jobsWithExclusion()}
                   handleView={handleOpenViewModal} handleDelete={handleOpenDeleteModal} searchTable={searchTable}/>
          {searchTable.length === 0 && <>
            {currentOffset > 0 && <Button secondary onClick={previousBtn}>Previous</Button>}
            {(showLoadMore || currentOffset + count < offset)  &&
                <Button loading={loadMore} secondary onClick={nextBtn}>Next</Button>}</>}
        </> }
        {/*{!networkError?
          <>
            <Grid centered>
              <Grid.Row>
                <Grid.Column width={6} >
                  <div style={{display: 'flex', width: '100%'}}>
                    <Input label="Filter" placeholder="Search" value={searchTable}
                           onChange={(e)=>setSearchTable(e.target.value)}
                           style={{width: '100%',marginRight:3}}
                    />
                  </div>
                </Grid.Column>
                <Grid.Column width={6}>
                  <Dropdown
                    text='Exclude class'
                    icon='filter'
                    labeled
                    multiple
                    button
                    className='icon'
                    value={excludeKeys}
                    options={getExclusionKeysList()}
                    onChange={(event, {value}) => setExcludeKeys(value)}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <JobList data={searchTable.length === 0 ? jobsWithExclusion()?.slice(currentOffset, count + currentOffset) : jobsWithExclusion()}
                     handleView={handleOpenViewModal} handleDelete={handleOpenDeleteModal} searchTable={searchTable}/>
            {searchTable.length === 0 && <>
              {currentOffset > 0 && <Button secondary onClick={previousBtn}>Previous</Button>}
              {(showLoadMore || currentOffset + count < offset)  &&
              <Button loading={loadMore} secondary onClick={nextBtn}>Next</Button>}</>}
          </>:
          <Container text textAlign="center" style={{height: "37vh"}}>
            Network error
            <Button secondary onClick={refreshBtn} icon="redo" content="Refresh" size="tiny" style={{marginLeft: 10}}/>
          </Container>
        }*/}
      </Tab.Pane>
    },
  ]

  const modalPanes = (job) =>[
    {
      menuItem:<Menu.Item key='details'>
        Details
      </Menu.Item>, render: () => <Tab.Pane>
        <Grid centered>
          <Grid.Row>
            <Grid.Column width={14}>
              <Header as='h4'>
                <Header.Content>
                  <Header.Subheader>ID</Header.Subheader>
                  {job.id}
                </Header.Content>
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={14}>
              <Header as='h4'>
                <Header.Content>
                  <Header.Subheader>Job Class</Header.Subheader>
                  {job.job_class}
                </Header.Content>
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={7}>
              <Header as='h4'>
                <Header.Content>
                  <Header.Subheader>priority</Header.Subheader>
                  {job.priority}
                </Header.Content>
              </Header>
            </Grid.Column>
            <Grid.Column width={7}>
              <Header as='h4'>
                <Header.Content>
                  <Header.Subheader>Error Count</Header.Subheader>
                  {job.error_count}
                </Header.Content>
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={7}>
              <Header as='h4'>
                <Header.Content>
                  <Header.Subheader>Queue</Header.Subheader>
                  {job.queue}
                </Header.Content>
              </Header>
            </Grid.Column>
            <Grid.Column width={7}>
              <Header as='h4'>
                <Header.Content>
                  <Header.Subheader>Run At</Header.Subheader>
                  {toReadableDateTime(job.run_at)}
                </Header.Content>
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={14}>
              <Header as='h4'>
                <Header.Content>
                  <Header.Subheader>Last Error</Header.Subheader>
                  {job.last_error}
                </Header.Content>
              </Header>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Tab.Pane>
    },
    {
      menuItem: (
        <Menu.Item key='args'>
          Arguments
        </Menu.Item>
      ), render: () => <Tab.Pane>
        <Grid centered>
          <Grid.Row>
            <Grid.Column width={14}>
              <Header as='h4'>
                <Header.Content>
                  {/*<Header.Subheader>{key}</Header.Subheader>*/}
                  <pre>{JSON.stringify(job.args,null,2)}</pre>
                </Header.Content>
              </Header>
            </Grid.Column>
          </Grid.Row>
          {/*{
            Object.keys(job.args).map((key) =>
              <Grid.Row key={key}>
                <Grid.Column width={14}>
                  <Header as='h4'>
                    <Header.Content>
                      <Header.Subheader>{key}</Header.Subheader>
                      {job.args[key] instanceof Object || job.args[key] instanceof Array
                        ? <pre>{JSON.stringify(job.args[key],null,2)}</pre> : job.args[key]}
                    </Header.Content>
                  </Header>
                </Grid.Column>
              </Grid.Row>
            )
          }*/}
        </Grid>
      </Tab.Pane>
    },
  ]

  return (
    <>
      <TopBar name={props.title}/>
      <Tab panes={panes}/>
      {!!(jobs && jobs.length && job) &&
      <>
        <SideModal
          open={viewModalOpen}
          onClose={handleCloseViewModal}
          title="Job Details"
          width={80}
        >
          <Tab panes={modalPanes(job)}/>
        </SideModal>
        <Modal
          size="mini"
          open={deleteModalOpen}
          onClose={handleCloseDeleteModal}
        >
          <Modal.Header>{`Deleting ${job.job_class}`}</Modal.Header>
          <Modal.Content>
            {(popUp === NotifyCode.DELETE_JOB.ERROR) &&
                    <Message negative>
                      <Message.Header>{popUpMessage}</Message.Header>
                    </Message>}
            <p>Are you sure?</p>
          </Modal.Content>
          <Modal.Actions>
            <Button negative disabled={submitLoader} onClick={handleCloseDeleteModal}>
              No
            </Button>
            <Button positive loading={submitLoader} disabled={submitLoader} onClick={handleDeleteSubmit}>
              Yes
            </Button>
          </Modal.Actions>
        </Modal>
      </>}
    </>
    );
}

export default Jobs;

