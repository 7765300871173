import React, {useContext, useEffect, useState} from 'react';
import {
  Accordion,
  Button,
  Dropdown,
  Form,
  Grid,
  Header,
  Icon,
  Input,
  Label,
  Message,
  Tab
} from 'semantic-ui-react';
import TopBar from '../../util/topbar';
import VendorList from "./VendorList";
import SideModal from "../../util/SideModal";
import Api from "../../util/api";
import {currencyOptions, currencyOptions2} from "../../util/selectOptions";
import {
  currencyCode,
  toActualValue,
  toBasePercentage,
  toBaseValue,
  toReadableValue,
  toBaseRate,
  // userCan, userLevels,
  NotifyCode,
  formatCutOffDate, handleSetPopupMessage
} from "../../util/HelperFunctions";
import {UserContext} from "../../context/UserProvider";
import {TimeInput} from "semantic-ui-calendar-react";
import moment from 'moment'

const Vendors = (props) => {
  const {user} = useContext(UserContext)
  const [modalOpen, setModalOpen] = useState(false)
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [viewModalOpen, setViewModalOpen] = useState(false)
  const [submitLoader, setSubmitLoader] = useState(false)
  const [index, setIndex] = useState(-1)
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [enabled, setEnabled] = useState(true)
  const [base_rate, setBase_rate] = useState(0.0)
  const [currency_code, setCurrency_code] = useState(currencyOptions[0].value)
  const [min_balance, setMin_balance] = useState(0)
  const [account_name, setAccount_name] = useState('')
  const [account, setAccount] = useState('')
  const [bank, setBank] = useState('')
  const [mobile_money, setMobile_money] = useState('')
  const [vendors, setVendors] = useState(null)
  const [searchTable,setSearchTable] = useState("")
  const [showMeta, setShowMeta] = useState(false)

  const [apply_interest, setApply_interest] = useState(false)
  const [deposit_maturity, setDeposit_maturity] = useState(0)
  const [deposit_cut_off, setDeposit_cut_off] = useState('')
  const [withdrawal_maturity, setWithdrawal_maturity] = useState(0)
  const [withdrawal_charge, setWithdrawal_charge] = useState(0)
  const [maturity, setMaturity] = useState(0)
  const [withdrawal_cut_off, setWithdrawal_cut_off] = useState('')
  const [cut_off, setCut_off] = useState('')
  const [interest_trunc_limit, setInterest_trunc_limit] = useState('')

  const [popUp, setPopUp] = useState('')
  const [popUpMessage, setPopUpMessage] = useState('')

  useEffect(() => {
    if (!vendors) {
      fetchVendors()
    }
  },[])

  const fetchVendors = async () => {
    resetPopUp()
    try {
      const {data} = await Api.allVendors()
      if (data.result.vendors) {
        setVendors(data.result.vendors)
      } else {
        setVendors([])
      }
    } catch (err) {
      /*setNetworkError(true)
      setVendors([])
      console.log('err', err)*/
      handleSetPopupMessage(setPopUp,setPopUpMessage,resetPopUp,NotifyCode.FETCH_VENDORS.ERROR,err.data.error.message,-1)
    }
  }

  const openModal = () => {
    setModalOpen(true)
  }

  const handleClose = () => {
    setModalOpen(false)
  }

  const handleOpenEditModal = (index) => {
    setEditModalOpen(true)
    setIndex(index)
    // setId(vendors[index].id)
    setName(vendors[index].name)
    setDescription(vendors[index].description)
    setEnabled(vendors[index].enabled)
    setBase_rate(toBasePercentage(vendors[index].base_rate))
    setCurrency_code(vendors[index].currency_code)
    setMin_balance(toActualValue(vendors[index].min_balance,vendors[index].currency_code))
    setAccount_name(vendors[index].meta.account_name??'')
    setAccount(vendors[index].meta.account??'')
    setBank(vendors[index].meta.bank??'')
    setMobile_money(vendors[index].meta.mobile_money??'')

    setApply_interest(vendors[index].meta.apply_interest ?? '')
    setDeposit_maturity(vendors[index].meta.deposit_maturity ?? '')
    setDeposit_cut_off(vendors[index].meta.deposit_cut_off? moment(vendors[index].meta.deposit_cut_off).format('HH:mm') : '')
    setWithdrawal_maturity(vendors[index].meta.withdrawal_maturity ?? '')
    setWithdrawal_charge(vendors[index].meta.withdrawal_charge ?? '')
    setMaturity(vendors[index].meta.maturity ?? '')
    setWithdrawal_cut_off(vendors[index].meta.withdrawal_cut_off? moment(vendors[index].meta.withdrawal_cut_off).format('HH:mm') : '')
    setCut_off(vendors[index].meta.cut_off? moment(vendors[index].meta.cut_off).format('HH:mm'): '')
    setInterest_trunc_limit(vendors[index].meta.interest_trunc_limit ?? '')
  }

  const handleCloseEditModal = () => {
    setEditModalOpen(false)
    resetFields()
  }

  const handleOpenViewModal = (index) => {
    setViewModalOpen(true)
    setIndex(index)
  }

  const handleCloseViewModal = () => {
    setViewModalOpen(false)
    setIndex(-1)
  }

  const resetFields = () => {
    // setId('')
    setName('')
    setDescription('')
    setEnabled(true)
    setBase_rate(0.0)
    setCurrency_code(currencyOptions[0].value)
    setMin_balance(0)
    setAccount_name('')
    setAccount('')
    setBank('')
    setMobile_money('')
    setApply_interest('')
    setDeposit_maturity('')
    setDeposit_cut_off(null)
    setWithdrawal_maturity('')
    setWithdrawal_charge('')
    setMaturity('')
    setWithdrawal_cut_off(null)
    setCut_off(null)
    setInterest_trunc_limit('')
  }


  const handleCreateSubmit = async (e) => {
    e.preventDefault()
    setSubmitLoader(true)
    resetPopUp()
    try {
      const params = {
        name,
        description,
        enabled,
        base_rate: Number(base_rate),
        currency_code,
        min_balance: min_balance? toBaseValue(min_balance,currency_code): 0,
        meta: {
          account_name,
          account,
          bank,
          mobile_money,
          apply_interest,
          deposit_maturity: Number(deposit_maturity),
          deposit_cut_off: formatCutOffDate(deposit_cut_off),
          withdrawal_maturity: Number(withdrawal_maturity),
          withdrawal_charge: Number(withdrawal_charge),
          withdrawal_cut_off: formatCutOffDate(withdrawal_cut_off),
          interest_trunc_limit: Number(interest_trunc_limit),
          maturity: Number(maturity),
          cut_off: formatCutOffDate(cut_off),
        }
      }
      const {data} = await Api.createVendor(params)
      setVendors([...vendors,data.result.vendor])
      handleClose()
      resetFields()
    } catch (err) {
      handleSetPopupMessage(setPopUp,setPopUpMessage,resetPopUp,NotifyCode.CREATE_VENDOR.ERROR,err.data.error.message,-1)
    }
    setSubmitLoader(false)
  }

  const handleEditSubmit = async (e) => {
    e.preventDefault()
    setSubmitLoader(true)
    resetPopUp()
    try {
      const params = {
        id: vendors[index].id,
        name,
        description,
        enabled,
        base_rate: toBaseRate(base_rate),
        currency_code,
        min_balance: min_balance? toBaseValue(min_balance,currency_code): 0,
        meta: {
          account_name,
          account,
          bank,
          mobile_money,
          apply_interest,
          deposit_maturity: Number(deposit_maturity),
          deposit_cut_off: formatCutOffDate(deposit_cut_off),
          withdrawal_maturity: Number(withdrawal_maturity),
          withdrawal_charge: Number(withdrawal_charge),
          withdrawal_cut_off: formatCutOffDate(withdrawal_cut_off),
          interest_trunc_limit: Number(interest_trunc_limit),
          maturity: Number(maturity),
          cut_off: formatCutOffDate(cut_off),
        }
      }
      console.log(params)
      const {data} = await Api.updateVendor(params)
      setVendors([...vendors.map(item => item.id === data.result.vendor.id ? {...data.result.vendor} : item)])
      handleCloseEditModal()
    } catch (err) {
      handleSetPopupMessage(setPopUp,setPopUpMessage,resetPopUp,NotifyCode.UPDATE_VENDOR.ERROR,err.data.error.message,-1)
    }
    setSubmitLoader(false)
  }

  const refreshBtn = () => {
    setVendors(null)
  }

  const resetPopUp = () =>{
    setPopUp('')
    setPopUpMessage('')
  }

  const panes = [
    {
      menuItem: 'Vendors',
      render: () => <Tab.Pane loading={vendors === null}>
        {(popUp === NotifyCode.FETCH_VENDORS.ERROR) ? <Grid centered>
          <Grid.Row>
            <Grid.Column width={14}>
              <Message negative>
                <Message.Header>{popUpMessage}</Message.Header>
              </Message>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={14}>
              <Button secondary onClick={refreshBtn} icon="redo" content="Refresh" size="tiny" style={{marginLeft: 10}}/>
            </Grid.Column>
          </Grid.Row>
        </Grid>: <>
          <Grid centered>
            <Grid.Row>
              <Grid.Column width={9} floated="left">
                <div style={{display: 'flex', width: '100%'}}>
                  <Input label="Filter" placeholder="Search" value={searchTable}
                         onChange={(e) => setSearchTable(e.target.value)}
                         style={{width: '100%', marginRight: 3}}
                  />
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <VendorList data={vendors} handleView={handleOpenViewModal} searchTable={searchTable}/>
        </> }
        {/*{!networkError ?
          <>
          <Grid centered>
            <Grid.Row>
              <Grid.Column width={9} floated="left">
                <div style={{display: 'flex', width: '100%'}}>
                  <Input label="Filter" placeholder="Search" value={searchTable}
                         onChange={(e) => setSearchTable(e.target.value)}
                         style={{width: '100%', marginRight: 3}}
                  />
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        <VendorList data={vendors} handleView={handleOpenViewModal} searchTable={searchTable}/>
          </> :
          <Container text textAlign="center" style={{height: "37vh"}}>
            Network error
            <Button secondary onClick={refreshBtn} icon="redo" content="Refresh" size="tiny" style={{marginLeft: 10}}/>
          </Container>
        }*/}
      </Tab.Pane>
    },
  ]

  return (
    <>
      <TopBar
        name={props.title}
        actions={
          // userCan(user.access, userLevels.CREATE_VENDOR) &&
        [
            {render: () => <Button basic color="blue" content="Create Vendor" onClick={openModal}/>},
          ]
        }/>
      <Tab panes={panes}/>
      <SideModal
        open={modalOpen}
        onClose={handleClose}
        title="Create Vendor"
      >
        <Form onSubmit={handleCreateSubmit}>
          <Grid centered>
            {( popUp === NotifyCode.CREATE_VENDOR.ERROR) && <Grid.Row>
              <Grid.Column width={14}>
                <Message negative>
                  <Message.Header>{popUpMessage}</Message.Header>
                </Message>
              </Grid.Column>
            </Grid.Row>}
            <Grid.Row>
              <Grid.Column width={12}>
                <Form.Field>
                  <label>Name</label>
                  <input value={name} required
                         onChange={event => setName(event.target.value)}/>
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={12}>
                <Form.Field>
                  <label>Description (Optional)</label>
                  <textarea onChange={event => setDescription(event.target.value)}
                            value={description} rows={3}/>
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={12}>
                <Form.Field>
                  <label>Enabled</label>
                  <Dropdown
                    placeholder='Enabled'
                    fluid
                    selection
                    value={enabled}
                    options={[
                      {key: 'true', text: 'Yes', value: true},
                      {key: 'false', text: 'No', value: false},
                    ]}
                    onChange={(event, {value}) => setEnabled(value)}
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={12}>
                <Form.Field>
                  <label>Currency Code</label>
                  <Dropdown
                    placeholder='Currency Code'
                    fluid
                    selection
                    value={currency_code}
                    options={currencyOptions2}
                    onChange={(event, {value}) => setCurrency_code(value)}
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={12}>
                <Form.Field>
                  <label>Minimum Balance (Optional)</label>
                  <input type="number" placeholder='eg: 5000' value={min_balance} required
                         onChange={event => setMin_balance(event.target.value)}/>
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={12}>
                <Form.Field>
                  <label>Base Rate %(Optional)</label>
                  <input type="number" placeholder='eg: 300' value={base_rate} required min={0} max={100} step={0.0001}
                         onChange={event => setBase_rate(event.target.value)}/>
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={6}>
                <Form.Field>
                  <label>Account Name (Optional)</label>
                  <input value={account_name}
                         onChange={event => setAccount_name(event.target.value)}/>
                </Form.Field>
              </Grid.Column>
              <Grid.Column width={6}>
                <Form.Field>
                  <label>Account (Optional)</label>
                  <input value={account}
                         onChange={event => setAccount(event.target.value)}/>
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={6}>
                <Form.Field>
                  <label>Bank (Optional)</label>
                  <input value={bank}
                         onChange={event => setBank(event.target.value)}/>
                </Form.Field>
              </Grid.Column>
              <Grid.Column width={6}>
                <Form.Field>
                  <label>Mobile Money (Optional)</label>
                  <input value={mobile_money}
                         onChange={event => setMobile_money(event.target.value)}/>
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={6}>
                <Form.Field>
                  <label>Apply Interest (Optional)</label>
                  <Dropdown
                      placeholder='Apply Interest'
                      fluid
                      selection
                      value={apply_interest}
                      options={[
                        {key: 'true', text: 'Yes', value: true},
                        {key: 'false', text: 'No', value: false},
                      ]}
                      onChange={(event, {value}) => setApply_interest(value)}
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column width={6}>
                <Form.Field>
                  <label>Interest truncate limit (Optional)</label>
                  <input type="number" value={interest_trunc_limit} onChange={event => setInterest_trunc_limit(event.target.value)}/>
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={6}>
                <Form.Field>
                  <label>Deposit maturity (Optional)</label>
                  <input value={deposit_maturity} onChange={event => setDeposit_maturity(event.target.value)}/>
                </Form.Field>
              </Grid.Column>
              <Grid.Column width={6}>
                <Form.Field>
                  <label>Deposit cut off (Optional)</label>
                  <TimeInput
                      placeholder="HH:mm"
                      value={deposit_cut_off}
                      iconPosition="left"
                      // timeFormat="HH:mm"
                      onChange={(event, {value}) => setDeposit_cut_off(value)}
                  />
                  {/*<SemanticDatepicker
                      value={deposit_cut_off}
                      onChange={(event,{value}) => setDeposit_cut_off(value)}
                  />*/}
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={6}>
                <Form.Field>
                  <label>Withdrawal maturity (Optional)</label>
                  <input value={withdrawal_maturity} onChange={event => setWithdrawal_maturity(event.target.value)}/>
                </Form.Field>
              </Grid.Column>
              <Grid.Column width={6}>
                <Form.Field>
                  <label>Withdrawal cut off (Optional)</label>
                  <TimeInput
                      placeholder="HH:mm"
                      value={withdrawal_cut_off}
                      iconPosition="left"
                      // timeFormat="HH:mm"
                      onChange={(event, {value}) => setWithdrawal_cut_off(value)}
                  />
                 {/*<SemanticDatepicker
                      value={withdrawal_cut_off}
                      onChange={(event,{value}) => setWithdrawal_cut_off(value)}
                  />*/}
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={6}>
                <Form.Field>
                  <label>Maturity (Optional)</label>
                  <input value={maturity} onChange={event => setMaturity(event.target.value)}/>
                </Form.Field>
              </Grid.Column>
              <Grid.Column width={6}>
                <Form.Field>
                  <label>Cut off (Optional)</label>
                  <TimeInput
                      placeholder="HH:mm"
                      value={cut_off}
                      iconPosition="left"
                      // timeFormat="HH:mm"
                      onChange={(event, {value}) => setCut_off(value)}
                  />
                </Form.Field>
                {/*<Form.Field>
                  <label>Cut off (Optional)</label>
                  <SemanticDatepicker
                      value={cut_off}
                      onChange={(event,{value}) => setCut_off(value)}
                  />
                </Form.Field>*/}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={12}>
                <Form.Field>
                  <label>Withdrawal charges</label>
                  <input value={withdrawal_charge} onChange={event => setWithdrawal_charge(event.target.value)}/>
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Button type='submit' loading={submitLoader} disabled={submitLoader}>Submit</Button>
          </Grid>
        </Form>
      </SideModal>
      {!!(vendors && vendors.length && index !== -1) &&
        <>
          <SideModal
            open={editModalOpen}
            onClose={handleCloseEditModal}
            title="Edit Vendor"
          >
            <Form onSubmit={handleEditSubmit}>
              <Grid centered>
                {(popUp === NotifyCode.UPDATE_VENDOR.ERROR) && <Grid.Row>
                  <Grid.Column width={14}>
                    <Message
                        negative={popUp === NotifyCode.UPDATE_VENDOR.ERROR}>
                      <Message.Header>{popUpMessage}</Message.Header>
                    </Message>
                  </Grid.Column>
                </Grid.Row>}
                <Grid.Row>
                  <Grid.Column width={12}>
                    <Form.Field>
                      <label>Name</label>
                      <input placeholder='Name' value={name} required
                             onChange={event => setName(event.target.value)}/>
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={12}>
                    <Form.Field>
                      <label>Description (Optional)</label>
                      <textarea onChange={event => setDescription(event.target.value)}
                                value={description} rows={3}/>
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={12}>
                    <Form.Field>
                      <label>Enabled</label>
                      <Dropdown
                        placeholder='Enabled'
                        fluid
                        selection
                        value={enabled}
                        options={[
                          {key: 'true', text: 'Yes', value: true},
                          {key: 'false', text: 'No', value: false},
                        ]}
                        onChange={(event, {value}) => setEnabled(value)}
                      />
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={12}>
                    <Form.Field>
                      <label>Currency Code</label>
                      <Dropdown
                        placeholder='Currency Code'
                        fluid
                        selection
                        value={currency_code}
                        options={currencyOptions2}
                        onChange={(event, {value}) => setCurrency_code(value)}
                      />
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={12}>
                    <Form.Field>
                      <label>Min Balance</label>
                      <input type="number" value={min_balance} required min={0} step={1}
                             onChange={event => setMin_balance(event.target.value)}/>
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={12}>
                    <Form.Field>
                      <label>Base Rate %</label>
                      <input type="number" value={base_rate} required min={0} max={100} step={0.0001}
                             onChange={event => setBase_rate(event.target.value)}/>
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={6}>
                    <Form.Field>
                      <label>Account Name (Optional)</label>
                      <input value={account_name}
                             onChange={event => setAccount_name(event.target.value)}/>
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column width={6}>
                    <Form.Field>
                      <label>Account (Optional)</label>
                      <input value={account}
                             onChange={event => setAccount(event.target.value)}/>
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={6}>
                    <Form.Field>
                      <label>Bank (Optional)</label>
                      <input value={bank}
                             onChange={event => setBank(event.target.value)}/>
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column width={6}>
                    <Form.Field>
                      <label>Mobile Money (Optional)</label>
                      <input value={mobile_money}
                             onChange={event => setMobile_money(event.target.value)}/>
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={6}>
                    <Form.Field>
                      <label>Apply Interest (Optional)</label>
                      <Dropdown
                          placeholder='Apply Interest'
                          fluid
                          selection
                          value={apply_interest}
                          options={[
                            {key: 'true', text: 'Yes', value: true},
                            {key: 'false', text: 'No', value: false},
                          ]}
                          onChange={(event, {value}) => setApply_interest(value)}
                      />
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column width={6}>
                    <Form.Field>
                      <label>Interest truncate limit (Optional)</label>
                      <input type="number" value={interest_trunc_limit} onChange={event => setInterest_trunc_limit(event.target.value)}/>
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={6}>
                    <Form.Field>
                      <label>Deposit maturity (Optional)</label>
                      <input value={deposit_maturity} onChange={event => setDeposit_maturity(event.target.value)}/>
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column width={6}>
                    <Form.Field>
                      <label>Deposit cut off (Optional)</label>
                      <TimeInput
                          placeholder="HH:mm"
                          value={deposit_cut_off}
                          iconPosition="left"
                          // timeFormat="HH:mm"
                          onChange={(event, {value}) => setDeposit_cut_off(value)}
                      />
                      {/*<SemanticDatepicker
                          value={deposit_cut_off}
                          onChange={(event,{value}) => setDeposit_cut_off(value)}
                      />*/}
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={6}>
                    <Form.Field>
                      <label>Withdrawal maturity (Optional)</label>
                      <input value={withdrawal_maturity} onChange={event => setWithdrawal_maturity(event.target.value)}/>
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column width={6}>
                    <Form.Field>
                      <label>Withdrawal cut off (Optional)</label>
                      <TimeInput
                          placeholder="HH:mm"
                          value={withdrawal_cut_off}
                          iconPosition="left"
                          // timeFormat="HH:mm"
                          onChange={(event, {value}) => setWithdrawal_cut_off(value)}
                      />
                      {/*<SemanticDatepicker
                          value={withdrawal_cut_off}
                          onChange={(event,{value}) => setWithdrawal_cut_off(value)}
                      />*/}
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={6}>
                    <Form.Field>
                      <label>Maturity (Optional)</label>
                      <input value={maturity} onChange={event => setMaturity(event.target.value)}/>
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column width={6}>
                    <Form.Field>
                      <label>Cut off (Optional)</label>
                      <TimeInput
                          placeholder="HH:mm"
                          value={cut_off}
                          iconPosition="left"
                          // timeFormat="HH:mm"
                          onChange={(event, {value}) => setCut_off(value)}
                      />
                      {/*<SemanticDatepicker
                          value={cut_off}
                          onChange={(event,{value}) => setCut_off(value)}
                      />*/}
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={12}>
                    <Form.Field>
                      <label>Withdrawal charges</label>
                      <input value={withdrawal_charge} onChange={event => setWithdrawal_charge(event.target.value)}/>
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                <Button type='submit' loading={submitLoader} disabled={submitLoader}>Submit</Button>
              </Grid>
            </Form>
          </SideModal>
          <SideModal
            open={viewModalOpen}
            onClose={handleCloseViewModal}
            title="Vendor Detail"
            width={editModalOpen?80:60}
          >
            <Grid centered>
              <Grid.Row textAlign="right">
                <Grid.Column width={6} floated="right">
                  {/*{ userCan(user.access, userLevels.EDIT_VENDOR) && */}
                      <Button icon={"pencil"} content='Edit' size="mini" color={"blue"} onClick={()=> handleOpenEditModal(index)}/>
                  {/*}*/}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={12}>
                  <Header as='h4'>
                    <Header.Content>
                      <Header.Subheader>Name</Header.Subheader>
                      {vendors[index].name}
                    </Header.Content>
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={12}>
                  <Header as='h4'>
                    <Header.Content>
                      <Header.Subheader>Description</Header.Subheader>
                      {vendors[index].description ?? '-'}
                    </Header.Content>
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={6}>
                  <Header as='h4'>
                    <Header.Content>
                      <Header.Subheader>Enabled</Header.Subheader>
                      <Label
                        color={vendors[index].enabled?'green':'red'}
                        size="medium" content={vendors[index].enabled?'True' : 'False'} />
                    </Header.Content>
                  </Header>
                </Grid.Column>
                <Grid.Column width={6}>
                  <Header as='h4'>
                    <Header.Content>
                      <Header.Subheader>Base Rate</Header.Subheader>
                      {toBasePercentage(vendors[index].base_rate)}%
                    </Header.Content>
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={12}>
                  <Header as='h4'>
                    <Header.Content>
                      <Header.Subheader>Min Balance</Header.Subheader>
                      {toReadableValue(vendors[index].min_balance,vendors[index].currency_code)} {currencyCode(vendors[index].currency_code)}
                    </Header.Content>
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={6}>
                  <Header as='h4'>
                    <Header.Content>
                      <Header.Subheader>Account Name</Header.Subheader>
                      {vendors[index].meta.account_name ?? '-'}
                    </Header.Content>
                  </Header>
                </Grid.Column>
                <Grid.Column width={6}>
                  <Header as='h4'>
                    <Header.Content>
                      <Header.Subheader>Account</Header.Subheader>
                      {vendors[index].meta.account ?? '-'}
                    </Header.Content>
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={6}>
                  <Header as='h4'>
                    <Header.Content>
                      <Header.Subheader>Bank</Header.Subheader>
                      {vendors[index].meta.bank ?? '-'}
                    </Header.Content>
                  </Header>
                </Grid.Column>
                <Grid.Column width={6}>
                  <Header as='h4'>
                    <Header.Content>
                      <Header.Subheader>Mobile Money</Header.Subheader>
                      {vendors[index].meta.mobile_money ?? '-'}
                    </Header.Content>
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={6}>
                  <Header as='h4'>
                    <Header.Content>
                      <Header.Subheader>Apply interest</Header.Subheader>
                          <Label
                          color={vendors[index].meta.apply_interest ?'green':'red'}
                          size="medium" content={vendors[index].meta.apply_interest?'True' : 'False'} />
                    </Header.Content>
                  </Header>
                </Grid.Column>
                <Grid.Column width={6}>
                  <Header as='h4'>
                    <Header.Content>
                      <Header.Subheader>Interest trunc limit</Header.Subheader>
                      {vendors[index].meta.interest_trunc_limit ?? '-'}
                      {/*{vendors[index].meta.interest_trunc_limit ? toReadableValue(vendors[index].meta.interest_trunc_limit,vendors[index].currency_code) : '-'} {currencyCode(vendors[index].currency_code)}*/}
                    </Header.Content>
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={6}>
                  <Header as='h4'>
                    <Header.Content>
                      <Header.Subheader>Deposit maturity</Header.Subheader>
                      {vendors[index].meta.deposit_maturity ?? '-'}
                      {/*{vendors[index].meta.deposit_maturity ? toReadableValue(vendors[index].meta.deposit_maturity,vendors[index].currency_code) : '-'} {currencyCode(vendors[index].currency_code)}*/}
                    </Header.Content>
                  </Header>
                </Grid.Column>
                <Grid.Column width={6}>
                  <Header as='h4'>
                    <Header.Content>
                      <Header.Subheader>Deposit cut off</Header.Subheader>
                      {vendors[index].meta.deposit_cut_off ? moment(vendors[index].meta.deposit_cut_off).format('HH:mm') : '-'}
                    </Header.Content>
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={6}>
                  <Header as='h4'>
                    <Header.Content>
                      <Header.Subheader>Withdrawal maturity</Header.Subheader>
                      {vendors[index].meta.withdrawal_maturity ?? '-'}
                      {/*{vendors[index].meta.withdrawal_maturity ? toReadableValue(vendors[index].meta.withdrawal_maturity,vendors[index].currency_code) : '-'} {currencyCode(vendors[index].currency_code)}*/}
                    </Header.Content>
                  </Header>
                </Grid.Column>
                <Grid.Column width={6}>
                  <Header as='h4'>
                    <Header.Content>
                      <Header.Subheader>Withdrawal cut off</Header.Subheader>
                      {vendors[index].meta.withdrawal_cut_off ? moment(vendors[index].meta.withdrawal_cut_off).format('HH:mm') : '-'}
                    </Header.Content>
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={6}>
                  <Header as='h4'>
                    <Header.Content>
                      <Header.Subheader>Maturity</Header.Subheader>
                      {vendors[index].meta.maturity ?? '-'}
                      {/*{vendors[index].meta.maturity ? toReadableValue(vendors[index].meta.maturity,vendors[index].currency_code) : '-'} {currencyCode(vendors[index].currency_code)}*/}
                    </Header.Content>
                  </Header>
                </Grid.Column>
                <Grid.Column width={6}>
                  <Header as='h4'>
                    <Header.Content>
                      <Header.Subheader>Cut off</Header.Subheader>
                      {vendors[index].meta.cut_off ? moment(vendors[index].meta.cut_off).format('HH:mm') : '-'}
                    </Header.Content>
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={12}>
                  <Header as='h4'>
                    <Header.Content>
                      <Header.Subheader>Withdrawal charge</Header.Subheader>
                      {vendors[index].meta.withdrawal_charge ?? '-'}
                      {/*{vendors[index].meta.withdrawal_charge ? toReadableValue(vendors[index].meta.withdrawal_charge,vendors[index].currency_code) : '-'} {currencyCode(vendors[index].currency_code)}*/}
                    </Header.Content>
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row >
                <Grid.Column width={14}>
                  <Accordion fluid styled>
                    <Accordion.Title active={showMeta} onClick={()=> setShowMeta(!showMeta)}>
                      <Icon name='dropdown' />
                      Meta
                    </Accordion.Title>
                    <Accordion.Content active={showMeta}>
                      <pre>{JSON.stringify(vendors[index].meta,null,2)}</pre>
                    </Accordion.Content>
                  </Accordion>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </SideModal>
        </>
      }
    </>
  );
}

export default Vendors;

