import React, {useState} from 'react';
import {Button, Container, Grid, Header, Message,Image} from 'semantic-ui-react';
import {signInWithGoogle} from "../../util/firebaseConfig";
import Api, {STORAGE_KEYS} from '../../util/api'


const LoginForm = (props) => {
  const uid = props.location.search.replace('?uid=','')
  const [isLoadingGoogle, setIsLoadingGoogle] = useState(false)
  const [error, setError] = useState(null)

  const handleFirebaseSignIn = async ()=>{
    setError(null)
    try{
      const {user} = await signInWithGoogle()
      // console.log('user',user)
      setIsLoadingGoogle(true)
      const {data} = await Api.loginFirebase(uid.length?uid:user.uid)
      // console.log('data',data)
      if(data.result.is_admin){
        localStorage.setItem(STORAGE_KEYS.TOKEN, data.result.token)
        localStorage.setItem(STORAGE_KEYS.USER, JSON.stringify(data.result.user))
        localStorage.setItem(STORAGE_KEYS.SESSION, Date.now())
        const path = localStorage.getItem('to.path')
        localStorage.removeItem('to.path')
        window.location.replace(path ?? '/');
      }else {
        setError({message:'Login failed'})
      }
    }catch (err) {
      console.log('err',err)
      setError({message:'Network Error'})
    }
    setIsLoadingGoogle(false)
  }

    return (
      <div className="login-form">
        <style>
          {`
        body,
        body > div,
        body > div > div,
        body > div > div > div.login-form {
          height: 100%;
        }
      `}
        </style>
        <Container style={{height: '100%', display: 'flex', alignItems: 'center'}}>
          <Grid centered verticalAlign="middle" style={{width: '100%'}}>
            <Grid.Column computer={6} largeScreen={6} mobile={12}>
              <Header as="h1" content="Koa" color="teal"/>
              {error && (
                <Message negative>
                  <Message.Header>{error.message}</Message.Header>
                </Message>)}
              <Button loading={isLoadingGoogle} disabled={isLoadingGoogle} onClick={handleFirebaseSignIn}>
                <Image src='/google.svg' style={{width:25,height: 'auto',fontSize:'0.8125rem'}} verticalAlign='middle'/> Login with Google
              </Button>
            </Grid.Column>
            <Grid.Column width={10} style={{fontSize: '5rem'}}>
            </Grid.Column>
          </Grid>
        </Container>
      </div>
    );
}

export default LoginForm;
