import React from 'react';
import {Button, Container, Table} from 'semantic-ui-react';
import {searchOnTable, toReadableDateTime,
    // userCan, userLevels
} from "../../util/HelperFunctions";
// import {UserContext} from "../../context/UserProvider";

const JobList = ({data,handleView,searchTable,handleDelete})=> {
    // const {user} = useContext(UserContext)
    return (
      <>
        {data && data.length ?
        <Table singleLine basic='very' striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Class</Table.HeaderCell>
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.HeaderCell>Priority</Table.HeaderCell>
              <Table.HeaderCell>Error Count</Table.HeaderCell>
              <Table.HeaderCell>Run At</Table.HeaderCell>
                {/*{ (userCan(user.access, userLevels.VIEW_JOB_DETAILS) || userCan(user.access, userLevels.DELETE_JOB)) && */}
                    <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
                {/*}*/}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.map((item,i) =>searchOnTable(searchTable,
              [item.job_class,item.args.Type,item.priority,item.error_count,toReadableDateTime(item.run_at),item.run_at]) ?
              <Table.Row key={i}>
                <Table.Cell>{item.job_class}</Table.Cell>
                <Table.Cell>{item.args.Type??''}</Table.Cell>
                <Table.Cell>{item.priority}</Table.Cell>
                <Table.Cell>{item.error_count}</Table.Cell>
                <Table.Cell>{toReadableDateTime(item.run_at)}</Table.Cell>
                  {/*{ (userCan(user.access, userLevels.VIEW_JOB_DETAILS) || userCan(user.access, userLevels.DELETE_JOB)) && */}
                      <Table.Cell style={{float:'right'}}>
                {/*{ userCan(user.access, userLevels.DELETE_JOB) && */}
                    <Button icon={"trash"} content='Delete' size="mini" color={"red"} onClick={()=> handleDelete(i)}/>
                {/*}*/}
                {/*{ userCan(user.access, userLevels.VIEW_TRANSACTION_DETAILS) && */}
                    <Button icon="file alternate outline" content='View Details' size="small" color="green" floated="right"
                          onClick={()=> handleView(i)}/>
                {/*}*/}
                </Table.Cell>
                  {/*}*/}
              </Table.Row>:null
              )
            }
          </Table.Body>
        </Table>
        :
        <Container text textAlign="center" content="No records found" style={{height: "37vh"}}/>
        }
      </>
    );
}


export default JobList;

