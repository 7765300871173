import React, {useContext} from 'react';
import {Divider, Grid, List} from 'semantic-ui-react';
import TitleBar from '../components/TitleBar';
import AppMenu from "../components/AppMenu";
import {UserContext} from "../context/UserProvider";
import {userCan} from "../util/HelperFunctions";
import {Redirect} from "react-router-dom";

const Dashboard = (props) => {
  const {user} = useContext(UserContext)
  if( props.access !== 'none' && !userCan(user.access, props.access)){
    return <Redirect to="/"/>
  }
  return (
      <div style={{display:'flex', flexDirection:'column',minHeight: '100vh'}}>
        <Grid centered>
          <Grid.Row style={{paddingBottom: "0"}}>
            <Grid.Column width={props.width || 14}>
              <TitleBar/>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style={{paddingTop: "0"}}>
            <Grid.Column width={props.width || 14}>
              {props.showMenu && <AppMenu/>}
              {props.children}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <div style={{margin: 'auto 5vw 60px'}}>
          <Divider style={{marginBottom: 30}}/>
          <List floated="right" horizontal>
            <List.Item disabled href="#">© Koa Technologies</List.Item>
          </List>
          <List horizontal>
            <List.Item href="#">Terms &amp; Conditions</List.Item>
            <List.Item href="#">Privacy Policy</List.Item>
            <List.Item href="#">Cookies</List.Item>
            <List.Item href="#">Support</List.Item>
          </List>
        </div>
      </div>
  );
}

export default Dashboard

