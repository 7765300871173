import React, {useContext} from 'react';
import {Button, Container, Label, Table} from 'semantic-ui-react';
import {
  currencyCode, getTransactionDirection, getTransactionType,
  isLargeScreen,
  statusColor,
  statusName,
  toReadableDateTime,
  toReadableValue, transactionDirectionColor, transactionTypeColor, userCan, userLevels
} from "../../util/HelperFunctions";
import {UserContext} from "../../context/UserProvider";

const TransactionList = ({data, handleView}) => {
  const {user} = useContext(UserContext)
  return (
    <>
      {data && data.length ?
        <Table singleLine basic='very' striped fixed={!isLargeScreen()}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Id</Table.HeaderCell>
              <Table.HeaderCell>Reference ID</Table.HeaderCell>
              <Table.HeaderCell>Amount</Table.HeaderCell>
              <Table.HeaderCell>Direction</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              {isLargeScreen() && <Table.HeaderCell>Date</Table.HeaderCell>}
              { userCan(user.access, userLevels.VIEW_TRANSACTION_DETAILS) && <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.map((item, i) =>
              <Table.Row key={i}>
                <Table.Cell>{item.id}</Table.Cell>
                <Table.Cell>{item.ref}</Table.Cell>
                <Table.Cell>{toReadableValue(item.amount,item.currency_code)} {currencyCode(item.currency_code)}</Table.Cell>
                <Table.Cell>
                  <Label
                      color={item.transfer_type === 'interest' ? transactionTypeColor(item.transfer_type):transactionDirectionColor(item.direction)}
                      size="medium" content={item.transfer_type === 'interest' ?getTransactionType(item.transfer_type):getTransactionDirection(item.direction)} />
                </Table.Cell>
                <Table.Cell>
                  <Label
                    color={statusColor(item.status)}
                    size="medium" content={statusName(item.status)}/>
                </Table.Cell>
                {isLargeScreen() &&<Table.Cell>{toReadableDateTime(item.created_at)}</Table.Cell>}
                { userCan(user.access, userLevels.VIEW_TRANSACTION_DETAILS) && <Table.Cell>
                  <Button icon="file alternate outline" content='View Details' size="small" color="green" floated="right"
                          onClick={() => handleView(item)}/>
                </Table.Cell>}
              </Table.Row>
            )
            }
          </Table.Body>
        </Table>
        :
        <Container text textAlign="center" content="No records found" style={{height: "37vh",paddingTop:'7vh'}}/>
      }
    </>
  );
}


export default TransactionList;

