import {Card, Divider, Loader} from "semantic-ui-react";
import React from "react";

function UserStats({userStats}) {
    return userStats === null ? <>
        <Divider hidden/>
        <Card.Group>
            <Card>
                <Card.Content>
                    <Card.Header><Loader active inline /></Card.Header>
                    <Card.Description>
                        Total Verified Users
                    </Card.Description>
                </Card.Content>
            </Card>
            <Card>
                <Card.Content>
                    <Card.Header><Loader active inline /></Card.Header>
                    <Card.Description>
                        Pending Verification
                    </Card.Description>
                </Card.Content>
            </Card>
            <Card>
                <Card.Content>
                    <Card.Header><Loader active inline /></Card.Header>
                    <Card.Description>
                        Awaiting to provide data
                    </Card.Description>
                </Card.Content>
            </Card>
            <Card>
                <Card.Content>
                    <Card.Header><Loader active inline /></Card.Header>
                    <Card.Description>
                        Signed up today
                    </Card.Description>
                </Card.Content>
            </Card>
        </Card.Group>
        <Divider hidden/>
    </>: userStats === undefined ? <></>: <>
        <Divider hidden/>
        <Card.Group>
            <Card>
                <Card.Content>
                    <Card.Header>{userStats.verified}</Card.Header>
                    <Card.Description>
                        Total Verified Users
                    </Card.Description>
                </Card.Content>
            </Card>
            <Card>
                <Card.Content>
                    <Card.Header>{userStats.pending_verification}</Card.Header>
                    <Card.Description>
                        Pending Verification
                    </Card.Description>
                </Card.Content>
            </Card>
            <Card>
                <Card.Content>
                    <Card.Header>{userStats.data_incomplete}</Card.Header>
                    <Card.Description>
                        Awaiting to provide data
                    </Card.Description>
                </Card.Content>
            </Card>
            <Card>
                <Card.Content>
                    <Card.Header>{userStats.data_incomplete}</Card.Header>
                    <Card.Description>
                        Signed up today
                    </Card.Description>
                </Card.Content>
            </Card>
        </Card.Group>
        <Divider hidden/>
    </>
}

export default UserStats