import React, {useEffect, useState} from 'react';
import TopBar from '../../util/topbar';
import {remoteConfig} from "../../util/firebaseConfig";
import Api from "../../util/api";

const Home = (props) => {

    const [vendors, setVendors] = useState(null)

    useEffect(() => {
        if (!vendors) {
            fetchVendors()
        }
    },[])

    const fetchVendors = async () => {
        try {
            const {data} = await Api.allVendors()
            if (data.result.vendors) {
                setVendors(data.result.vendors)
            } else {
                setVendors([])
            }
        } catch (err) {

        }
    }

    const getIframe = () => !!remoteConfig.getValue('koa_dashboard_home_statistics').asString() ?
            <div dangerouslySetInnerHTML={{__html: remoteConfig.getValue('koa_dashboard_home_statistics').asString()}}/>:
            <iframe width="100%" height="600"
                    src="https://datastudio.google.com/embed/reporting/61f8599a-b2cd-4afa-a36c-336c7a3c625d/page/p_nxdukzpztc"
                    frameBorder="0" style={{border:0}} allowFullScreen title="Stats dashboard"/>

    return (
      <>
        <TopBar name={props.title}/>
          {getIframe()}
      </>
    );
}

export default Home;

