export const currencyOptions = [
  {key: 'kec', text: 'KSH', value: 'KEc'},
  // {key: 'ke6', text: 'KE6', value: 'KE6'},
  // {key: 'ghp', text: 'GHS', value: 'GHp'},
  // {key: 'usc', text: 'USD', value: 'USc'}
]

export const currencyOptions2 = [
  {key: 'kec', text: 'KSH', value: 'KEc'},
  {key: 'ke6', text: 'KE6', value: 'KE6'},
  // {key: 'ghp', text: 'GHS', value: 'GHp'},
  // {key: 'usc', text: 'USD', value: 'USc'}
]

export const recurrenceOptions = [
  {key:'daily',text:'Daily',value:'daily'},
  {key:'weekly',text:'Weekly',value:'weekly'},
  {key:'monthly',text:'Monthly',value:'monthly'},
  {key:'once',text:'Once',value:'once'},
]

