import React, {useContext} from 'react';
import {Button, Container, Label, Table} from 'semantic-ui-react';
import {
    searchOnTable,
    toReadableDateTime, userCan, userLevels, yesOrNoColor
} from "../../util/HelperFunctions";
import {UserContext} from "../../context/UserProvider";

const TransactionExportList = ({data,handleView,searchTable,vendors}) => {
    const {user} = useContext(UserContext)
  return (
    <>
      {data && data.length && vendors ?
      <Table singleLine basic='very' striped fixed>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>Closed</Table.HeaderCell>
            <Table.HeaderCell>Vendor</Table.HeaderCell>
            <Table.HeaderCell>Date</Table.HeaderCell>
              { userCan(user.access, userLevels.VIEW_EXPORT_DETAILS) &&<Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {data.map((item,i) =>searchOnTable(searchTable,
            [item.id,item.closed?'True':'False',item.created_at,toReadableDateTime(item.created_at),
              item.vendor_id ? vendors.find(e => e.id === item.vendor_id)?.name??'-' : '-']) ?
            <Table.Row key={i}>
              <Table.Cell>{item.id.substring(0,8)}...</Table.Cell>
              <Table.Cell>
                <Label
                color={yesOrNoColor(item.closed)}
                size="medium" content={item.closed?'True':'False'} /></Table.Cell>
              <Table.Cell>{item.vendor_id ? vendors.find(e => e.id === item.vendor_id)?.name??'-' : '-'}</Table.Cell>
              <Table.Cell>{toReadableDateTime(item.created_at)}</Table.Cell>
                { userCan(user.access, userLevels.VIEW_EXPORT_DETAILS) && <Table.Cell style={{float:'right'}}>
                <Button icon="file alternate outline" content='View Details' size="mini" color="green" onClick={()=> handleView(item)}/>
              </Table.Cell>}
            </Table.Row>:null
          )
          }
        </Table.Body>
      </Table>
        :
        <Container text textAlign="center" content="No records found" style={{height: "37vh"}}/>
      }
      </>
    );
}


export default TransactionExportList;

