import Aux from "../util/Aux"
import LoginForm from "../pages/auth/login";
import RequestPassword from "../pages/auth/requestPassword";
import DashboardLayout from "../layout/DashboardLayout";
import Home from "../pages/home";
import Users from "../pages/users";
import Products from "../pages/products";
import Transactions from "../pages/transactions";
import Categories from "../pages/categories";
import Vendors from "../pages/vendors";
import Jobs from "../pages/jobs";
import Exports from "../pages/exports";
import AppSettings from "../pages/appConfigs";
import {userLevels} from "../util/HelperFunctions";

export default [
  {
    title:'Login',
    layout: Aux,
    component: LoginForm,
    path: '/login',
    exact: false,
    private: false,
    redirect: '/'
  },
  {
    title:'Change Password',
    layout: DashboardLayout,
    component: RequestPassword,
    path: '/change-password',
    exact: false,
    private: true,
    redirect: '/login'
  },
  {
    title:'Home',
    layout: DashboardLayout,
    component: Home,
    path: '/',
    exact: true,
    private: true,
    showMenu: true,
    redirect: '/login',
    access: 'none'
  },
  {
    title:'Users',
    layout: DashboardLayout,
    component: Users,
    path: '/users',
    exact: true,
    private: true,
    showMenu: true,
    redirect: '/login',
    access: 'none'
    // access: userLevels.VIEW_USERS
  },
  {
    title:'Users',
    layout: DashboardLayout,
    component: Users,
    path: '/users/:getUser',
    exact: false,
    private: true,
    showMenu: true,
    redirect: '/login',
    access: 'none'
    // access: userLevels.VIEW_USERS
  },
  {
    title:'Products',
    layout: DashboardLayout,
    component: Products,
    path: '/products',
    exact: false,
    private: true,
    showMenu: true,
    redirect: '/login',
    access: 'none'
    // access: userLevels.VIEW_PRODUCTS
  },
  {
    title:'Transactions',
    layout: DashboardLayout,
    component: Transactions,
    path: '/transactions',
    exact: false,
    private: true,
    showMenu: true,
    redirect: '/login',
    access: 'none'
    // access: userLevels.VIEW_TRANSACTIONS
  },
  {
    title:'Categories',
    layout: DashboardLayout,
    component: Categories,
    path: '/categories',
    exact: false,
    private: true,
    showMenu: true,
    redirect: '/login',
    access: 'none'
    // access: userLevels.VIEW_CATEGORIES
  },
  {
    title:'Vendors',
    layout: DashboardLayout,
    component: Vendors,
    path: '/vendors',
    exact: false,
    private: true,
    showMenu: true,
    redirect: '/login',
    access: 'none'
    // access: userLevels.VIEW_VENDORS
  },
  {
    title:'Jobs',
    layout: DashboardLayout,
    component: Jobs,
    path: '/jobs',
    exact: false,
    private: true,
    showMenu: true,
    redirect: '/login',
    access: 'none'
    // access: userLevels.VIEW_JOBS
  },
  {
    title:'Exports',
    layout: DashboardLayout,
    component: Exports,
    path: '/exports',
    exact: false,
    private: true,
    showMenu: true,
    redirect: '/login',
    access: 'none'
    // access: userLevels.VIEW_EXPORTS
  },
  {
    title:'App configs',
    layout: DashboardLayout,
    component: AppSettings,
    path: '/app-configs',
    exact: false,
    private: true,
    showMenu: true,
    redirect: '/login',
    access: 'none'
    // access: userLevels.VIEW_APP_CONFIGS
  },
]
