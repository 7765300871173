import React,{useState} from "react";
import {Button, Grid, Header, Icon, Label, Popup} from "semantic-ui-react";
import {toReadableDateTime, userCan, userLevels} from "../util/HelperFunctions";
import Api from "../util/api";

const KYCData = ({data, user, kyc_state,currentUser,showSmileChecks,smileChecks,handleShowSmileChecks}) => {

  const [load, setLoad] = useState('')
  const [showSelfie, setShowSelfie] = useState(false)
  const [selfieURL, setSelfieURL] = useState('')
  const [showDocumentFront, setShowDocumentFront] = useState(false)
  const [documentFrontURL, setDocumentFrontURL] = useState('')
  const [showDocumentBack, setShowDocumentBack] = useState(false)
  const [documentBackURL, setDocumentBackURL] = useState('')


  const getImageURL = async (key,doc_type) =>{
    let signed_url = ''
    try{
      const params = {
        doc_type,
        key
      }
      const {data} = await Api.uploadGetURL(params)
      signed_url = data.result.download_url
    }
    catch (err){
      // return ''
    }
    return signed_url
  }

  const handleSelfie = async ()=> {
    if(selfieURL === ''){
      setLoad('selfie')
      const url = await getImageURL(data.selfie,'selfie')
      setSelfieURL(url)
      setLoad('')
    }
    setShowSelfie(!showSelfie)
  }

  const handleDocumentFront = async ()=> {
    if(documentFrontURL === ''){
      setLoad('id_document')
      const url = await getImageURL(data.id_document, data.id_type.toLowerCase())
      setDocumentFrontURL(url)
      setLoad('')
    }
    setShowDocumentFront(!showDocumentFront)
  }

  const handleDocumentBack = async ()=> {
    if(documentBackURL === ''){
      setLoad('id_document_back')
      const url = await getImageURL(data.id_document_back, data.id_type.toLowerCase())
      setDocumentBackURL(url)
      setLoad('')
    }
    setShowDocumentBack(!showDocumentBack)
  }

  return <>
    <Grid.Row textAlign="right">
      <Grid.Column
          width={14} floated="right">
        { (userCan(currentUser.access, userLevels.VIEW_SMILE_CHECKS) && user.meta.smile_id_job && user.meta.smile_id_job.callback_response) &&
            <Button icon="eye" content={`${showSmileChecks? 'Hide':'Show'} Smile Check`} size="small" color={showSmileChecks? 'yellow':'blue'} onClick={()=>handleShowSmileChecks(user,!showSmileChecks)}/>}
      </Grid.Column>
    </Grid.Row>
    {/*<Grid.Row>
      <Grid.Column width={7}>
        <Header as='h4'>
          <Header.Content>
            <Header.Subheader>Marital Status</Header.Subheader>
            {data.marital_status??'-'}
          </Header.Content>
        </Header>
      </Grid.Column>
      <Grid.Column width={7}>
        <Header as='h4'>
          <Header.Content>
            <Header.Subheader>Post Address</Header.Subheader>
            {data.post_address??'-'}
          </Header.Content>
        </Header>
      </Grid.Column>
    </Grid.Row>*/}
    <Grid.Row>
      <Grid.Column width={7}>
        <Header as='h4'>
          <Header.Content>
            <Header.Subheader>Occupation</Header.Subheader>
            {data.occupation??'-'}
          </Header.Content>
        </Header>
      </Grid.Column>
      <Grid.Column width={7}>
        <Header as='h4'>
          <Header.Content>
            <Header.Subheader>Verified At</Header.Subheader>
            {data.verified_at?toReadableDateTime(data.verified_at):'-'}
          </Header.Content>
        </Header>
      </Grid.Column>

    </Grid.Row>
    {/*<Grid.Row>
      <Grid.Column width={7}>
        <Header as='h4'>
          <Header.Content>
            <Header.Subheader>Employer Phone Number</Header.Subheader>
            {data.employer_phone_number??'-'}
          </Header.Content>
        </Header>
      </Grid.Column>
      <Grid.Column width={7}>
        <Header as='h4'>
          <Header.Content>
            <Header.Subheader>Employer Post Address</Header.Subheader>
            {data.employer_post_address??'-'}
          </Header.Content>
        </Header>
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column width={7}>
        <Header as='h4'>
          <Header.Content>
            <Header.Subheader>Employer Industry</Header.Subheader>
            {data.employer_industry??'-'}
          </Header.Content>
        </Header>
      </Grid.Column>
      <Grid.Column width={7}>
        <Header as='h4'>
          <Header.Content>
            <Header.Subheader>Employer</Header.Subheader>
            {data.employer??'-'}
          </Header.Content>
        </Header>
      </Grid.Column>
    </Grid.Row>*/}
    <Grid.Row>
      <Grid.Column width={7}>
        <Header as='h4'>
          <Header.Content>
            <Header.Subheader>ID Type</Header.Subheader>
            {data.id_type??'-'}
          </Header.Content>
        </Header>
      </Grid.Column>
      <Grid.Column width={7}>
        <Header as='h4'>
          <Header.Content>
            <Header.Subheader>
              ID Number {!!data.id_number && <Popup content='Click to Copy' trigger={
              <Popup content='Copied to Clipboard' on="click" trigger={
                <Icon name="copy" onClick={() => navigator.clipboard.writeText(data.id_number)}/>}
              />}
            />}
              {showSmileChecks && <>
                / Smile ID Number
                <Popup content='Click to Copy' trigger={
                  <Popup content='Copied to Clipboard' on="click" trigger={
                    <Icon name="copy"
                          onClick={() => navigator.clipboard.writeText(user.meta.smile_id_job?.callback_response?.IDNumber)}/>}
                  />}
                />
                <Label
                    color={smileChecks.id.b}
                    basic
                    size="small"
                    content={smileChecks.id.m}/>
              </>}
            </Header.Subheader>
            {data.id_number??'-'} {showSmileChecks && <>/ {user.meta.smile_id_job?.callback_response?.IDNumber}</>}
          </Header.Content>
        </Header>
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column width={7}>
        <Header as='h4'>
          <Header.Content>
            <Header.Subheader>ID Document {data.id_document? <Button loading={load === 'id_document'} onClick={handleDocumentFront}>
                  {showDocumentFront?'Hide preview':'Show preview'}
            </Button>:''}</Header.Subheader>
            {data.id_document && showDocumentFront? <img src={documentFrontURL} width="100%" alt="document front"/>: data.id_document ?? '-'}
          </Header.Content>
        </Header>
      </Grid.Column>
      <Grid.Column width={7}>
        <Header as='h4'>
          <Header.Content>
            <Header.Subheader>ID Document Back {data.id_document_back? <Button loading={load === 'id_document_back'} onClick={handleDocumentBack}>
              {showDocumentBack?'Hide preview':'Show preview'}
            </Button>:''}</Header.Subheader>
            {data.id_document_back && showDocumentBack? <img src={documentBackURL} width="100%" alt="document back"/>: data.id_document_back ?? '-'}
          </Header.Content>
        </Header>
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column width={7}>
        <Header as='h4'>
          <Header.Content>
            <Header.Subheader>Selfie {data.selfie? <Button loading={load === 'selfie'} onClick={handleSelfie}>
              {showSelfie?'Hide preview':'Show preview'}
            </Button>:''}</Header.Subheader>
            {data.selfie && showSelfie? <img src={selfieURL} width="100%" alt="selfie"/>: data.selfie ?? '-'}
          </Header.Content>
        </Header>
      </Grid.Column>
      <Grid.Column width={7}>
        <Header as='h4'>
          <Header.Content>
            <Header.Subheader>Residential address</Header.Subheader>
            {data.residential_address ?? '-'}
          </Header.Content>
        </Header>
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column width={14}>
        <Header as='h4'>
          <Header.Content>
            <Header.Subheader>
              Kra Pin {data.kra_pin && <Popup content='Click to Copy' trigger={
              <Popup content='Copied to Clipboard' on="click" trigger={
                <Icon name="copy" onClick={() => navigator.clipboard.writeText(data.kra_pin)}/>}
              />}
            />}
              {showSmileChecks &&
                  <Label
                      color={smileChecks.pin.b}
                      basic
                      size="small"
                      content={smileChecks.pin.m}/>
              }
            </Header.Subheader>
            {data.kra_pin??'-'}
          </Header.Content>
        </Header>
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column width={14}>
        <Header as='h4'>
          <Header.Content>
            <Header.Subheader>KYC Reason</Header.Subheader>
            {kyc_state === 'verified' || !data.failure_reason ? '-': data.failure_reason}
          </Header.Content>
        </Header>
      </Grid.Column>
    </Grid.Row>
  </>
}

export default KYCData

