import React, {useContext, useEffect, useState} from 'react';
import {
  Button, Checkbox, Container, Divider, Dropdown, Form, Grid, Header, Image, Input, Label, Message, Modal, Tab
} from 'semantic-ui-react';
import TopBar from '../../util/topbar';
import ProductList from "./ProductList";
import SideModal from "../../util/SideModal";
import axios from "axios"
import Api from "../../util/api";
import {currencyOptions, recurrenceOptions} from "../../util/selectOptions";
import {remoteConfig} from "../../util/firebaseConfig"
import {
  currencyCode,
  editableColor,
  editableName,
  toReadableValue,
  toActualValue,
  // userCan, userLevels,
  NotifyCode, handleSetPopupMessage
} from "../../util/HelperFunctions";
import {UserContext} from "../../context/UserProvider";

const fileSize = 1024*1024*2
const fileSizeMessage = 'Image must be 2 mb (2048 KB) or less'
const Products = (props) => {
  const {user} = useContext(UserContext)
  const unsplash_endpoint = remoteConfig.getValue('unsplash_endpoint').asString()
  const unsplash_access_key = remoteConfig.getValue('unsplash_access_key').asString()
  const [modalOpen, setModalOpen] = useState(false)
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [viewModalOpen, setViewModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [index, setIndex] = useState(-1)
  const [id, setId] = useState('')
  const [goalName, setGoalName] = useState('')
  const [desc, setDesc] = useState('')
  const [category_ids, setCategory_ids] = useState([])
  const [name, setName] = useState({value: '', editable: false})
  const [target, setTarget] = useState({value: '', editable: false})
  const [deposits, setDeposits] = useState({value: '', editable: false})
  const [locked, setLocked] = useState({value: true, editable: false})
  const [currency_code, setCurrency_code] = useState({value: currencyOptions[0].value, editable: false})
  const [recurrence, setRecurrence] = useState({value: recurrenceOptions[0].value, editable: false})
  const [img_url, setImg_url] = useState({value: '', editable: false})
  const [vendor_id, setVendor_id] = useState({value: '', editable: false})
  const [products, setProducts] = useState(null)
  const [categories, setCategories] = useState(null)
  const [vendors, setVendors] = useState(null)
  const [submitLoader, setSubmitLoader] = useState(false)
  const [imgSearch, setImgSearch] = useState('')
  const [unsplashResults, setUnsplashResults] = useState([])
  const [unsplashResultsPage, setUnsplashResultsPage] = useState(1)
  const [unsplashResultsTotal, setUnsplashResultsTotal] = useState(0)
  const [enabled, setEnabled] = useState(false)
  const [searchImg, setSearchImg] = useState(false)
  const [networkError, setNetworkError] = useState(false)
  const [searchTable,setSearchTable] = useState("")
  const [product,setProduct] = useState(null)
  const [popUp, setPopUp] = useState('')
  const [popUpMessage, setPopUpMessage] = useState('')
  const [image, setImage] = useState(null)



  useEffect(() => {
    if (!products) {
      fetchProducts()
    }
    if (!categories) {
      fetchCategories()
    }
    if (!vendors) {
      fetchVendors()
    }
  },[])

  const fetchProducts = async () => {
    resetPopUp()
    try {
      const {data} = await Api.allProducts()
      if (data.result.products) {
        setProducts(data.result.products)
      } else {
        setProducts([])
      }
    } catch (err) {
      setProducts([])
     /* setNetworkError(true)
      console.log('err', err)*/
      handleSetPopupMessage(setPopUp,setPopUpMessage,resetPopUp,NotifyCode.FETCH_PRODUCTS.ERROR,err.data.error.message,-1)
    }
  }

  const fetchCategories = async () => {
    resetPopUp()
    try {
      const {data} = await Api.allCategories()
      if (data.result.categories && data.result.categories.length) {
        setCategories(data.result.categories)
        setCategory_ids([data.result.categories[0].id])
      } else {
        setCategories([])
      }
    } catch (err) {
      handleSetPopupMessage(setPopUp,setPopUpMessage,resetPopUp,NotifyCode.FETCH_CATEGORIES.ERROR,err.data.error.message,-1)
    }
  }

  const fetchVendors = async () => {
    resetPopUp()
    try {
      const {data} = await Api.allVendors()
      if (data.result.vendors && data.result.vendors.length) {
        setVendors(data.result.vendors)
        setVendor_id({...vendor_id,value: data.result.vendors[0].id})
      } else {
        setVendors([])
      }
    } catch (err) {
      handleSetPopupMessage(setPopUp,setPopUpMessage,resetPopUp,NotifyCode.FETCH_VENDORS.ERROR,err.data.error.message,-1)
    }
  }

  const openModal = () => {
    setModalOpen(true)
  }

  const handleClose = () => {
    setModalOpen(false)
  }

  const handleOpenEditModal = () => {
    setEditModalOpen(true)
    setFields()
  }

  const handleOpenCreateNewModal = () => {
    setFields()
    setModalOpen(true)
    handleCloseViewModal()
  }

  const handleCloseEditModal = () => {
    setEditModalOpen(false)
    resetFields()
  }

  const handleOpenViewModal = (index) => {
    setViewModalOpen(true)
    setIndex(index)
    setProduct(products[index])
  }

  const handleCloseViewModal = () => {
    setViewModalOpen(false)
    setIndex(-1)
    setProduct(null)
  }

  const handleOpenDeleteModal = (index) => {
    setDeleteModalOpen(true)
    setIndex(index)
    setId(product.id)
  }

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false)
    setIndex(-1)
    setId('')
    setProduct(null)
  }

  const resetFields = () => {
    setId('')
    setGoalName('')
    setDesc('')
    setCategory_ids(categories && categories.length ? [categories[0].id]:[])
    setVendor_id({value: vendors && vendors.length ? vendors[0].id : '', editable: false})
    setName({value: '', editable: false})
    setTarget({value: '', editable: false})
    setDeposits({value: '', editable: false})
    setLocked({value: true, editable: false})
    setCurrency_code({value: currencyOptions[0].value, editable: false})
    setRecurrence({value: recurrenceOptions[0].value, editable: false})
    setImg_url({value: '', editable: false})
    setImage(null)
  }

  const setFields = () => {
    setId(product.id)
    setEnabled(product.enabled)
    setGoalName(product.name)
    setDesc(product.description)
    setCategory_ids(product.category_ids)
    setVendor_id(product.data.vendor_id)
    setName(product.data.name)
    setTarget({value: toActualValue(product.data.target.value), editable: product.data.target.editable})
    setDeposits({value: toActualValue(product.data.deposits.value), editable: product.data.deposits.editable})
    setLocked(product.data.locked)
    setCurrency_code(product.data.currency_code)
    setRecurrence(product.data.recurrence)
    setImg_url(product.data.img_url)
  }

  const handleCreateSubmit = async (e) => {
    e.preventDefault()
    setSubmitLoader(true)
    try {
      let params = {
        name: goalName,
        desc: desc,
        // category_id: category_ids[0],
        category_ids,
        data: {
          currency_code: {...currency_code},
          img_url: {...img_url},
          vendor_id: {...vendor_id},
          locked: {...locked},
          recurrence: {...recurrence},
          name: {...name},
          target: {...target,value: Number(target.value) * 100},
          deposits: {...deposits,value: Number(deposits.value) * 100},
        }
      }
      // console.log(params)
      if(image){
        const {data} = await Api.upload({doc_type:'product_image',extension:getFileExtension(image)})
        await axios.put(data.result.signed_url,image)
        const {data: res} = await Api.uploadGetURL({doc_type:'product_image',key: data.result.key})
        params = {...params,data: {...params.data, img_url: {...params.data.img_url, value: res.result.download_url}}}
      }

      // console.log(params)
      const {data} = await Api.createProduct(params)
      setProducts([data.result.product, ...products])

      handleClose()
      resetFields()
    } catch (err) {
      handleSetPopupMessage(setPopUp,setPopUpMessage,resetPopUp,NotifyCode.CREATE_PRODUCT.ERROR,err.data.error.message,-1)
    }
    setSubmitLoader(false)
  }

  const handleEditSubmit = async (e) => {
    e.preventDefault()
    setSubmitLoader(true)
    resetPopUp()
    try {
      const params = {
        id,
        enabled,
        name: goalName,
        desc: desc,
        category_ids,
        /*data: {
          vendor_id: {...vendor_id}
        }*/
      }
      const {data} = await Api.updateProduct(params)
      setProducts([...products.map(item => item.id === data.result.product.id ? {...data.result.product} : item)])
      handleCloseEditModal()
    } catch (err) {
      handleSetPopupMessage(setPopUp,setPopUpMessage,resetPopUp,NotifyCode.UPDATE_PRODUCT.ERROR,err.data.error.message,-1)
    }
    setSubmitLoader(false)
  }

  const handleDeleteSubmit = async (e) => {
    e.preventDefault()
    setSubmitLoader(true)
    try {
      const params = {id}
      const {data} = await Api.deleteProduct(params)
      if (data.result.status) {
        setProducts([...products.filter(item => item.id !== product.id)])
      }
      handleCloseDeleteModal()
    } catch (err) {
      handleSetPopupMessage(setPopUp,setPopUpMessage,resetPopUp,NotifyCode.DELETE_PRODUCT.ERROR,err.data.error.message,-1)
    }
    setSubmitLoader(false)
  }

  const searchImage = async (event, page = 1) => {
    event.preventDefault()
    event.persist()
    setSearchImg(true)
    try {
      const res = await axios.get(unsplash_endpoint, {
        params: {
          query: imgSearch,
          page,
          client_id: unsplash_access_key
        },
      })
      if (page === 1) {
        setUnsplashResults(res.data.results)
        setUnsplashResultsTotal(res.data.total)
      } else {
        setUnsplashResults([...unsplashResults, ...res.data.results])
      }
      setUnsplashResultsPage(page)
    } catch (err) {
      console.log(err)
    }
    setSearchImg(false)
  }

  const refreshBtn = () => {
    if(popUp === NotifyCode.FETCH_PRODUCTS.ERROR){
      setProducts(null)
      fetchProducts()
    }
    fetchCategories()
    fetchVendors()
  }

  const resetPopUp = () =>{
    setPopUp('')
    setPopUpMessage('')
  }

  const handleSetImage = (file)=>{
    resetPopUp()

    if(file.size <= fileSize){
      setImage(file)
    }
    else{
      handleSetPopupMessage(setPopUp,setPopUpMessage,resetPopUp,NotifyCode.CREATE_PRODUCT.ERROR,fileSizeMessage,-1)
    }

  }

  const getFileExtension = (file)=>{
    return file.name.split('.').pop()
  }

  const panes = [
    {
      menuItem: 'Product List',
      render: () => <Tab.Pane loading={products === null}>
        {(popUp === NotifyCode.FETCH_CATEGORIES.ERROR || popUp === NotifyCode.FETCH_VENDORS.ERROR) && <Grid centered>
          <Grid.Row>
            <Grid.Column width={14}>
              <Message negative>
                <Message.Header>{popUpMessage}</Message.Header>
              </Message>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={14}>
              <Button secondary onClick={refreshBtn} icon="redo" content="Refresh" size="tiny" style={{marginLeft: 10}}/>
            </Grid.Column>
          </Grid.Row>
        </Grid>}
        {(popUp === NotifyCode.FETCH_PRODUCTS.ERROR) ? <Grid centered>
          <Grid.Row>
            <Grid.Column width={14}>
              <Message negative>
                <Message.Header>{popUpMessage}</Message.Header>
              </Message>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={14}>
              <Button secondary onClick={refreshBtn} icon="redo" content="Refresh" size="tiny" style={{marginLeft: 10}}/>
            </Grid.Column>
          </Grid.Row>
        </Grid>: <>
          <Grid centered>
            <Grid.Row>
              <Grid.Column width={9} floated="left">
                <div style={{display: 'flex', width: '100%'}}>
                  <Input label="Filter" placeholder="Search" value={searchTable}
                         onChange={(e) => setSearchTable(e.target.value)}
                         style={{width: '100%', marginRight: 3}}
                  />
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <ProductList data={products} handleView={handleOpenViewModal} searchTable={searchTable}/>
        </> }
          {/*{!networkError ?
            <>
            <Grid centered>
              <Grid.Row>
                <Grid.Column width={9} floated="left">
                  <div style={{display: 'flex', width: '100%'}}>
                    <Input label="Filter" placeholder="Search" value={searchTable}
                           onChange={(e) => setSearchTable(e.target.value)}
                           style={{width: '100%', marginRight: 3}}
                    />
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
              <ProductList data={products} handleView={handleOpenViewModal} searchTable={searchTable}/>
            </> :
            <Container text textAlign="center" style={{height: "37vh"}}>
              Network error
              <Button secondary onClick={refreshBtn} icon="redo" content="Refresh" size="tiny" style={{marginLeft: 10}}/>
            </Container>
          }*/}
      </Tab.Pane>
    },
  ]

  return (
    <>
      <TopBar
        name={props.title}
        actions={
          // userCan(user.access, userLevels.CREATE_PRODUCT) &&
          [
            {render: () => <Button basic color="blue" content="Create Product" onClick={openModal}/>},
          ]
        }/>
      <Tab panes={panes}/>
      <SideModal
        open={modalOpen}
        onClose={handleClose}
        title="Create Product"
      >
        <Form onSubmit={handleCreateSubmit}>
          <Grid centered>
            {(popUp === NotifyCode.CREATE_PRODUCT.ERROR) && <Grid.Row>
              <Grid.Column width={14}>
                <Message negative>
                  <Message.Header>{popUpMessage}</Message.Header>
                </Message>
              </Grid.Column>
            </Grid.Row>}
            <Grid.Row>
              <Grid.Column width={12}>
                <Form.Field>
                  <label>Goal Name</label>
                  <input placeholder='Create your own goal' value={goalName} required
                         onChange={event => setGoalName(event.target.value)}/>
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={12}>
                <Form.Field>
                  <label>Goal Description (Optional)</label>
                  <textarea placeholder='eg: Most popular goals' onChange={event => setDesc(event.target.value)}
                            value={desc} rows={3}/>
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={12}>
                <Form.Field>
                  <label>Categories</label>
                  <Dropdown
                    placeholder='Category'
                    fluid
                    selection
                    multiple
                    search
                    value={category_ids}
                    options={categories && categories ?
                      [...categories.map(item => ({key: item.id, text: item.name, value: item.id}))]
                      : []}
                    onChange={(event, {value}) => setCategory_ids(value)}
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={12}>
                <Form.Field>
                  <label>Vendor</label>
                  <Dropdown
                    placeholder='Vendor'
                    fluid
                    selection
                    value={vendor_id.value}
                    options={vendors && vendors ?
                      [...vendors.map(item => ({key: item.id, text: item.name, value: item.id}))]
                      : []}
                    onChange={(event, {value}) => setVendor_id({...vendor_id, value})}
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column width={12} style={{marginTop: 7}}>
                <Form.Field>
                  <Checkbox label='Editable' checked={vendor_id.editable}
                            onChange={(event, {checked}) =>
                              setVendor_id({...vendor_id, editable: checked})
                            }/>
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={12}>
                <Form.Field>
                  <label>Name</label>
                  <input placeholder='eg: Pension Plan' value={name.value} required
                         onChange={event => setName({...name, value: event.target.value})}/>
                </Form.Field>
              </Grid.Column>
              <Grid.Column width={12} style={{marginTop: 7}}>
                <Form.Field>
                  <Checkbox label='Editable' checked={name.editable}
                            onChange={(event, {checked}) =>
                              setName({...name, editable: checked})
                            }/>
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={9}>
                <Form.Field>
                  <label>Image search</label>
                  <input placeholder='eg: house' value={imgSearch} disabled={searchImg}
                         onChange={event => setImgSearch(event.target.value)}
                         onKeyDown={event => event.key === "Enter" && event.target.value !== ""? searchImage(event) : null}
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column width={3}>
                <Button onClick={(event) => searchImage(event)} loading={searchImg}
                        disabled={searchImg || imgSearch.length === 0} style={{marginTop: 25}}>
                  Search</Button>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={12}>
                Search results {unsplashResults.length > 0 ? <strong>Click to select</strong> : ''}
              </Grid.Column>
              <Grid.Column width={12}>
                <Image.Group size="small">
                  {unsplashResults.map(item =>
                    <Image key={item.id} src={item.urls.regular}
                           onClick={() => setImg_url({...img_url, value: item.urls.regular})}
                           style={{border: `3px solid ${img_url.value === item.urls.regular ? 'green' : 'transparent'}`}}
                    />
                  )}
                </Image.Group>
                {unsplashResults.length > 0 && unsplashResults.length < unsplashResultsTotal ?
                  <Button onClick={(event) => searchImage(event, unsplashResultsPage + 1)}
                          style={{marginTop: 25}}>Load more</Button> : ''
                }
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={12}>
                <Form.Field>
                  <label>Image Url</label>
                  <input placeholder='https://123.com/vehical.jpg' value={img_url.value} required={!image}
                         onChange={(e) =>
                      setImg_url({...img_url, value: e.target.value})
                  }/>
                </Form.Field>
              </Grid.Column>
              <Grid.Column width={12} style={{marginTop: 7}}>
                <Form.Field>
                  <Checkbox label='Editable' checked={img_url.editable}
                            onChange={(event, {checked}) =>
                              setImg_url({...img_url, editable: checked})
                            }/>
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={12}>
                <Form.Field>
                  <label>Upload Image (Optional)</label>
                  {!!image &&<>
                    <p>Preview</p>
                    <Image src={URL.createObjectURL(image)} size="medium"/>
                    <Divider hidden/>
                  </>}
                  <Input type="file" accept="image/jpg,image/jpeg,image/png" required={img_url.value.length < 1}
                         onChange={(e)=>handleSetImage(e.target.files[0])}/>
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={12}>
                <Form.Field>
                  <label>Locked</label>
                  <Dropdown
                    placeholder='Locked'
                    fluid
                    selection
                    value={locked.value}
                    options={[
                      {key: 'true', text: 'True', value: true},
                      {key: 'false', text: 'False', value: false},
                    ]}
                    onChange={(event, {value}) => setLocked({...locked, value})}
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column width={12} style={{marginTop: 7}}>
                <Form.Field>
                  <Checkbox label='Editable' checked={locked.editable}
                            onChange={(event, {checked}) =>
                              setLocked({...locked, editable: checked})
                            }/>
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={12}>
                <Form.Field>
                  <label>Recurrence</label>
                  <Dropdown
                    placeholder='Recurrence'
                    fluid
                    selection
                    value={recurrence.value}
                    options={recurrenceOptions}
                    onChange={(event, {value}) => setRecurrence({...recurrence, value})}
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column width={12} style={{marginTop: 7}}>
                <Form.Field>
                  <Checkbox label='Editable' checked={recurrence.editable}
                            onChange={(event, {checked}) =>
                              setRecurrence({...recurrence, editable: checked})
                            }/>
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={12}>
                <Form.Field>
                  <label>Currency Code</label>
                  <Dropdown
                    placeholder='Currency Code'
                    fluid
                    selection
                    value={currency_code.value}
                    options={currencyOptions}
                    onChange={(event, {value}) => setCurrency_code({...currency_code, value})}
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column width={12} style={{marginTop: 7}}>
                <Form.Field>
                  <Checkbox label='Editable' checked={currency_code.editable}
                            onChange={(event, {checked}) =>
                              setCurrency_code({...currency_code, editable: checked})
                            }/>
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={12}>
                <Form.Field>
                  <label>Target</label>
                  <input type="number" placeholder='eg: 5000' value={target.value} required
                         onChange={event => setTarget({...target, value: event.target.value})}/>
                </Form.Field>
              </Grid.Column>
              <Grid.Column width={12} style={{marginTop: 7}}>
                <Form.Field>
                  <Checkbox label='Editable' checked={target.editable}
                            onChange={(event, {checked}) =>
                              setTarget({...target, editable: checked})
                            }/>
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={12}>
                <Form.Field>
                  <label>Deposit</label>
                  <input type="number" placeholder='eg: 300' value={deposits.value} required
                         onChange={event => setDeposits({...deposits, value: event.target.value})}/>
                </Form.Field>
              </Grid.Column>
              <Grid.Column width={12} style={{marginTop: 7}}>
                <Form.Field>
                  <Checkbox label='Editable' checked={deposits.editable}
                            onChange={(event, {checked}) =>
                              setDeposits({...deposits, editable: checked})
                            }/>
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Button type='submit' loading={submitLoader} disabled={submitLoader}>Submit</Button>
          </Grid>
        </Form>
      </SideModal>
      {!!(products && products.length && categories && vendors && product !== null) &&
        <>
          <SideModal
            open={editModalOpen}
            onClose={handleCloseEditModal}
            title="Edit Product"
          >
            <Form onSubmit={handleEditSubmit}>
              <Grid centered>
                {(popUp === NotifyCode.UPDATE_PRODUCT.ERROR) &&
                    <Grid.Row>
                      <Grid.Column width={14}>
                        <Message negative>
                          <Message.Header>{popUpMessage}</Message.Header>
                        </Message>
                      </Grid.Column>
                    </Grid.Row>}
                <Grid.Row>
                  <Grid.Column width={12} style={{marginTop: 7}}>
                    <Form.Field>
                      <label>Enabled Product</label>
                      <Dropdown
                          placeholder='Enabled Product'
                          fluid
                          selection
                          value={enabled}
                          options={[
                            {key: 'true', text: 'Yes', value: true},
                            {key: 'false', text: 'No', value: false},
                          ]}
                          onChange={(event, {value}) => setEnabled(value)}
                      />
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={12}>
                    <Form.Field>
                      <label>Goal Name</label>
                      <input placeholder='eg: Create your own goal' value={goalName} required
                             onChange={event => setGoalName(event.target.value)}/>
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={12}>
                    <Form.Field>
                      <label>Goal Description (Optional)</label>
                      <textarea placeholder='eg: Most popular goals'
                                onChange={event => setDesc(event.target.value)} value={desc} rows={3}/>
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={12}>
                    <Form.Field>
                      <label>Categories</label>
                      <Dropdown
                        placeholder='Category'
                        fluid
                        selection
                        multiple
                        search
                        value={category_ids}
                        options={categories && categories ?
                          [...categories.map(item => item ? {key: item.id, text: item.name, value: item.id} : {})]
                          : []}
                        onChange={(event, {value}) =>setCategory_ids(value)}
                      />
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                {/*<Grid.Row>
                  <Grid.Column width={12}>
                    <Form.Field>
                      <label>Vendor</label>
                      <Dropdown
                        placeholder='Vendor'
                        fluid
                        selection
                        value={vendor_id.value}
                        options={vendors && vendors ?
                          [...vendors.map(item => ({key: item.id, text: item.name, value: item.id}))]
                          : []}
                        onChange={(event, {value}) => setVendor_id({...vendor_id, value})}
                      />
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column width={12} style={{marginTop: 7}}>
                    <Form.Field>
                      <Checkbox label='Editable' checked={vendor_id.editable}
                                onChange={(event, {checked}) =>
                                  setVendor_id({...vendor_id, editable: checked})
                                }/>
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={12}>
                    <Form.Field>
                      <label>Name</label>
                      <input placeholder='eg: Pension Plan' value={name.value} required
                             onChange={event => setName({...name, value: event.target.value})}/>
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column width={12} style={{marginTop: 7}}>
                    <Form.Field>
                      <Checkbox label='Editable' checked={name.editable}
                                onChange={(event, {checked}) =>
                                  setName({...name, editable: checked})
                                }/>
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={12}>
                    <Form.Field>
                      <label>Currency Code</label>
                      <Dropdown
                        placeholder='Currency Code'
                        fluid
                        selection
                        value={currency_code.value}
                        options={currencyOptions}
                        onChange={(event, {value}) => setCurrency_code({...currency_code, value})}
                      />
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column width={12} style={{marginTop: 7}}>
                    <Form.Field>
                      <Checkbox label='Editable' checked={currency_code.editable}
                                onChange={(event, {checked}) =>
                                  setCurrency_code({...currency_code, editable: checked})
                                }/>
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={12}>
                    <Form.Field error={errorImg}>
                      <label>Image Url</label>
                      <input placeholder='eg: https://example.com/image.jpg' value={img_url.value} required
                             onChange={event => handleImgUrl(event)}/>
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Row centered style={{display: previewImg ? '' : "none"}}>
                    <label>Preview</label>
                    <Image src={img_url.value} style={{width: 200, height: 200}}/>
                  </Grid.Row>
                  <Grid.Column width={12} style={{marginTop: 7}}>
                    <Form.Field>
                      <Checkbox label='Editable' checked={img_url.editable}
                                onChange={(event, {checked}) =>
                                  setImg_url({...img_url, editable: checked})
                                }/>
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={12}>
                    <Form.Field>
                      <label>Locked</label>
                      <Dropdown
                        placeholder='Locked'
                        fluid
                        selection
                        value={locked.value}
                        options={[
                          {key: 'true', text: 'True', value: true},
                          {key: 'false', text: 'False', value: false},
                        ]}
                        onChange={(event, {value}) => setLocked({...locked, value})}
                      />
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column width={12} style={{marginTop: 7}}>
                    <Form.Field>
                      <Checkbox label='Editable' checked={locked.editable}
                                onChange={(event, {checked}) =>
                                  setLocked({...locked, editable: checked})
                                }/>
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={12}>
                    <Form.Field>
                      <label>Recurrence</label>
                      <Dropdown
                        placeholder='Recurrence'
                        fluid
                        selection
                        value={recurrence.value}
                        options={recurrenceOptions}
                        onChange={(event, {value}) => setRecurrence({...recurrence, value})}
                      />
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column width={12} style={{marginTop: 7}}>
                    <Form.Field>
                      <Checkbox label='Editable' checked={recurrence.editable}
                                onChange={(event, {checked}) =>
                                  setRecurrence({...recurrence, editable: checked})
                                }/>
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={12}>
                    <Form.Field>
                      <label>Target</label>
                      <input type="number" placeholder='eg: 5000' value={target.value} required
                             onChange={event => setTarget({...target, value: Number(event.target.value)})}/>
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column width={12} style={{marginTop: 7}}>
                    <Form.Field>
                      <Checkbox label='Editable' checked={target.editable}
                                onChange={(event, {checked}) =>
                                  setTarget({...target, editable: checked})
                                }/>
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={12}>
                    <Form.Field>
                      <label>Deposit</label>
                      <input type="number" placeholder='eg: 300' value={deposits.value} required
                             onChange={event => setDeposits({...deposits, value:Number(event.target.value)})}/>
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column width={12} style={{marginTop: 7}}>
                    <Form.Field>
                      <Checkbox label='Editable' checked={deposits.editable}
                                onChange={(event, {checked}) =>
                                  setDeposits({...deposits, editable: checked})
                                }/>
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>*/}
                <Button type='submit' loading={submitLoader} disabled={submitLoader}>Submit</Button>
              </Grid>
            </Form>
          </SideModal>
          <Modal
            size="mini"
            open={deleteModalOpen}
            onClose={()=>setDeleteModalOpen(false)}
          >
            <Modal.Header>{`Deleting ${product.name}`}</Modal.Header>
            <Modal.Content>
              {(popUp === NotifyCode.DELETE_PRODUCT.ERROR) &&
                      <Message negative>
                        <Message.Header>{popUpMessage}</Message.Header>
                      </Message>}
              <p>Are you sure?</p>
            </Modal.Content>
            <Modal.Actions>
              <Button negative disabled={submitLoader} onClick={()=>setDeleteModalOpen(false)}>
                No
              </Button>
              <Button positive loading={submitLoader} disabled={submitLoader} onClick={handleDeleteSubmit}>
                Yes
              </Button>
            </Modal.Actions>
          </Modal>
          <SideModal
            open={viewModalOpen}
            onClose={handleCloseViewModal}
            title="Product Details"
            width={editModalOpen?80:60}
          >
            <Grid centered>
              <Grid.Row textAlign="right">
                <Grid.Column width={8} floated="right">
                  {/*{ userCan(user.access, userLevels.CREATE_NEW_PRODUCT) &&*/}
                      <Button icon="recycle" content="Create New" size="mini" color="yellow" onClick={handleOpenCreateNewModal}/>
                  {/*}*/}
                  {/*{ userCan(user.access, userLevels.EDIT_PRODUCT) && */}
                      <Button icon="pencil" content='Edit' size="mini" color="blue" onClick={handleOpenEditModal}/>
                  {/*}*/}
                  {/*{ userCan(user.access, userLevels.DELETE_PRODUCT) &&*/}
                      <Button icon="trash" content='Delete' size="mini" color="red" onClick={handleOpenDeleteModal}/>
                  {/*}*/}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row centered>
                <Image src={product.data.img_url.value} style={{height: 200}}/>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={12}>
                  <Header as='h4'>
                    <Header.Content>
                      <Header.Subheader>Enabled</Header.Subheader>
                      <Label color={product.enabled?'green':'red'} size="medium">
                        {product.enabled?'True':'False'}
                      </Label>
                    </Header.Content>
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={12}>
                  <Header as='h4'>
                    <Header.Content>
                      <Header.Subheader>Goal name</Header.Subheader>
                      {product.name}
                    </Header.Content>
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={12}>
                  <Header as='h4'>
                    <Header.Content>
                      <Header.Subheader>Goal Description</Header.Subheader>
                      {product.description ?? '-'}
                    </Header.Content>
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={12}>
                  <Header as='h4'>
                    <Header.Content>
                      <Header.Subheader>Category</Header.Subheader>
                      {product.category_ids.map((e,i) =>
                        <Label key={i} color="grey" size="large" basic
                               content={categories.find(item => item.id === e).name}/>
                      )}
                    </Header.Content>
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={12}>
                  <Header as='h4'>
                    <Header.Content>
                      <div style={{display: 'flex'}}>
                        <Header.Subheader>Vendor</Header.Subheader>
                        <Label color={editableColor(product.data.vendor_id.editable)} size="mini">
                          {editableName(product.data.vendor_id.editable)}
                        </Label>
                      </div>
                      {product.data.vendor_id.value ? vendors.find(item => item.id === product.data.vendor_id.value).name : '-'}
                    </Header.Content>
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={12}>
                  <Header as='h4'>
                    <Header.Content>
                      <div style={{display: 'flex'}}>
                        <Header.Subheader>Name</Header.Subheader>
                        <Label color={editableColor(product.data.name.editable)} size="mini">
                          {editableName(product.data.name.editable)}
                        </Label>
                      </div>
                      {product.data.name.value}
                    </Header.Content>
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={6}>
                  <Header as='h4'>
                    <Header.Content>
                      <div style={{display: 'flex'}}>
                        <Header.Subheader>Locked</Header.Subheader>
                        <Label color={editableColor(product.data.locked.editable)} size="mini">
                          {editableName(product.data.locked.editable)}
                        </Label>
                      </div>
                      {product.data.locked.value ? 'True' : 'False'}
                    </Header.Content>
                  </Header>
                </Grid.Column>
                <Grid.Column width={6}>
                  <Header as='h4'>
                    <Header.Content>
                      <div style={{display: 'flex'}}>
                        <Header.Subheader>Recurrence</Header.Subheader>
                        <Label color={editableColor(product.data.recurrence.editable)} size="mini">
                          {editableName(product.data.recurrence.editable)}
                        </Label>
                      </div>
                      {product.data.recurrence.value}
                    </Header.Content>
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={6}>
                  <Header as='h4'>
                    <Header.Content>
                      <div style={{display: 'flex'}}>
                        <Header.Subheader>Target</Header.Subheader>
                        <Label color={editableColor(product.data.target.editable)} size="mini">
                          {editableName(product.data.target.editable)}
                        </Label>
                      </div>
                      {toReadableValue(product.data.target.value,product.data.currency_code.value)} {currencyCode(product.data.currency_code.value)}
                    </Header.Content>
                  </Header>
                </Grid.Column>
                <Grid.Column width={6}>
                  <Header as='h4'>
                    <Header.Content>
                      <div style={{display: 'flex'}}>
                        <Header.Subheader>Deposits</Header.Subheader>
                        <Label color={editableColor(product.data.deposits.editable)} size="mini">
                          {editableName(product.data.deposits.editable)}
                        </Label>
                      </div>
                      {toReadableValue(product.data.deposits.value,product.data.currency_code.value)} {currencyCode(product.data.currency_code.value)}
                    </Header.Content>
                  </Header>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </SideModal>
        </>
      }
    </>
  );
}

export default Products;

