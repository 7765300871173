import React from "react";
import {Button, Divider, Grid, Header, Label} from "semantic-ui-react";
import {toReadableDateTime, userCan, userLevels} from "../util/HelperFunctions";

function UserSmileData({user,currentUser,job_status,submitLoader,handleOpenReEnrollModel,handleJobStatus}){
    return <>
        <Grid.Row textAlign="right">
            <Grid.Column width={user.kyc_state !== "verified" && !!user.meta.smile_id_job.koa_job_id ? 7:5} floated="right">
                {(user.kyc_state !== "verified" && userCan(currentUser.access, userLevels.ENROLL_USER_SMILE)) && <Button icon="check circle" content='Re-enroll' size="small" color="yellow"
                                                                                                                         disabled={submitLoader} onClick={(e)=>handleOpenReEnrollModel()}/>}
                {(!!user.meta.smile_id_job.koa_job_id && userCan(currentUser.access, userLevels.CHECK_USER_SMILE)) &&<Button icon="check circle" content='Check Status'
                                                                                                                             size="small" color="grey" loading={submitLoader}
                                                                                                                             disabled={submitLoader} onClick={(e)=>handleJobStatus(e,user)}/>}
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column width={14}>
                <Header as='h5'>
                    <Header.Content>
                        <Header.Subheader>Verification Request Date</Header.Subheader>
                        {toReadableDateTime(user.meta.smile_id_job.verification_request_date)}
                    </Header.Content>
                </Header>
            </Grid.Column>
        </Grid.Row>
        {!!user.meta.smile_id_job.ref_id && <Grid.Row>
            <Grid.Column width={14}>
                <Header as='h5'>
                    <Header.Content>
                        <Header.Subheader>Reference ID</Header.Subheader>
                        {user.meta.smile_id_job.ref_id}
                    </Header.Content>
                </Header>
            </Grid.Column>
        </Grid.Row>}
        {!!user.meta.smile_id_job.smile_job_id && <Grid.Row>
            <Grid.Column width={14}>
                <Header as='h5'>
                    <Header.Content>
                        <Header.Subheader>Smile Job ID</Header.Subheader>
                        {user.meta.smile_id_job.smile_job_id}
                    </Header.Content>
                </Header>
            </Grid.Column>
        </Grid.Row>}
        {!!user.meta.smile_id_job.koa_job_id && <Grid.Row>
            <Grid.Column width={14}>
                <Header as='h5'>
                    <Header.Content>
                        <Header.Subheader>KOA Job ID</Header.Subheader>
                        {user.meta.smile_id_job.koa_job_id}
                    </Header.Content>
                </Header>
            </Grid.Column>
        </Grid.Row>}
        <Divider hidden/>
        <Grid.Row>
            <Grid.Column width={14}>
                <Header as='h4'>
                    <Header.Content>
                        <Header.Subheader>Raw Smile Mete Data</Header.Subheader>
                        <pre>{JSON.stringify(user.meta.smile_id_job,null,2)}</pre>
                    </Header.Content>
                </Header>
            </Grid.Column>
        </Grid.Row>
        {!!job_status && <>
            <Grid.Row>
                <Grid.Column width={14}>
                    <Header as='h3' color="teal" content="Job Status" />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={14}>
                    <Header as='h5'>
                        <Header.Content>
                            <Header.Subheader>Smile Job ID</Header.Subheader>
                            {job_status.result.SmileJobID}
                        </Header.Content>
                    </Header>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={7}>
                    <Header as='h5'>
                        <Header.Content>
                            <Header.Subheader>Job Complete</Header.Subheader>
                            <Label color={job_status.job_complete? 'green':'red'} content={job_status.job_complete? 'TRUE':'FALSE'}/>
                        </Header.Content>
                    </Header>
                </Grid.Column>
                <Grid.Column width={7}>
                    <Header as='h5'>
                        <Header.Content>
                            <Header.Subheader>Job Success</Header.Subheader>
                            <Label color={job_status.job_success? 'green':'red'} content={job_status.job_success? 'TRUE':'FALSE'}/>
                        </Header.Content>
                    </Header>
                </Grid.Column>
            </Grid.Row>
            {
                job_status.history.length > 0 && job_status.history.map((item,i)=><React.Fragment key={i}>
                    <Grid.Row>
                        <Grid.Column width={14}>
                            <Header as="h4" content={`History ${i+1}`}/>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={7}>
                            <Header as='h5'>
                                <Header.Content>
                                    <Header.Subheader>Return Personal Info</Header.Subheader>
                                    {item.Actions.Return_Personal_Info}
                                </Header.Content>
                            </Header>
                        </Grid.Column>
                        <Grid.Column width={7}>
                            <Header as='h5'>
                                <Header.Content>
                                    <Header.Subheader>Verify ID Number</Header.Subheader>
                                    {item.Actions.Verify_ID_Number}
                                </Header.Content>
                            </Header>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={7}>
                            <Header as='h5'>
                                <Header.Content>
                                    <Header.Subheader>Country</Header.Subheader>
                                    {item.Country}
                                </Header.Content>
                            </Header>
                        </Grid.Column>
                        <Grid.Column width={7}>
                            <Header as='h5'>
                                <Header.Content>
                                    <Header.Subheader>ID Number</Header.Subheader>
                                    {item.IDNumber}
                                </Header.Content>
                            </Header>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={7}>
                            <Header as='h5'>
                                <Header.Content>
                                    <Header.Subheader>ID Number Previously Registered</Header.Subheader>
                                    {item.IDNumberPreviouslyRegistered}
                                </Header.Content>
                            </Header>
                        </Grid.Column>
                        <Grid.Column width={7}>
                            <Header as='h5'>
                                <Header.Content>
                                    <Header.Subheader>ID Type</Header.Subheader>
                                    {item.IDType}
                                </Header.Content>
                            </Header>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={14}>
                            <Header as='h5'>
                                <Header.Content>
                                    <Header.Subheader>Is Final Result</Header.Subheader>
                                    {item.IsFinalResult}
                                </Header.Content>
                            </Header>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={7}>
                            <Header as='h5'>
                                <Header.Content>
                                    <Header.Subheader>Result Code</Header.Subheader>
                                    {item.ResultCode}
                                </Header.Content>
                            </Header>
                        </Grid.Column>
                        <Grid.Column width={7}>
                            <Header as='h5'>
                                <Header.Content>
                                    <Header.Subheader>Result Type</Header.Subheader>
                                    {item.ResultType}
                                </Header.Content>
                            </Header>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={14}>
                            <Header as='h5'>
                                <Header.Content>
                                    <Header.Subheader>ResultText</Header.Subheader>
                                    {item.ResultText}
                                </Header.Content>
                            </Header>
                        </Grid.Column>
                    </Grid.Row>
                </React.Fragment>)
            }
            <Grid.Row>
                <Grid.Column width={14}>
                    <Header as='h5'>
                        <Header.Content>
                            <Header.Subheader>Date</Header.Subheader>
                            {toReadableDateTime(job_status.timestamp)}
                        </Header.Content>
                    </Header>
                </Grid.Column>
            </Grid.Row>
        </>}
    </>
}

export default UserSmileData