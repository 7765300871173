import React, {useState} from 'react';

import {Button, Form, Header, Message, Segment} from 'semantic-ui-react';
import Api from '../../util/api';

const RequestPassword = (props) => {
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [matches, setMatches] = useState(false)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!matches) {
      return;
    }
    setLoading(true)
    try {
      const query = new URLSearchParams(props.location.search);
      await Api.requestPasswordChange({
        password: password,
        token: query.get('token'),
      });
      setLoading(false)
      props.history.push('/login');
    } catch (ex) {
      setLoading(false)
      setError('Server Error: plese try again later')
    }
  }

  const handleChange = (e, d) => {
    if (d.name === 'password') {
      setPassword(d.value);
    } else if (d.name === 'confirmPassword') {
      setConfirmPassword(d.value);
      if (d.value.length !== 0 && d.value === password) {
        setMatches(true);
      } else {
        setMatches(false);
      }
    }
  }

    return (
      <>
        <Segment style={{width: 400, margin: '100px auto'}}>
          <Header content={props.title}/>

          {error &&
          <Message error>
            Something went wrong. Please try again later.
          </Message>}

          <Form onSubmit={handleSubmit} loading={loading}>
            <Form.Input
              value={password}
              onChange={handleChange}
              name="password"
              placeholder="Password"
              type="password"
              requried
            />
            <Form.Input
              value={confirmPassword}
              error={!matches}
              onChange={handleChange}
              name="confirmPassword"
              placeholder="Confirm Password"
              type="password"
              requried
            />
            <Button color="blue" type="submit">Submit</Button>
            <br/>
            <br/>
            <a href>Back to Login</a>
          </Form>
        </Segment>
      </>
    );
}

export default RequestPassword;
