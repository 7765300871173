import React from "react";
import {Button, Grid, Header, Icon, Image, Label, Popup} from "semantic-ui-react";
import {
  currencyCode,
  getUserFirstName,
  getUserLastName, getUserMiddleName,
  KYCStateColor,
  KYCStateName,
  toReadableDateTime, toReadableValue,
  userCan, userLevels,
  yesOrNoColor,
  yesOrNoName
} from "../util/HelperFunctions"

function UserProfile({data,currentUser,showSmileChecks,smileChecks,handleShowSmileChecks,vendors,isGroup = false}){
  return <>
    <Grid.Row centered>
      <Image src={data.photo_url} circular style={{width: 200, height: 200}}/>
    </Grid.Row>
    <Grid.Row textAlign="right">
      <Grid.Column
          width={14} floated="right">
        { (userCan(currentUser.access, userLevels.VIEW_SMILE_CHECKS) && data.meta.smile_id_job && data.meta.smile_id_job.callback_response) &&
            <Button icon="eye" content={`${showSmileChecks? 'Hide':'Show'} Smile Check`} size="small" color={showSmileChecks? 'yellow':'blue'} onClick={()=>handleShowSmileChecks(data,!showSmileChecks)}/>}
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column width={isGroup ? 14 : 7}>
        <Header as='h4'>
          <Header.Content>
            <Header.Subheader>
              ID <Popup content='Click to Copy' trigger={
              <Popup content='Copied to Clipboard' on="click" trigger={
                <Icon name="copy" onClick={()=>navigator.clipboard.writeText(data.id)}/>}
              />}
            />
            </Header.Subheader>
            {data.id} { data.meta.is_v2_user && <Label
              color="teal"
              size="medium"
              content="COOP"/>}
          </Header.Content>
        </Header>
      </Grid.Column>
      {!isGroup && <Grid.Column width={7}>
        <Header as='h4'>
          <Header.Content>
            <Header.Subheader>
              Britam ID {(data.meta && data.meta.britam && data.meta.britam.britam_user_id) && <Popup content='Click to Copy' trigger={
              <Popup content='Copied to Clipboard' on="click" trigger={
                <Icon name="copy"
                      onClick={() => navigator.clipboard.writeText(data.meta.britam.britam_user_id)}/>}
              />}
            />}
            </Header.Subheader>
            {data.meta && data.meta.britam && data.meta.britam.britam_user_id ? data.meta.britam.britam_user_id : '-'}
          </Header.Content>
        </Header>
      </Grid.Column>}
    </Grid.Row>
    {isGroup ? <>
      <Grid.Row>
        <Grid.Column width={7}>
          <Header as='h4'>
            <Header.Content>
              <Header.Subheader>
                Name
              </Header.Subheader>
              {data.name}
            </Header.Content>
          </Header>
        </Grid.Column>
        <Grid.Column width={7}>
          <Header as='h4'>
            <Header.Content>
              <Header.Subheader>
                Reference
              </Header.Subheader>
              {data.email}
            </Header.Content>
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={7}>
          <Header as='h4'>
            <Header.Content>
              <Header.Subheader>
                Target amount
              </Header.Subheader>
              {toReadableValue(data.meta?.group_details?.target_amount ?? 0)} {currencyCode()}
            </Header.Content>
          </Header>
        </Grid.Column>
        <Grid.Column width={7}>
          <Header as='h4'>
            <Header.Content>
              <Header.Subheader>
                Target Date
              </Header.Subheader>
              {data.meta?.group_details?.target_date ? new Date(data.meta?.group_details?.target_date).toDateString() : '-'}
            </Header.Content>
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={14}>
          <Header as='h4'>
            <Header.Content>
              <Header.Subheader>Description</Header.Subheader>
              {data.meta?.group_details?.description ?? '-'}
            </Header.Content>
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={14}>
          <Header as='h4'>
            <Header.Content>
              <Header.Subheader>
                Invitation link {data.meta?.group_details?.group_invite_link && <Popup content='Click to Copy' trigger={
                <Popup content='Copied to Clipboard' on="click" trigger={
                  <Icon name="copy" onClick={()=>navigator.clipboard.writeText(data.meta?.group_details?.group_invite_link)}/>}
                />}
              />}
              </Header.Subheader>
              {data.meta?.group_details?.group_invite_link ?? '-'}
            </Header.Content>
          </Header>
        </Grid.Column>
      </Grid.Row>
    </>:<>
      <Grid.Row>
        <Grid.Column width={5}>
          <Header as='h4'>
            <Header.Content>
              <Header.Subheader>
                First Name
                {showSmileChecks &&
                    <Label
                        color={smileChecks.f.b}
                        basic
                        size="small"
                        content={smileChecks.f.m}/>
                }
              </Header.Subheader>
              {getUserFirstName(data)}
            </Header.Content>
          </Header>
        </Grid.Column>
        <Grid.Column width={4}>
          <Header as='h4'>
            <Header.Content>
              <Header.Subheader>
                Middle Name
                {showSmileChecks &&
                    <Label
                        color={smileChecks.m.b}
                        basic
                        size="small"
                        content={smileChecks.m.m}/>
                }
              </Header.Subheader>
              {getUserMiddleName(data)}
            </Header.Content>
          </Header>
        </Grid.Column>
        <Grid.Column width={5}>
          <Header as='h4'>
            <Header.Content>
              <Header.Subheader>
                Last Name
                {showSmileChecks &&
                    <Label
                        color={smileChecks.l.b}
                        basic
                        size="small"
                        content={smileChecks.l.m}/>
                }
              </Header.Subheader>
              {getUserLastName(data)}
            </Header.Content>
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={14}>
          <Header as='h4'>
            <Header.Content>
              <Header.Subheader>Full name
                <Popup content='Click to Copy' trigger={
                  <Popup content='Copied to Clipboard' on="click" trigger={
                    <Icon name="copy"
                          onClick={() => navigator.clipboard.writeText(data.fullName)}/>}
                  />}
                />
                {showSmileChecks && <>
                  / Smile Full Name
                  <Popup content='Click to Copy' trigger={
                    <Popup content='Copied to Clipboard' on="click" trigger={
                      <Icon name="copy"
                            onClick={() => navigator.clipboard.writeText(data.meta.smile_id_job?.callback_response?.FullName)}/>}
                    />}
                  />
                  <Label
                      color={smileChecks.fn.b}
                      basic
                      size="small"
                      content={smileChecks.fn.m}/>
                </>}
              </Header.Subheader>
              {data.fullName} {showSmileChecks && <>/ {data.meta.smile_id_job?.callback_response?.FullName}</>}
            </Header.Content>
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={7}>
          <Header as='h4'>
            <Header.Content>
              <Header.Subheader>
                Phone number {!!data.phone_number && <Popup content='Click to Copy' trigger={
                <Popup content='Copied to Clipboard' on="click" trigger={
                  <Icon name="copy" onClick={() => navigator.clipboard.writeText(data.phone_number.replace('+', ''))}/>}
                />}
              />}
              </Header.Subheader>
              {data.phone_number ?? '-'}
            </Header.Content>
          </Header>
        </Grid.Column>
        <Grid.Column width={7}>
          <Header as='h4'>
            <Header.Content>
              <Header.Subheader>
                Email {!!data.email && <Popup content='Click to Copy' trigger={
                <Popup content='Copied to Clipboard' on="click" trigger={
                  <Icon name="copy" onClick={() => navigator.clipboard.writeText(data.email)}/>}
                />}
              />}
              </Header.Subheader>
              {data.email ?? '-'}
            </Header.Content>
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={7}>
          <Header as='h4'>
            <Header.Content>
              <Header.Subheader>
                Date of birth
                <Popup content='Click to Copy' trigger={
                  <Popup content='Copied to Clipboard' on="click" trigger={
                    <Icon name="copy"
                          onClick={() => navigator.clipboard.writeText(data.dob? new Date(data.dob).toISOString().split('T')[0] : '-')}/>
                  }
                  />}
                />
                {showSmileChecks && <>
                  / Smile Date of birth
                  <Popup content='Click to Copy' trigger={
                    <Popup content='Copied to Clipboard' on="click" trigger={
                      <Icon name="copy"
                            onClick={() => navigator.clipboard.writeText(data.meta.smile_id_job?.callback_response?.DOB)}/>}
                    />}
                  />
                  <Label
                      color={smileChecks.dob.b}
                      basic
                      size="small"
                      content={smileChecks.dob.m}/>
                </>}
              </Header.Subheader>
              {data.dob? showSmileChecks ? new Date(data.dob).toISOString().split('T')[0] : new Date(data.dob).toDateString(): '-'}
              {showSmileChecks && <>/ {data.meta.smile_id_job?.callback_response?.DOB}</>}
            </Header.Content>
          </Header>
        </Grid.Column>
        <Grid.Column width={7}>
          <Header as='h4'>
            <Header.Content>
              <Header.Subheader>Gender</Header.Subheader>
              {data.gender ?? '-'}
            </Header.Content>
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={7}>
          <Header as='h4'>
            <Header.Content>
              <Header.Subheader>Residential address</Header.Subheader>
              {data.meta && data.meta.kyc_data ? data.meta.kyc_data.residential_address : '-'}
            </Header.Content>
          </Header>
        </Grid.Column>
        <Grid.Column width={7}>
          <Header as='h4'>
            <Header.Content>
              <Header.Subheader>Income Range</Header.Subheader>
              {data.income_range ?? '-'}
            </Header.Content>
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={7}>
          <Header as='h4'>
            <Header.Content>
              <Header.Subheader>Employment Status</Header.Subheader>
              {data.employment_status ?? '-'}
            </Header.Content>
          </Header>
        </Grid.Column>
        <Grid.Column width={7}>
          <Header as='h4'>
            <Header.Content>
              <Header.Subheader>KYC State</Header.Subheader>
              <Label
                  color={KYCStateColor(data.kyc_state)}
                  size="medium"
                  content={KYCStateName(data.kyc_state)}/>
            </Header.Content>
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={7}>
          <Header as='h4'>
            <Header.Content>
              <Header.Subheader>Sign Up Date</Header.Subheader>
              {toReadableDateTime(data.created_at)}
            </Header.Content>
          </Header>
        </Grid.Column>
        <Grid.Column width={7}>
          <Header as='h4'>
            <Header.Content>
              <Header.Subheader>Frozen</Header.Subheader>
              <Label
                  color={yesOrNoColor(!data.frozen)}
                  size="medium"
                  content={yesOrNoName(data.frozen)}/>
            </Header.Content>
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={7}>
          <Header as='h4'>
            <Header.Content>
              <Header.Subheader>Vendor</Header.Subheader>
              {data.meta && data.meta.vendor_data && data.meta.vendor_data.VendorId && vendors && vendors.length > 0 ? vendors.find(e => e.id === data.meta.vendor_data.VendorId)?.name : '-'}
            </Header.Content>
          </Header>
        </Grid.Column>
        <Grid.Column width={7}>
          <Header as='h4'>
            <Header.Content>
              <Header.Subheader>Affiliation</Header.Subheader>
              {data?.meta?.affiliation ?? '-'}
            </Header.Content>
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={7}>
          <Header as='h4'>
            <Header.Content>
              <Header.Subheader>Terms Version</Header.Subheader>
              {data.meta.terms && data.meta.terms.version ? data.meta.terms.version : '-'}
            </Header.Content>
          </Header>
        </Grid.Column>
        <Grid.Column width={7}>
          <Header as='h4'>
            <Header.Content>
              <Header.Subheader>Terms Date</Header.Subheader>
              {data.meta.terms && data.meta.terms.date ? new Date(data.meta.terms.date).toDateString() : '-'}
            </Header.Content>
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={14}>
          <Header as='h4'>
            <Header.Content>
              <Header.Subheader>Where did you hear from us</Header.Subheader>
              {data.meta && data.meta.marketing_info ? data.meta.marketing_info.where_did_you_hear_from_us : '-'}
            </Header.Content>
          </Header>
        </Grid.Column>
      </Grid.Row>
      {!!data.meta.beneficiary && <>
        <Grid.Row>
          <Grid.Column width={14}>
            <Header as='h3' color="teal" content="Beneficiary" />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={7}>
            <Header as='h4'>
              <Header.Content>
                <Header.Subheader>Name</Header.Subheader>
                {data.meta?.beneficiary?.name ?? '-'}
              </Header.Content>
            </Header>
          </Grid.Column>
          <Grid.Column width={7}>
            <Header as='h4'>
              <Header.Content>
                <Header.Subheader>Date of birth</Header.Subheader>
                {data.meta?.beneficiary?.dob? new Date(data.meta.beneficiary.dob).toDateString(): '-'}
              </Header.Content>
            </Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={7}>
            <Header as='h4'>
              <Header.Content>
                <Header.Subheader>Phone number</Header.Subheader>
                {data.meta?.beneficiary?.phone_number ?? '-'}
              </Header.Content>
            </Header>
          </Grid.Column>
          <Grid.Column width={7}>
            <Header as='h4'>
              <Header.Content>
                <Header.Subheader>ID</Header.Subheader>
                {data.meta?.beneficiary?.id_number ?? '-'}
              </Header.Content>
            </Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={7}>
            <Header as='h4'>
              <Header.Content>
                <Header.Subheader>Funds source</Header.Subheader>
                {data.meta?.beneficiary?.funds_source ?? '-'}
              </Header.Content>
            </Header>
          </Grid.Column>
          <Grid.Column width={7}>
            <Header as='h4'>
              <Header.Content>
                <Header.Subheader>Business type</Header.Subheader>
                {data.meta?.beneficiary?.business_type ?? '-'}
              </Header.Content>
            </Header>
          </Grid.Column>
        </Grid.Row>
      </>}
    </>}
  </>
}

export default UserProfile
