import React, {useContext} from 'react';

import {Dropdown, Header, Icon, Image, Menu, Message} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import {UserContext} from "../context/UserProvider";

const TitleBar = () => {
  const {logout,user} = useContext(UserContext)
  return (
    <Menu secondary>
      <Menu.Item href="/" fitted="horizontally">
        <Header content="Koa" as="h1" color="blue"/>
      </Menu.Item>
      <Menu.Menu position="right">
        <Dropdown item text={user.name}>
          <Dropdown.Menu>
            <Link to="/my-account">
              <Message icon attached compact>
                  <Image src={user.photo_url} avatar/>
                  <Message.Content style={{marginRight:30}}>
                    <Message.Header>{user.name}</Message.Header>
                    <Message.Content>{user.access}</Message.Content>
                  </Message.Content>
                </Message>
              </Link>
              <Link to="/profile" href>
                <Dropdown.Item><Icon name="user circle"/>Profile</Dropdown.Item>
              </Link>
            <Dropdown.Item><Icon name="phone"/>Contact Support</Dropdown.Item>
            <Dropdown.Divider/>
            <Dropdown.Item onClick={logout}><Icon name="log out"/>Logout</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Menu>
    </Menu>
  );
}

export default TitleBar;
